import {
    BufferAttribute,
    BufferGeometry,
    Color,
    DoubleSide,
    Float32BufferAttribute,
    InterleavedBufferAttribute,
    Matrix4,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Raycaster,
    Vector3,
} from 'three';
import { AcidObject3dBase } from '@/lib/planning/objects-3D/AcidObject3d';

import anylogger from 'anylogger';
import { CancelToken } from 'axios';
import AsyncIterationUtil from '@/lib/viewer/component-coverage/AsyncIterationUtil';
import BufferGeometryUtil, {
    Face3Indices,
    Face3Positions,
    FacePredicate,
} from '@/lib/viewer/component-coverage/BufferGeometryUtil';
import { applyOnlyRotationMatrix, setLocalTransform, sourceToTarget } from '@/lib/base/ThreeUtil';
import {
    CollisionCoverageResults,
    CollisionFaceColorOptions,
    CollisionFaceColors,
    ColorWithOpacity,
    ComponentCoverageStrategy,
    FaceAreaMetric,
    FaceCollisionColorCallback,
    IntersectionResult,
    MeshIntersectionConfig,
    MeshIntersectionResult,
    PointIntersection,
} from '@/lib/viewer/component-coverage/types';
import { any, flatten, range } from 'ramda';
import { formatNumber } from '@/lib/filters/format/formatNumber';
import { formatElapsedSeconds } from '@/lib/filters/format/formatElapsedSeconds';
import AreaUtil from '@/lib/viewer/component-coverage/AreaUtil';
import { FaceUtil } from '@/lib/base/three-js/FaceUtil';

const log = anylogger('ComponentCoverageUtils2');

export const FACES_PER_DEFER_LOOP = 10;

/**
 * Component coverage utilities for:
 *
 * - Calculating the coverage of a component into a bone. This is achieved creating a collision
 * geometry and calculating collision area.
 * - Calculating the collision point of a component into a bone.
 *
 *
 * Strategy
 * --------
 * 1. To use the [crossing number algorithm]{@link https://en.wikipedia.org/wiki/Point_in_polygon}
 * (a.k.a even-odd rule algorithm)
 *
 * @see https://en.wikipedia.org/wiki/Point_in_polygon
 *
 * Assumptions
 * -----------
 * 1. The collidable mesh is a [close mesh (watertight)]{@link https://davidstutz.de/a-formal-definition-of-watertight-meshes/)}.
 * 2. The algorithm does not account for [osteophytes]{@link https://en.wikipedia.org/wiki/Osteophyte}
 * not filetered on the segmentation process.
 *
 * e.g.: Considering a hip - cup scenario:
 *  If a cup is making contact with an osteophyte, the algorithm will not know the difference between
 *  the osteophyte and actual hip socket where the cup is intended to be fit.
 *  This seems to not be a limitation of this algorithm but rather a residual of the segmentation process.
 *
 * Strategy illustration in 2D using a polygon and multiple points
 * ---------------------------------------------------------------
 * 1. Draw a horizontal line to the right of each point and extend it to infinity
 * 1. Count the number of times the line intersects with polygon edges.
 * 1. A point is inside the polygon if either count of intersections is odd or point lies on an edge of polygon.
 * If none of the conditions is true, then point lies outside.
 *
 * @see https://newbedev.com/check-if-point-is-inside-a-custom-mesh-geometry
 *
 * ```
 *                   y0----------------------------------------> 0 intersections (even - outside)
 *                               ___________
 *                              / x1--------|------------------> 1 intersection (odd - inside)
 *                           __/            |_________
 *                          |                         |
 *                          |   x2--------------------|--------> 1 intersection (odd - inside)
 *                          |                         |
 *                          |        _____            |
 *                   y1-----|-------|----|------------|--------> 4 intersections (even - outside)
 *                          |  x3 --|----|------------|--------> 3 intersections (odd - inside)
 *                          |_______|    |____________|
 * ```
 *
 * TODO: - The coverage utility has still UI concerns.
 */
export default class ComponentCoverageUtil {
    /**
     * Check the bone penetration of given component.
     *
     * Note:
     * 1. Currently it is only used with screws, but it should be able to be called with any component (e.g.: stem)
     * 2. It is responsibility of the user of this method to update the **matrix world** of the meshes
     *
     * @param token the cancellation token
     * @param coveringMesh e.g. Scapula
     * @param meshIntersectionsConfig
     */
    public static async checkComponentPenetration(
        token: CancelToken,
        coveringMesh: Mesh,
        meshIntersectionsConfig: MeshIntersectionConfig[]): Promise<MeshIntersectionResult[]> {
        return meshIntersectionsConfig.map((intersectionConfig) => {
            const start = new Date().getTime();

            const pointIntersectionMetric = ComponentCoverageUtil.intersectPoint(
                intersectionConfig.origin, intersectionConfig.rayDirection, coveringMesh);

            log.info(
                'Collision calculation took: %s. Inside: %s. Distance: %s',
                formatElapsedSeconds(start),
                pointIntersectionMetric.inside,
                formatNumber(pointIntersectionMetric.distance as number, 2));

            return new MeshIntersectionResult(intersectionConfig, pointIntersectionMetric);
        });
    }

    /**
     * Calculate the proportion of area of faces in the componentMask that are BEHIND faces coveringMesh
     *
     * @param token the cancellation token
     * @param componentMask
     * @param fullCoveringMesh
     * @param componentCoverageStrategy
     */
    public static async checkComponentCoverage(
        token: CancelToken,
        componentMask: Mesh,
        fullCoveringMesh: Mesh,
        componentCoverageStrategy: ComponentCoverageStrategy): Promise<CollisionCoverageResults> {
        const start = new Date().getTime();

        const calculatedCoverage = await ComponentCoverageUtil.calculateComponentCoverage(
            token, componentMask, fullCoveringMesh, componentCoverageStrategy);

        log.info(
            'Coverage calculation took: %s. Area: %d. Detected: %d. Coverage: %s%.',
            formatElapsedSeconds(start),
            calculatedCoverage.totalArea,
            calculatedCoverage.area,
            formatNumber(calculatedCoverage.coverage * 100, 2));

        return {
            ...calculatedCoverage,
            isCalculating: false,
        };
    }

    /**
     * Remove any previous collision coverage objects
     */
    public static clearCollision(collisionRepresentationObject: AcidObject3dBase): void {
        collisionRepresentationObject.detach(...collisionRepresentationObject.children);
        collisionRepresentationObject.theObject.remove(...collisionRepresentationObject.theObject.children);
    }

    /**
     * Create the component-mask mesh for a component given the actual component and the component mask geometry.
     * @param component the component object with transform in the scene
     * @param maskGeometry the mask geometry to use for intersection
     */
    public static makeComponentMask(component: Object3D, maskGeometry: BufferGeometry): Mesh {
        if (component.parent) {
            component.parent.updateMatrixWorld(true);
        }
        component.updateMatrixWorld(true);

        const result = new Mesh(maskGeometry);
        result.matrixAutoUpdate = false;
        setLocalTransform(result, component.matrixWorld);
        return result;
    }

    /**
     * Create a mesh with the same vertices and transform as the source mesh but only a
     * subset of the faces. The new mesh will be assigned a {@link DoubleSide double-sided}
     * material so that the {@link Raycaster} will return intersections wth both front- and
     * back-side faces.
     *
     * @see https://threejs.org/docs/api/en/core/Raycaster.html#intersectObject
     */
    private static filterMeshFaces(sourceMesh: Mesh, includeFace: FacePredicate): Mesh {
        const reducedGeometry = BufferGeometryUtil.extractFaces(
            sourceMesh.geometry.clone(), includeFace, false);
        const result = new Mesh(reducedGeometry, new MeshBasicMaterial({ side: DoubleSide }));
        result.matrixAutoUpdate = false;
        setLocalTransform(result, sourceMesh.matrixWorld);
        return result;
    }

    /**
     * Check whether a given point is inside a cylinder with the given radius and axis passing through the origin and
     * with the axis in a particular direction.
     */
    private static isInsideCylinder(position: Vector3, cylinderAxis: Vector3, cylinderRadius: number): boolean {
        // Find the (squared) distance from the position to the cylinder axis. If this (squared) distance is less
        // than the (squared) cylinder radius, the position is inside the cylinder.
        const squaredDistanceFromCylinderAxis = position.projectOnPlane(cylinderAxis).lengthSq();
        return squaredDistanceFromCylinderAxis < cylinderRadius * cylinderRadius;
    }

    /**
     * Check whether a given point is inside a sphere with the given radius, centred at the origin
     */
    private static isInsideSphere(position: Vector3, sphereRadius: number): boolean {
        // Find the (squared) distance from the position to the sphere centre. If this (squared) distance is less than
        // than the (squared) sphere radius, the position is inside the sphere.
        const squaredDistanceFromCentre = position.lengthSq();
        return squaredDistanceFromCentre < sphereRadius * sphereRadius;
    }

    /**
     * Create the mesh to be used for intersection with tests with a subset of the faces from the full covering-mesh.
     */
    public static makeIntersectionMesh(
        componentMask: Mesh,
        fullCoveringMesh: Mesh,
        coverageStrategy: ComponentCoverageStrategy,
        componentToCoveringMesh: Matrix4): Mesh {
        // Transform the ray direction from component space into covering-mesh space
        const rayDirection = applyOnlyRotationMatrix(
            componentToCoveringMesh, coverageStrategy.getRayDirection().normalize().clone());

        // Get the bounding sphere of the component mesh in component space
        const boundingSphere = BufferGeometryUtil.boundingSphere(componentMask.geometry);

        // Find the position of the bounding sphere in covering-mesh coordinates.
        const sphereCentre = boundingSphere.center.clone().applyMatrix4(componentToCoveringMesh);

        const isFaceInsideVolume = (facePositions: Face3Positions): boolean => {
            // Each face is included if it has at least one vertex within the inclusion volume.
            return any((vertexPosition: Vector3): boolean => {
                // Vector that gives the vertex position relative to the sphere centre
                const sphereToVertex = vertexPosition.clone().sub(sphereCentre);

                // Get the distance of the vertex from the plane through the component-bounding-sphere centre and
                // orthogonal to the ray direction. This plane splits the hemispherical from the cylindrical bounding
                // volumes.
                const vertexToComponentPlane = sphereToVertex.dot(rayDirection);

                if (vertexToComponentPlane > 0) {
                    // Vertex is in the cylindrical region of the bounding volume.
                    return ComponentCoverageUtil.isInsideCylinder(sphereToVertex, rayDirection, boundingSphere.radius);
                } else {
                    // The vertex is in the the hemispherical region of the bounding volume.
                    return ComponentCoverageUtil.isInsideSphere(sphereToVertex, boundingSphere.radius);
                }
            }, facePositions);
        };

        const result = this.filterMeshFaces(fullCoveringMesh, isFaceInsideVolume);
        log.info(`Created intersection mesh with ${BufferGeometryUtil.faceCount(result.geometry)} ` +
            `out of ${BufferGeometryUtil.faceCount(fullCoveringMesh.geometry)} faces`);
        return result;
    }

    /**
     * Calculate the proportion of area of faces in obj1 that are BEHIND faces coveringMesh
     *
     * @param token
     * @param componentMask mesh we are calculating the coverage of.
     * @param fullCoveringMesh object (eg. hemi-pelvis or scapula) that is covering collisionMaskGeometry
     * @param componentCoverageStrategy
     * @return coverage proportion between 0 and 1 and the covered geometry
     */
    private static async calculateComponentCoverage(
        token: CancelToken,
        componentMask: Mesh,
        fullCoveringMesh: Mesh,
        componentCoverageStrategy: ComponentCoverageStrategy): Promise<CollisionCoverageResults> {
        // Get the matrix that transforms component space into covering-mesh space.
        const componentToCoveringMesh = sourceToTarget(componentMask, fullCoveringMesh);

        fullCoveringMesh.updateMatrixWorld(true);
        fullCoveringMesh.updateMatrix();
        const coveringMesh = ComponentCoverageUtil.makeIntersectionMesh(
            componentMask, fullCoveringMesh, componentCoverageStrategy, componentToCoveringMesh);
        const collisionMask = BufferGeometryUtil.applyMatrix4(componentMask.geometry, componentMask.matrixWorld);

        log.info(
            'Checking coverage of obj1 (%s faces) in coveringMesh %s (%s faces)',
            BufferGeometryUtil.faceCount(collisionMask),
            fullCoveringMesh.name,
            BufferGeometryUtil.faceCount(coveringMesh.geometry));

        let collisionArea = 0.0;
        let totalArea = 0.0;

        const faceAreaMetrics: FaceAreaMetric[] = [];

        const collisionMaskVertexPositions = BufferGeometryUtil.vertexPositionBuffer(collisionMask);

        // The ray direction when calculating the face intersection seems to be needed in the
        // world space (could not finding documentation about it, but seems reasonable)
        // Given the ray direction is in the component mask local space, and does not
        // taking in consideration the rotation of the components mask in the world space,
        // it is rotated as follows.
        const rayDirectionInWorldSpace = applyOnlyRotationMatrix(
            componentMask.matrixWorld, componentCoverageStrategy.getRayDirection().normalize().clone());

        function calculateFace(faceIndices: Face3Indices): void {
            token.throwIfRequested();
            const faceAreaMetric = ComponentCoverageUtil.calculateFace(
                faceIndices, collisionMaskVertexPositions, rayDirectionInWorldSpace, coveringMesh);
            totalArea += faceAreaMetric.area;

            if (faceAreaMetric.intersection.inside) {
                collisionArea += faceAreaMetric.area;
            }
            faceAreaMetrics.push(faceAreaMetric);
        }

        function returnCallback(): CollisionCoverageResults {
            token.throwIfRequested();
            // Update geometry color when rendered

            //
            // TODO FL-982
            //
            // collisionMask.colorsNeedUpdate = true;
            log.info('%s faces covered with area of %s', BufferGeometryUtil.faceCount(collisionMask), collisionArea);

            return {
                coverage: collisionArea / totalArea,
                area: collisionArea,
                totalArea,
                isCalculating: true,
                intersectionMesh: coveringMesh,
                faceAreaMetrics,
            };
        }

        const [result, _metrics] = await AsyncIterationUtil.doInChunks<Face3Indices, CollisionCoverageResults>(
            BufferGeometryUtil.faceIndices(collisionMask), FACES_PER_DEFER_LOOP, calculateFace, returnCallback);

        return result;
    }

    public static calculateFace(
        faceIndices: Face3Indices,
        /**
         * The position attribute of the buffer geometry. This is is used to extract the face positions.
         *
         * Note: For **performance** reasons it is asked as a parameter, so it is calculated **only once** and
         * not on each iteration.
         */
        vertexPositions: BufferAttribute | InterleavedBufferAttribute,
        rayDirectionInWorldSpace: Vector3,
        coveringMesh: Mesh): FaceAreaMetric {
        const facePositions = BufferGeometryUtil.getFaceVertexFromBufferAttribute(vertexPositions, faceIndices);
        const faceArea = AreaUtil.areaOfTriangle(facePositions[0], facePositions[1], facePositions[2]);
        const pointIntersection = ComponentCoverageUtil.intersectFaceCentroid(
            facePositions, coveringMesh, rayDirectionInWorldSpace);
        return new FaceAreaMetric(faceArea, facePositions, pointIntersection);
    }

    /**
     * Intersects a face with a mesh.
     *
     * A ray is configured as follows:
     *   - origin: face centroid.
     *   - direction: the inverse of the face normal
     */
    public static intersectFaceCentroid(
        facePositions: Face3Positions, coveringMesh: Object3D, rayDirection: Vector3): PointIntersection {
        const faceCentroid = FaceUtil.getCentroid(facePositions);
        return this.intersectPoint(faceCentroid, rayDirection, coveringMesh);
    }

    /**
     * Intersects a point with a mesh.
     */
    public static intersectPoint(origin: Vector3, rayDirection: Vector3, coveringMesh: Object3D): PointIntersection {
        const ray = new Raycaster(origin, rayDirection);
        const result = new IntersectionResult(origin, rayDirection, ray.intersectObject(coveringMesh));
        return new PointIntersection(result);
    }

    /**
     * Method for making default collision face colors
     *
     * A default color is always provided, while the cortical and cancellous colors can be overwritten
     * by passing their respective colors
     */
    public static makeComponentCollisionFaceColor(options?: CollisionFaceColorOptions): CollisionFaceColors {
        return {
            /** Default collision color is RED */
            default: new Color(255, 0, 0),
            /** Default cortical color is YELLOW */
            cortical: options?.cortical || new Color(255, 255, 0),
            /** Default cancellous color is BLUE */
            cancellous: options?.cancellous || new Color(0, 0, 255),
            /** Default 'outside of bone' color is RED */
            outside: options?.outside || new Color(255, 0, 0),
        };
    }

    /**
     * Make the coverage mesh for visualization purposes using the results of the coverage calculation.
     */
    public static makeCoverageMesh(
        calculatedCoverage: CollisionCoverageResults, getFaceColor: FaceCollisionColorCallback): Mesh {
        const geometry = ComponentCoverageUtil.makeGeometry(calculatedCoverage.faceAreaMetrics, getFaceColor);
        const material = new MeshBasicMaterial({
            // Defines whether vertex coloring is used
            vertexColors: true,
            transparent: true,
            side: DoubleSide,
        });
        return new Mesh(geometry, material);

        // MaterialUtils.updateMaterialColor(coverageMesh.material, new Color(0x4343f9));
        // const showCollisionPoints = false;
        // const collisionPointsMesh = new Points(
        //     calculatedCoverage.collisionPoints,
        //     new PointsMaterial({ color: 0xFF9000, visible: showCollisionPoints }));
        // coverageMesh.attach(collisionPointsMesh);
    }

    /**
     * Make a non indexed buffer geometry using the results of the coverage calculation.
     *
     * Note:
     * 1. The Buffer Geometry is a non-indexed to make it setting the position/color buffer more simpler.
     * 2. The implementation is not intended to be performant at this stage. If iterating the faces twice
     * to generate the position and color buffer has a penalty, it can be changed to an accumulation approach
     * instead of a `map`.
     *
     * @param faceAreaMetrics: the metrics of the coverage calculation process.
     * @param faceColor: The strategy to calculate the color of the face. If the face color evaluates to null,
     * the face will not be added to the geometry.
     */
    public static makeGeometry(
        faceAreaMetrics: FaceAreaMetric[], faceColor: FaceCollisionColorCallback): BufferGeometry {
        const geometry = new BufferGeometry();
        const makeVertexColor = (colorConfig: ColorWithOpacity) => {
            // The opacity comes as the 4th element of the array
            return [...colorConfig.rgb.toArray(), colorConfig.opacity];
        };

        /**
         * @returns the vertex position array buffer. Faces which evaluate the color as null are not included.
         *
         * Note: This is a **compensation** for not being able to make hidden the faces that
         * are outside of the pelvis (cup).
         *
         * This allows:
         * - Cup: faces that are not inside to be excluded.
         *  Note that if they would have been included, and with opacity: 0, they will be in some way visible
         *  when the user moves the cup. This seems seems to be related to the lighting of the Material.
         * - Baseplate: faces that are not inside to have a different material.
         */
        const makePositionArrayBuffer = function(): number[] {
            const positionBufferPerFace = faceAreaMetrics.map((metric: FaceAreaMetric): number[] => {
                const colorConfig = faceColor(metric);
                if (colorConfig) {
                    return flatten(metric.positions.map((p) => p.toArray()));
                } else {
                    return [];
                }
            });

            return flatten(positionBufferPerFace);
        };

        /**
         * @returns the vertex color array buffer. Faces which evaluate the color do not have a color.
         */
        const makeColorArrayBuffer = function(): number[] {
            const colorBufferPerFace = faceAreaMetrics.map((metric: FaceAreaMetric): number[] => {
                const colorConfig = faceColor(metric);
                if (colorConfig) {
                    const colorElements = range(0, BufferGeometryUtil.VERTICES_PER_TRIANGLE).map(
                        () => makeVertexColor(colorConfig));

                    return flatten(colorElements);
                } else {
                    return [];
                }
            });

            return flatten(colorBufferPerFace);
        };

        // The implementation is not intended to be performant at this stage. If iterating the faces twice
        // to generate the position and color buffer has a penalty, it can be changed to an accumulation approach.
        const positionBuffer = makePositionArrayBuffer();
        const colorBuffer = makeColorArrayBuffer();

        geometry.setAttribute('position', new Float32BufferAttribute(positionBuffer, 3));
        geometry.setAttribute('color', new Float32BufferAttribute(colorBuffer, 4));
        geometry.computeVertexNormals();

        //
        // TODO: This was a comment on the previous implementation. Is still needed?
        // We need to do this because when the geometry is initially loaded into the scene,
        // a bounding sphere is being computed, but after we move the geometry to a new position,
        // the bounding sphere needs to be recalculated.
        // The renderer uses the bounding sphere as a reference point for the distance between the object and
        // the camera, and will not render objects with bounding sphere center point that is behind the camera
        geometry.computeBoundingSphere();

        return geometry;
    }
}
