import { defineStore } from 'pinia';
import { HipRoles, UserHipRole, UserHipRolesState } from '@/stores/userRoles/types';
import UserResource from '@/lib/api/resource/user/UserResource';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import axios, { AxiosResponse } from 'axios';

export const useUserRoles = defineStore('userRoles', {
    state: (): UserHipRolesState => ({
        isLoading: false,
        myUserUri: '',
        userRoles: [],
    }),
    getters: {
        isAdmin(): boolean {
            return this.userRoles.includes(HipRoles.Admin);
        },
        isOrgAdmin(): boolean {
            return this.userRoles.includes(HipRoles.OrgAdmin);
        },
        isSurgeon(): boolean {
            return this.userRoles.includes(HipRoles.Surgeon);
        },
        isSurgeonAssist(): boolean {
            return this.userRoles.includes(HipRoles.SurgeonAssist);
        },
        isSales(): boolean {
            return this.userRoles.includes(HipRoles.Sales);
        },
    },
    actions: {
        async load(): Promise<void> {
            await this.loadMyUserUri();
            await this.loadMyHipRoles();
        },
        async loadMyUserUri(): Promise<void> {
            const me = await UserResource.getMeUser(this.$api, this.$apiOptions);
            if (!me) {
                throw new Error('could not load user');
            }
            const myUserUri = LinkUtil.getUri(me, LinkRelation.self);
            if (!myUserUri) {
                throw new Error('could not get user self link');
            }
            this.myUserUri = myUserUri;
        },
        async loadMyHipRoles() {
            const userRolesUrl = `${this.myUserUri}/roles/product/4`;

            const userHipRoles: UserHipRole[] = await axios
                .get(userRolesUrl)
                .then((response: AxiosResponse): UserHipRole[] => {
                    return response.data.items;
                });
            if (!userHipRoles) {
                throw new Error('could not get user roles');
            }
            userHipRoles.forEach((userHipRole: UserHipRole) => {
                this.userRoles.push(userHipRole.title);
            });
        },
    },
});

export type UserRoleStore = ReturnType<typeof useUserRoles>;
