/**
 * All events used in the 3D planning should be listed here
 */

/**
 * For examples see: {@link HipEvent}
 * @see {@link TypeEventUtil}
 */
export enum PlannerEvent {
    /**
     * Events for toggling **hip** viewer buttons clusters (visibility, x-ray, cross-section, etc)
     */
    ToggleModelVisibilityByAlias = 'event:planner:toggle-model-visibility-by-alias',
    ToggleModelVisibilityByAliasDone = 'event:planner:toggle-model-visibility-by-alias-done',
    ToggleViewXRayMode = 'event:planner:toggle-view-x-ray-mode',
    ToggleCrossSection = 'event:planner:toggle-cross-section',

    HipPlannerCupAssemblyMoved = 'event:planner:hip-planner-cup-assembly-moved',
    HipPlannerCupAssemblyCupSet = 'event:planner:hip-planner-cup-assembly-cup-set',
}
