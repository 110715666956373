
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import { LinkUtil } from 'semantic-link';
    import { RefreshAction } from '@/lib/refresh-actions/RefreshAction';

    import anylogger from 'anylogger';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import PlanItemActions from '@/components/case-plan/PlanItemActions.vue';
    import PlanItemDetails from '@/components/case-plan/PlanItemDetails.vue';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';
    import LinkRelation from '@/lib/api/LinkRelation';

    const log = anylogger('PlanItem');

    /**
     * This component is a v-card with the details of one of the plans within a case. Each
     * case may have zero or more of them items.
     *
     * If the item is the 'active' (aka current) plan then it will be highlighted as the
     * active plan. Only one plan will be 'active'.
     *
     * If the plan has no report then it is assumed to be still being processed and will
     * show as being processed. The v-card will poll for updates so that the user doesn't
     * need to reload the web application.
     */
    @Component({ components: { PlanItemActions, PlanItemDetails } })
    export default class PlanItem extends Vue {
        @InjectReactive() private store!: ProjectStore;
        @InjectReactive() private planStore!: PlanReportStore;

        private readonly refresh = new RefreshAction(20000 /* ms */, this.onRefresh);

        @IsLoading('loading')
        protected async mounted(): Promise<void> {
            // If the plan doesn't have a report link then and the plan has been
            // created in the last (say) 24 hours, then keep refreshing the plan
            // periodically in anticipation that the plan will be processed by
            // the backend server.
            if (this.planStore.isCreating) {
                this.refresh.enable();
            } else if (this.planStore.isCreationBroken) {
                log.debug('Stale plan %s not being refreshed', LinkUtil.getUri(this.planStore.plan, LinkRelation.self));
            } else {
                log.debug('Existing plan not being refreshed');
            }
            this.refresh.onMounted();
        }

        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                this.refresh.onIntersect(isIntersecting);
            }

        protected beforeDestroy(): void {
            this.refresh.onDestroy();
        }

        private async onRefresh(): Promise<void> {
            await this.planStore.refreshPlan();
        }

        protected get caseName(): string {
            return this.store.projectName;
        }

        protected get isStoreInitialised(): boolean {
            return this.planStore.initialised;
        }
    }
