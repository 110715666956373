import CameraMan, { HipCameraPresetEnum } from '@/lib/planning/camera/CameraMan';
import { Vector3 } from 'three';
import AcidObject3d from '@/lib/planning/objects-3D/AcidObject3d';
import { cupWorldPosition } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import assert from 'assert';
import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import { CrossSectionPlaneModel } from '@/lib/planning/cross-section/CrossSectionPlaneModel';
import { includes } from 'lodash';
import { asVector3 } from '@/lib/base/ThreeUtil';
import LPS from '@/lib/base/LPS';
import { areCollinear } from '@/lib/geometry/vector3';
import anylogger from 'anylogger';

const log = anylogger('CameraManUtil');

export const DEFAULT_CAMERA_DISTANCE = 450;

/** Default camera offset direction from look-at-target is to the anterior,
 * so the camera will look in an AP direction **/
export const DEFAULT_CAMERA_DIRECTION = LPS.Anterior;

export default class CameraManUtil {
    public static moveToObject(
        cameraMan: CameraMan,
        object3D: AcidObject3d,
        distance: number = DEFAULT_CAMERA_DISTANCE,
        direction: Vector3 = DEFAULT_CAMERA_DIRECTION): void {
        const presetName = `${object3D.name}-default`;

        // Center position of the object
        // When getting the bounding sphere center we will always
        // get the object's center of mass position
        const lookAt = object3D.getWorldBoundingSphereCenter() || new Vector3();

        cameraMan.addPreset(presetName, lookAt, {
            direction: direction.clone(),
            distance,
        });

        cameraMan.moveTo(presetName, distance);
    }

    public static async moveCameraToFacePlane(
        cameraMan: CameraMan, plane: CrossSectionPlaneModel, cameraUpDirection?: Vector3,
        lookAt?: Vector3): Promise<void> {
        this.addPresetFacingPlane(cameraMan, plane, cameraUpDirection, lookAt);
        await cameraMan.moveTo(plane.name);
    }

    private static addPresetFacingPlane(
        cameraMan: CameraMan, plane: CrossSectionPlaneModel, cameraUpDirection?: Vector3, lookAt?: Vector3): void {
        const isStemPlane = includes(plane.name, 'stem');
        const presetName = plane.name;

        // Use the provided look-at point if given, otherwise use the plane 'origin' position
        lookAt = lookAt ? lookAt.clone() : plane.mesh.getWorldPosition(new Vector3()).clone();

        // We want the camera direction to be the opposite of the normal of the plane
        //
        // If we have
        // A) Our body
        // B) Cut by a plane defined by a point (P) and a Vector (V)
        // C) We want out camera to be positioned on a point (C) at distance (D) somewhere on (-V)
        // A)                       B)                      C)
        //                          /|                     /|
        // *****                 **| |                  **| |
        // *****                 **| |                  **| |[     D     ]
        // *****     [V]<--------**|P|     [-V]-------->**|P|------------>[C]
        // *****                 **| |                  **| | [camera positioned on C looking at P]
        // *****                 **|/                   **|/
        //

        // Get the offset-direction from look target to camera: orthogonal to the plane in the
        // direction opposite its normal
        const planeNormal = plane.direction;
        const direction = planeNormal.clone().negate();

        // TODO HACK
        // TODO Distance should be configurable per step / cross section
        // TODO HARD CODING distance for stem view
        let distance = 100;
        if (isStemPlane) {
            distance = 270;
        }

        // Check that a particular up direction is valid, given the current offset direction
        function validateUpDirection(name: string, up: Vector3): Vector3 | undefined {
            if (!areCollinear(up, direction)) {
                return up;
            } else {
                log.warn(`${name} direction is collinear with plane normal`);
            }
        }

        // Find up direction that is not collinear with the look direction using, in order of priority:
        //   - the provided "up" direction, if given
        //   - the world superior direction
        //   - the world posterior direction
        const up =
            (cameraUpDirection ? validateUpDirection('provided up', cameraUpDirection) : undefined) ??
            validateUpDirection('superior', LPS.Superior) ??
            LPS.Posterior;

        cameraMan.addPreset(presetName, lookAt, { direction, distance, up });
    }

    /** Set up the Initial, Stem, Cup and Combined camera presents */
    public static setupInitialView(
        cameraMan: CameraMan,
        sceneOrigin: Vector3): void {
        cameraMan.addPreset(HipCameraPresetEnum.InitialAP, sceneOrigin, { direction: LPS.Anterior });
        cameraMan.moveTo(HipCameraPresetEnum.InitialAP);
    }

    /** Set up the Initial, Stem, Cup and Combined camera presents */
    public static setupHipCameraPresets(
        cameraMan: CameraMan,
        assembly: HipPlannerAssembly): void {
        CameraManUtil._setupStemAnteriorPosterior(cameraMan, assembly);
        CameraManUtil._setupCupAnteriorPosterior(cameraMan, assembly);
        CameraManUtil._setupCombinedModeCamera(cameraMan, assembly);
    }

    private static _setupStemAnteriorPosterior(
        cameraMan: CameraMan, assembly: HipPlannerAssembly): void {
        const stemBucket: HipStemRepresentation = assembly.stem.getCaseComponent();
        assert.ok(stemBucket, 'stem bucket is not defined');
        assert.ok(stemBucket.pa_axis, 'stem bucket pa_axis is not defined');

        const stemAnterior = asVector3(stemBucket.pa_axis).negate();
        cameraMan.addPreset(
            HipCameraPresetEnum.StemAP,
            assembly.stemGroup.worldPosition, {
                direction: stemAnterior,
            },
        );
    }

    private static _setupCupAnteriorPosterior(
        cameraMan: CameraMan, assembly: HipPlannerAssembly): void {
        const cupBucket: HipCupRepresentation = assembly.cup.getCaseComponent();
        assert.ok(cupBucket, 'cup bucket is not defined');
        assert.ok(cupBucket.ap_vector, 'cup bucket ap_vector is not defined');

        const cupAnterior = asVector3(cupBucket.ap_vector).negate();

        cameraMan.addPreset(
            HipCameraPresetEnum.CupAP,
            cupWorldPosition(assembly), {
                direction: cupAnterior,
            },
        );
    }

    private static _setupCombinedModeCamera(cameraMan: CameraMan, assembly: HipPlannerAssembly): void {
        // TODO: ByeByeSceneTransform
        const DEFAULT_COMBINED_DIRECTION = LPS.Anterior;
        const DEFAULT_COMBINED_VIEW_DISTANCE = 300;

        cameraMan.addPreset(
            HipCameraPresetEnum.Combined,
            cupWorldPosition(assembly), {
                direction: DEFAULT_COMBINED_DIRECTION,
                distance: DEFAULT_COMBINED_VIEW_DISTANCE,
            },
        );
    }
}
