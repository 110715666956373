import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';

export class HipStemUtil {
    public static isSuitableStem(stem: HipStemRepresentation): boolean {
        return stem.suitability_score === 0;
    }

    public static isNotSuitableStem(stem: HipStemRepresentation): boolean {
        return !HipStemUtil.isSuitableStem(stem);
    }
}
