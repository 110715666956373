import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { isCupPosition, isCupRotation } from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateModel';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import CupRotationUtil from '@/hipPlanner/components/state/CupRotationUtil';
import assert from 'assert';
import { ComponentSelectorRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { NumberUtil } from '@/lib/base/NumberUtil';
import {
    CupComponentsRepresentation,
    CupOffset,
    isStemComponentsRepresentation,
    StemComponentsRepresentation,
} from '@/hipPlanner/components/state/types';
import HipSurgicalTemplateComponentResource
    from '@/lib/api/resource/case/surgical-template/components/HipSurgicalTemplateComponentResource';
import SurgicalTemplateSynchroniser from '@/hipPlanner/stores/template/SurgicalTemplateSynchroniser';
import { SyncState } from '@/hipPlanner/stores/template/TemplateSyncState';
import LinkRelation from '@/lib/api/LinkRelation';
import { instanceOfNotTrackedRepresentation } from '@/lib/api/instanceOfNotTrackedRepresentation';
import {
    AngleDegree,
} from '@/lib/api/representation/case/surgical-template/common/AnteversionInclinationAngleRepresentation';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { Vector3 } from 'three';
import { fromRepresentation, makeNullRigidTransform, toRepresentation } from '@/lib/base/RigidTransform';
import HipPlannerAPIService from '@/hipPlanner/components/state/HipPlannerAPIService';
import { HipSurgicalTemplateStoreUtil } from '@/hipPlanner/components/state/HipSurgicalTemplateStoreUtil';
import { SurgicalTemplateRecordChangeState } from '@/components/case/change-workflow/SurgicalTemplateRecordChangeState';
import StemController from '@/hipPlanner/assembly/controllers/StemController';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { ReRankingState } from '@/hipPlanner/stores/planner/ReRankingState';
import HipCupController from '@/hipPlanner/assembly/controllers/HipCupController';
import anylogger from 'anylogger';
import { WaitResourceUtil } from '@/lib/api/resource/WaitResourceUtil';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import { isErrorCausedBy } from '@/hipPlanner/views/hipPlannerServices';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import { HipComponentsRepresentation } from '@/lib/api/representation/case/hip/HipComponentsRepresentation';
import { cloneCatalogComponent } from '@/hipPlanner/stores/template/customDeepClone';

const log = anylogger('Hip template controller');

/**
 * The global store of the surgical template.
 *
 * The plan is that:
 * 1. It will be the living version/local copy of surgical template (user changes) until
 * it is migrated into the server & network of data.
 * 2. Provide reactivity and read access to the surgical template state.
 * 3. Provide a light weight version of the {@link HipSurgicalTemplateRepresentation} (without all the nested resources)
 */
export default class HipTemplateController {
    private _stemController: StemController | null = null;
    private _cupController: HipCupController | null = null;

    constructor(
        private templateStore: HipTemplateStore,
        private plannerStore: HipPlannerStore,
        public apiService: HipPlannerAPIService,
        private _templateSynchroniser: SurgicalTemplateSynchroniser,
        private _apiOptions: CacheOptions) {
        this._copyTemplateToState(templateStore.userTemplate);
    }

    public get store(): HipTemplateStore {
        return this.templateStore;
    }

    public initialize(stemController: StemController, cupController: HipCupController): void {
        this._stemController = stemController;
        this._cupController = cupController;
    }

    // =========================================================================================
    // Getters derived from the template synchroniser
    //

    public get userChangeInProgress(): boolean {
        return this.isDirty || this.isUpdateScheduled || this.isUpdating || this.isSynchronising;
    }

    public get isDirty(): boolean {
        return this._templateSynchroniser.isDirty();
    }

    public get isUpdateScheduled(): boolean {
        return this._templateSynchroniser.isState(SyncState.UpdateScheduled);
    }

    public get isUpdating(): boolean {
        return this._templateSynchroniser.isState(SyncState.Updating);
    }

    /** @returns A template is synchronising while is fetching the data after a PUT */
    public get isSynchronising(): boolean {
        const last = this._templateSynchroniser.transitions[0];
        return last !== undefined && last.from === SyncState.Updating && last.to === SyncState.Fetching;
    }

    public get isSynchronisationError(): boolean {
        return this._templateSynchroniser.isState(SyncState.Error);
    }

    public get isSynchronisationStopped(): boolean {
        return this._templateSynchroniser.isState(SyncState.Stopped);
    }

    // =========================================================================================

    public updateCupOffset(offset: CupOffset): void {
        this.templateStore.cupOffset = offset;
    }

    public async updateCupAndLiner(components: CupComponentsRepresentation): Promise<void> {
        this.templateStore.setCupComponents(components, this._apiOptions);
        await this.cupController.onCupAndLinerSet(
            { components, rotation: this.templateStore.anatomicCupRotation, offset: this.templateStore.cupOffset });
    }

    /**
     * Updates the stem & head. This will set the current stem & head to the stem assembly combo.
     * Note: This will revert any change done by the user in the head selection panel.
     */
    public async updateStemAssembly(stemComponents: StemComponentsRepresentation): Promise<void> {
        try {
            this.templateStore.setStemComponents(stemComponents, this._apiOptions, {
                // clean up stem transform when selecting a new stem/head combo
                // Note: If the user goes back to a previous selection its selection will not be retained
                stemTransform: toRepresentation(makeNullRigidTransform()),
            });

            await this.stemController.updateSelectedStem();
        } catch (err: unknown) {
            assert.ok(err instanceof Error);
            log.error('Selected stem error: %s', err.message);
        }
    }

    /**
     * Updates **only** the neck part of the femoral assembly (e.g: xr, high-offset, standard-offset, etc),
     * Note: This will not make any change to the current head selected by the user.
     */
    public async updateStemNeckVariation(stemComponents: StemComponentsRepresentation): Promise<void> {
        // Note that the head is not set, to keep the current user selection.
        assert.ok(isStemComponentsRepresentation(stemComponents), 'not a femoral group');
        this.templateStore.$patch({
            stemComponents: stemComponents,
            stem: cloneCatalogComponent(stemComponents.component),
        });

        await this.stemController.updateSelectedStem(false);
    }

    /**
     * Updates **only** the head selection of the femoral assembly
     * Note: This will not make any change to the current stem neck variation selected by the user.
     */
    public async updateStemHead(head: HipStemHeadCatalogComponentRepresentation): Promise<void> {
        assert.ok(instanceOfNotTrackedRepresentation(head), 'head should be just data');
        this.templateStore.head = head;

        const components = this.templateStore.stemComponents;
        assert.ok(components, 'stem components are not present');
        assert.ok(isStemComponentsRepresentation(components), 'stem components are not loaded');

        await this.stemController.updateSelectedStem(false);
    }

    /**
     * Reset cup settings on surgical template
     *
     * Update the surgical template with the ACID surgical template default values (cup link and rotation/offset values)
     */
    public resetCup(): void {
        const [
            acidSurgicalTemplate,
            originalCup,
            originalLiner] = this.apiService.getAutomatedTemplateCupComponents(
            this.templateStore.automatedTemplate);

        const cupOffset = acidSurgicalTemplate.cup_offset;
        assert.ok(isCupPosition(cupOffset));

        const anatomicCupRotation = acidSurgicalTemplate.cup_rotation;
        assert.ok(isCupRotation(anatomicCupRotation));

        this.templateStore.$patch({
            cupComponents: null,
            cup: cloneCatalogComponent(originalCup),
            liner: cloneCatalogComponent(originalLiner),
            cupOffset: cupOffset,
            cupRotation: anatomicCupRotation,
        });
    }

    /** Discard previous cups associated resources in the network of data and loads the updated resources */
    public async resetCupCompleted(): Promise<void> | never {
        const components = await this.apiService.getFittedComponentsAndSetOnTemplate();

        const currentCupId = this.store.cupUri;
        assert.ok(currentCupId, 'current cup id must exist');

        const currentCupComponent = HipSurgicalTemplateComponentResource.findCup(
            components.cups, currentCupId);
        assert.ok(currentCupComponent, 'current fitted cup must exist');

        this.templateStore.setCupComponents(currentCupComponent, this._apiOptions);
        await this.cupController.onResetReady({
            components: currentCupComponent,
            rotation: this.templateStore.anatomicCupRotation,
            offset: this.templateStore.cupOffset,
        });
    }

    public filterStems(
        components: HipComponentsRepresentation,
        stemSelectors: ComponentSelectorRepresentation[]): StemComponentsRepresentation[] {
        return this.apiService.filterStems(components, stemSelectors);
    }

    /**
     * Reset stem settings on surgical template
     * Update the surgical template with the ACID surgical template default values (stem and head link).
     *
     * Note: The stem/head collection will still be valid. There is no need to discard the collections as when
     * the targets are updated.
     */
    public resetStem(): void {
        const [
            originalStem,
            originalHead,
            originalStemTransform] = this.apiService.getAutomatedTemplateStemComponents(
            this.store.automatedTemplate);
        this.store.$patch({
            stemComponents: null,
            stem: cloneCatalogComponent(originalStem),
            head: cloneCatalogComponent(originalHead),
            stemTransform: fromRepresentation(originalStemTransform),
        });

        this._templateSynchroniser.update(this.makeUpdateDocument());
    }

    public async resetStemCompleted(): Promise<void> {
        const components = await this.apiService.getFittedComponentsAndSetOnTemplate();

        const currentStemId = this.store.stemUri;
        assert.ok(!!currentStemId, 'stem uri not set');

        const stemComponents = HipSurgicalTemplateComponentResource.findStem(
            components.stems, currentStemId);
        assert.ok(stemComponents, 'current fitted stem must exist');

        this.store.setStemComponents(stemComponents, this._apiOptions);

        try {
            await this.stemController.updateSelectedStem();

            // Signal that reset is done after the assembly was updated and the cup & liner are in the
            // right position/orientation, so that matrix validation can be done at the right time.
            this.plannerStore.stem.resetState = SurgicalTemplateRecordChangeState.Done;
        } catch (e) {
            this.plannerStore.stem.resetState = SurgicalTemplateRecordChangeState.Error;
        }
    }

    /**
     * Updates the cup anteversion
     *
     * Note: The cup rotation has to be converted from the 'radiographic' angle mode
     * to the 'anatomic' angle mode for storage.
     */
    public updateCupAnteversion(value: AngleDegree): void {
        const cupRotation = CupRotationUtil.make(value, this.store.radiographicCupRotation.inclination);
        this.store.cupRotation = CupRotationUtil.toAnatomic(cupRotation);
    }

    /**
     * Updates the cup inclination and schedules a server update.
     *
     * Note: The cup rotation has to be converted from the 'radiographic' angle mode
     * to the 'anatomic' angle mode for storage.
     */
    public updateCupInclination(value: AngleDegree): void {
        const cupRotation = CupRotationUtil.make(this.store.radiographicCupRotation.anteversion, value);
        this.store.cupRotation = CupRotationUtil.toAnatomic(cupRotation);
    }

    public updateTargets(updateDocument: Partial<HipSurgicalTemplateRepresentation>): void {
        assert.ok(
            NumberUtil.isFiniteNumber(updateDocument.target_leg_length_change),
            'Target leg length to be a number');

        assert.ok(
            NumberUtil.isFiniteNumber(updateDocument.target_offset_change),
            'Target offset has to be a number');

        this.store.$patch({
            targetLegLengthChange: updateDocument.target_leg_length_change,
            targetOffsetChange: updateDocument.target_offset_change,
            // Given the translation/rotation values are per stem it seems reasonable to clear them
            // when optimizing stems. The optimizing stems logic runs server side for each stem, and
            // does not take into account the translation/rotation values (which are only
            // valid for the current selected stem).
            // This decision is also consistent with what happens when the users change the stem or
            // click the 'reset' button
            stemTransform: makeNullRigidTransform(),
        });
    }

    /**
     * As part of stem re-ranking, the server will:
     * 1) update the current catalog stem and head of the surgical template with the top-ranked ones.
     * 2) Update suitability score on stems/heads candidates (stem collection/ head collection).
     *    This means the list of fitted stems/heads can be in a different order that before.
     * 3) Update the current fitted stem and head (surgical template resource).
     *
     * In current resource structure, we need to reload
     *
     * Surgical-template
     *  (1) |______ 'stem': singleton manufacturer component
     *  (2) |______ 'head': singleton manufacturer component
     *  (3) |______ 'stem-bucket' singleton fitted component (from S3)
     *  (4) |______ 'head-bucket': singleton fitted component (from S3)
     *  (5) |______ 'stem-buckets' collection:  suitability core might change, list order might change (from s3)
     *  (6) |______ 'head-buckets' collection: list order might change (from s3)
     */
    public async updateTargetsCompleted(): Promise<void> {
        const components = await this.apiService.getFittedComponentsAndSetOnTemplate();

        const currentStemId = this.store.stemUri;
        assert.ok(!!currentStemId, 'stem uri not set');

        const stemComponents = HipSurgicalTemplateComponentResource.findStem(
            components.stems, currentStemId);
        assert.ok(stemComponents, 'current fitted stem must exist');

        this.store.setStemComponents(stemComponents, this._apiOptions);

        if (this.plannerStore.stem.reRankingState === ReRankingState.CompletedAtServer) {
            try {
                this.plannerStore.updateTargetsStateChange(ReRankingState.UpdatingScene);

                await this.stemController.updateSelectedStem();

                this.plannerStore.updateTargetsStateChange(ReRankingState.SceneUpdated);
            } catch (e) {
                this.plannerStore.updateTargetsStateChange(ReRankingState.Error);
            }
        } else {
            this.plannerStore.updateTargetsStateChange(ReRankingState.Error);
        }
    }

    public async initialiseComponents(cancelSignal?: AbortSignal): Promise<[StemComponentsRepresentation, CupComponentsRepresentation]> {
        const components = await this.apiService.getFittedComponentsAndSetOnTemplate();

        cancelSignal?.throwIfAborted();

        const currentStemId = getRequiredUri(this.store.userTemplate, LinkRelation.hipCurrentStemComponent);
        const currentStemComponents = HipSurgicalTemplateComponentResource.findStem(
            components.stems, currentStemId);
        assert.ok(currentStemComponents, 'current fitted stem must exist');

        const currentCupId = getRequiredUri(this.store.userTemplate, LinkRelation.hipCurrentCupComponent);
        const currentCupComponents = HipSurgicalTemplateComponentResource.findCup(
            components.cups, currentCupId);
        assert.ok(currentCupComponents, 'current fitted cup must exist');

        this.store.setStemComponents(
            currentStemComponents,
            this._apiOptions,
            { stemTransform: this.store.userTemplate.stem_transform });
        this.store.setCupComponents(currentCupComponents, this._apiOptions);
        this.store.initialised = true;

        return [currentStemComponents, currentCupComponents];
    }

    private makeUpdateDocument(): HipSurgicalTemplateRepresentation {
        const template = HipSurgicalTemplateStoreUtil.makeUpdateDocument(this.store);
        if (HipSurgicalTemplateStoreUtil.validate(template, this.store)) {
            return template;
        } else {
            //
            // TODO: Placeholder for writing code to emit error and display a nice looking error in the ui
            //
            throw new Error('Failed to validate template');
        }
    }

    // ============================================================================================
    //
    // Update state functions
    //
    //

    private get stemController(): StemController {
        if (this._stemController) {
            return this._stemController;
        } else {
            throw Error('HipTemplateController has not been in initialized with stemController');
        }
    }

    private get cupController(): HipCupController {
        if (this._cupController) {
            return this._cupController;
        } else {
            throw Error('HipTemplateController has not been in initialized with cupController');
        }
    }

    private _copyTemplateToState(surgicalTemplate: HipSurgicalTemplateRepresentation): void {
        const cupRotation = surgicalTemplate.cup_rotation;
        if (cupRotation) {
            this.store.cupRotation = {
                anteversion: cupRotation.anteversion || 0,
                inclination: cupRotation.inclination || 0,
            };
        }

        const cupOffset = surgicalTemplate.cup_offset;
        if (cupOffset) {
            this.store.cupOffset = {
                ap: cupOffset.ap || 0,
                si: cupOffset.si || 0,
                ml: cupOffset.ml || 0,
            };
        }

        this.store.targetLegLengthChange = this.store.userTemplate.target_leg_length_change;
        this.store.targetOffsetChange = this.store.userTemplate.target_offset_change;
    }

    // ============================================================================================
    //
    // Extra functions
    //
    //
}

const WaitingForComponentsCancelledReason = 'Waiting for components cancelled';

/**
 * Cancel the awaiting until the components can be queried.
 */
export function cancelWaitUntilCanQueryComponents(cancellation: AbortController): void {
    cancellation.abort({ name: WaitingForComponentsCancelledReason });
}

/**
 * Linear waiting strategy until the current user changes are sent
 * to the server, and synchronized and the components data can be queried.
 *
 * @return if the components can be queried or not. If the there was an error, or the caller
 * made use of the cancellation and called abort, it returns false.
 */
export async function waitUntilCanQueryComponents(controller: HipTemplateController, signal: AbortSignal): Promise<boolean> {
    try {
        await doWaitUntilCanQueryComponents(controller, signal);
        return true;
    } catch (e: unknown) {
        if (isErrorCausedBy(e, WaitingForComponentsCancelledReason)) {
            log.debug('waiting until can query cancelled');
        } else {
            log.error(e);
        }
    }

    return false;
}

/**
 * Utility that waits until the user changes are done before trying to load the components.
 *
 * TODO: Note this is a compensation for the lack of architecture on the client,
 *       where the write/read access to the surgical template should be orchestrated.
 *       E.g: The architecture could consider a queue with write/read operations,
 *       where write (PUT) and read (GET) operations can be scheduled,
 *       and before executing a read access, the write operations are finished.
 *
 * Note: This utility uses the client side artifact 'form' which holds the state
 *       of the white dialog.
 *       It is the intent of this utility to check the client side states,
 *       and the record_state of the surgical template, given in that
 *       way we can avoid querying the components if a change has been
 *       - scheduled, but not executed yet.
 *       - executed, and not done yet.
 *       The record_state of the surgical template does not know about the UI state.
 *
 * @see SurgicalTemplateFormState
 * @see SurgicalTemplateRecordState
 */
async function doWaitUntilCanQueryComponents(
    controller: HipTemplateController, signal: AbortSignal): Promise<void> | never {
    await WaitResourceUtil.waitUntil(() => {
        const changeInProgress =
                controller.isDirty ||
                controller.isUpdateScheduled ||
                controller.isUpdating ||
                controller.isSynchronising ||
                !SurgicalTemplateUtil.hasComponents(controller.store.userTemplate);

        if (changeInProgress) {
            log.debug('waiting until can query components in progress');
            return false;
        } else {
            log.debug('waiting until can query components done');
            return true;
        }
    },
    100,
    { signal });
}
