import { DoubleSide, Matrix4, Mesh, MeshBasicMaterial, SphereBufferGeometry, Vector3 } from 'three';
import {
    cupWorldPosition,
    getAcetabularGroupPosition,
    orientCupGroupAnatomic,
    setCupPosition,
    updateCupAndLiner,
} from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { loadImplant } from '@/hipPlanner/assembly/controllers/implantLoading';

import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import { HipLinerRepresentation } from '@/lib/api/representation/case/hip/HipLinerRepresentation';

import anylogger from 'anylogger';
import { CupPosition, CupRotation } from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateModel';
import { HipImplantAlias } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import { positionalPart } from '@/lib/base/RigidTransform';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { addVectors, multiplyScalar } from '@/lib/geometry/vector3';
import { AxiosInstance } from 'axios';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import plannerEventBus from '@/lib/planning/events/PlannerEventBus';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import { SurgicalTemplateRecordChangeState } from '@/components/case/change-workflow/SurgicalTemplateRecordChangeState';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { ACS } from '@/lib/base/CoordinateSystem';
import { watch, WatchStopHandle } from 'vue';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { AnatomicalCoordinateSystem, BodySide } from '@/lib/api/representation/interfaces';
import { CupComponentsRepresentation } from '@/hipPlanner/components/state/types';

const log = anylogger('CupController');

const SHOW_DEBUG_OBJECTS = false;

/** A type to describe all the required data when the cup is changed */
export type HipSurgicalTemplateCupChangeData = {
    components: CupComponentsRepresentation,
    rotation: CupRotation,
    offset: CupPosition
}

/**
 * Controller class for cup selection and adjustment. Orchestrate the calls to the assembly,
 * by converting the global-cs vectors into the scene coordinate system.
 */
export default class HipCupController {
    constructor(
        private hipTemplateStore: HipTemplateStore,
        private hipPlannerStore: HipPlannerStore,
        private assembly: HipPlannerAssembly,
        private sceneAssembly: SceneAssembly,
        private axios: AxiosInstance) {
        this.stopHandles = [
            watch(() => hipTemplateStore.cupRotation, this.onUpdateRotation.bind(this)),
            watch(() => hipTemplateStore.cupOffset, this.onUpdateOffset.bind(this)),
            watch(() => hipPlannerStore.alignmentCoords, this.onUpdateAlignmentCoords.bind(this), {immediate: true}),
        ];
    }

    private stopHandles: WatchStopHandle[];

    public off() {
        this.stopHandles.forEach(h => h());
        this.stopHandles = [];
    }

    private get globalCS(): ACS {
        return this.hipPlannerStore.alignmentCoords;
    }

    /** Update assembly with the cup/liner, translates and rotates it. */
    public async setCupAndLiner(value: HipSurgicalTemplateCupChangeData): Promise<void> {
        await this.changeCupModels3D(value.components, value.components.liner);
        this.translateCup(value.offset);
        this.rotateCup(value.rotation);

        if (SHOW_DEBUG_OBJECTS && this.assembly.cup) {
            this.visualizeCupPoints();
        }
    }

    /**
     * Change the cup assembly with the newly selected cup.
     *
     * The new cup and liners are loaded without applying any transforms
     * to preserve their reference orientation to which cup angles will
     * be applied.
     */
    public async changeCupModels3D(cup: HipCupRepresentation, liner: HipLinerRepresentation): Promise<void> {
        log.info('changing cup to size %s', cup.outer_diameter);
        // Load the cup and liner objects
        const cupModel = await loadImplant(this.axios, cup, HipImplantAlias.Cup);
        const linerModel = await loadImplant(this.axios, liner, HipImplantAlias.Liner);

        log.debug('cup transformation %o', cupModel.theObject.matrixWorld);

        // update the cup and liner models in the assembly
        const groupPosition = getAcetabularGroupPosition(liner);
        updateCupAndLiner(this.assembly, groupPosition, cupModel, linerModel);
    }

    /**
     * Change the position of the cup relative to the native joint centre.
     *
     * @param: the cup position in the global (a.k.a scanner / CT) coordinate system.
     *         This will be the cup position as expressed on the
     *         [surgical-template]{@link HipSurgicalTemplateRepresentation.cup_rotation}, as opposed to the
     *         position in the [ui-component]{@link HipCupLocalPosition},which allows to manipulate the cup
     *         in the local coordinate system.
     */
    public translateCup(offset: CupPosition): void {
        const origin = asVector3(this.globalCS.origin);
        const shiftML = multiplyScalar(this.globalCS.ml.vector, offset.ml);
        const shiftSI = multiplyScalar(this.globalCS.si.vector, offset.si);
        const shiftAP = multiplyScalar(this.globalCS.ap.vector, offset.ap);
        setCupPosition(this.assembly, addVectors(origin, shiftAP, shiftSI, shiftML));
    }

    /**
     * Change the orientation of the cup
     *
     * We want to change the anteversion and abduction angles of the cup and liner
     * as a rigid group, independent of the femur and femur components.
     *
     * The cup and liner group is the CupGroup in the CupAssembly, which is what we re-orient.
     */
    public rotateCup(rotation: CupRotation): void {
        orientCupGroupAnatomic(
            this.assembly,
            rotation.anteversion,
            rotation.inclination,
            asVector3(this.globalCS.si.vector),
            asVector3(this.globalCS.ap.vector));
    }

    /**
     * Visualize all the point of interest of the cup and logs its position in the world space.
     * - Cup world position after [shifting using the Global CS]{@link translateCup}.
     * - Cup liner head centre.
     * - Cup origin based on the Tmatrix.
     * - Cup assembly point.
     *
     * @see {@link }https://miro.com/app/board/o9J_l2Tpg4Y=/}
     */
    private visualizeCupPoints(): void {
        const makeSpherePoint = (radius: number, color: string) => {
            const geometry = new SphereBufferGeometry(
                radius, 10, 10, 0, Math.PI * 2, 0, Math.PI);
            return new Mesh(geometry, new MeshBasicMaterial(
                { transparent: true, opacity: 0.7, side: DoubleSide, vertexColors: true, color }));
        };

        const addPointToScene = (radius: number, color: string, position: Vector3) => {
            const point = makeSpherePoint(radius, color);
            point.position.set(position.x, position.y, position.z);
            this.sceneAssembly.scene.add(point);
        };

        const liner = this.assembly.liner;
        const cup = this.assembly.cup;
        const linerBucket = liner.getCaseComponent() as HipLinerRepresentation;

        const linerHeadCentre = asVector3(linerBucket.head_centre);
        const cupWorldPositionAfterShiftCup3D = cup.worldPosition;
        const cupOriginInTMatrix = asVector3(positionalPart(cup.objectMatrix));
        const cupAssemblyPosition = cupWorldPosition(this.assembly);

        addPointToScene(2, 'green', cupWorldPositionAfterShiftCup3D);
        addPointToScene(2, 'blue', linerHeadCentre);
        addPointToScene(2, 'red', cupOriginInTMatrix);
        addPointToScene(2, 'yellow', cupAssemblyPosition);

        log.info(
            'Current cup position (green): %s, \n' +
            'Liner head centre (blue):     %s, \n' +
            'Cup origin in T matrix (red): %s, \n' +
            'Cup assembly (yellow):        %s',
            formatArrayNumber(cupWorldPositionAfterShiftCup3D.toArray(), 4),
            formatArrayNumber(linerHeadCentre.toArray(), 4),
            formatArrayNumber(cupOriginInTMatrix.toArray(), 4),
            formatArrayNumber(cupAssemblyPosition.toArray(), 4));
    }

    /**
     * On reset handler
     *
     * Notify the global state of the [reset state]{@link CupState.resetState}
     * This is done after the assembly was updated and the cup & liner are in the right position/orientation,
     * so the matrices validation can be done at the right time.
     */
    public async onResetReady(value: HipSurgicalTemplateCupChangeData): Promise<void> {
        try {
            await this.setCupAndLiner(value);
            plannerEventBus.$emit(PlannerEvent.HipPlannerCupAssemblyCupSet, this.assembly);

            this.hipPlannerStore.cup.resetState = SurgicalTemplateRecordChangeState.Done;
        } catch (e) {
            this.hipPlannerStore.cup.resetState = SurgicalTemplateRecordChangeState.Error;
        }
    }

    /** On cup/liner change handler */
    public async onCupAndLinerSet(value: HipSurgicalTemplateCupChangeData): Promise<void> {
        await this.setCupAndLiner(value);
        plannerEventBus.$emit(PlannerEvent.HipPlannerCupAssemblyCupSet, this.assembly);
    }

    private onUpdateRotation(value: CupRotation): void {
        this.rotateCup(value);
        plannerEventBus.$emit(PlannerEvent.HipPlannerCupAssemblyMoved, this.assembly);
    }

    private onUpdateOffset(value: CupPosition): void {
        this.translateCup(value);
        plannerEventBus.$emit(PlannerEvent.HipPlannerCupAssemblyMoved, this.assembly);
    }

    private onUpdateAlignmentCoords(coords: AnatomicalCoordinateSystem): void {
        const ml = asVector3(coords.ml.vector);
        const left = this.assembly.side === BodySide.Left ? ml : ml.negate();
        const posterior = asVector3(coords.ap.vector);
        const superior = asVector3(coords.si.vector).negate();
        const origin = asVector3(coords.origin);

        this.assembly.alignmentCoordinates.worldTransform =
            new Matrix4().makeBasis(left, posterior, superior).setPosition(origin);
        this.assembly.ctCoordinates.worldTransform =
            new Matrix4().setPosition(origin);
    }
}
