const IS_POSITIVE_INTEGER = /^[0-9]+?$/;
const IS_INTEGER = /^-?[0-9]+?$/;  // Numeric value that can be positive or negative

export const isNotEmpty = (field: string): boolean =>
    field !== undefined && field.length > 0;

export const isPositiveInteger = (field: string): boolean =>
    IS_POSITIVE_INTEGER.test(field);


export const isInteger = (field: string): boolean =>
    IS_INTEGER.test(field);
