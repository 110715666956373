import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';

/**
 * The state diagram simplification
 *
 *                                              Ok
 *       ┌───────────────────────────────────────────────────────────────────────────────────────────┐
 *       │                                                                                           │
 *       ▼                                                                                           │
 * ┌──────────────────┐                      ┌──────────────────────┐                    ┌───────────┴────────┐
 * │     Idle (start) ├──────────────────────►   FetchScheduled     ├────────────────────►   Fetching (GET)   │
 * └▲────▲────────┬───┘                      └──────────────────────┘                    └────────────▲───────┘
 *  │    │        │                                                                                   │
 *  │    │        │ User updates results                                                              │
 *  │    │        │ in changes                                                                        │
 *  │    │        │                                                                                   │
 *  │    │        │                          ┌───────────────────────┐                    ┌───────────┴────────┐
 *  │    │        └─────────────────────────►│     UpdateScheduled   ├────────────────────►  Updating (PUT)    │
 *  │    └───────────────────────────────────┤                       │                    │                    │
 *  │  User updates results in no changes    └───┬───────────▲───────┘                    └────────────────────┘
 *  │                                            │           │ User updates results in changes
 *  │                                            └───────────┘
 *  │
 *  │
 *  │                                                                                     │
 *  │  *** Only from Idle state                                                           │ *** From any other state
 *  ▼                                                                                     ▼
 * ┌──────────────────┐                                                      ┌──────────────────────────┐
 * │     Paused       │                                                      │         Stopped (Final)  │
 * └──────────────────┘                                                      └──────────────────────────┘
 */
export enum SyncState {
    /** The initial state */
    Idle = 'idle',

    FetchScheduled = 'fetch-scheduled',
    Fetching = 'fetching',

    UpdateScheduled = 'update-scheduled',

    /** The synchronizer is doing a PUT */
    Updating = 'updating',

    /**
     * The synchronizer can be paused only from the idle state.
     * Once resumed it will go to idle.
     *
     * Note: This is done for simplicity on the implementation and to avoid interruptions of previous states.
     * Async functions running on states that were interrupted could lead to bogus code.
     */
    Paused = 'paused',

    /** Not resumable state */
    Stopped = 'stopped',
    Error = 'error',
}

export type SynchronisationTransition = {
    from: SyncState,
    to: SyncState,
}

export enum TemplateTasksState {
    /** The task is new, and has been added to the queue. */
    New = 'new',

    /**
     * The task has been scheduled to be updated.
     *
     * Note:
     * 1. **not all the tasks** will ever reach this state. Tasks that have been superseded
     * by the latest's changes will stay in the 'new' state.
     * 2. For simplicity in the implementation, tasks that are scheduled but superseded
     * by latest's changes will stay as 'scheduled' (we only care about the latest task state)
     */
    Scheduled = 'scheduled',

    /**
     * The task is in progress.
     *
     * Note: An update task **will only transition** to in-progress **if it is the latest one at that time
     * and has changes that need to be saved, but** while it is in-progress another update task
     * can be added to the queue, meaning that the in-progress task is not the latest one anymore and
     * will not be taken into consideration when analysing if the synchronisation is dirty or not.
     */
    InProgress = 'in-progress',

    /** The update task has been completed successfully. */
    Completed = 'completed',

    /** The update task failed */
    Error = 'error',
}

export type TemplateUpdateTask = {
    value: HipSurgicalTemplateRepresentation,
    state: TemplateTasksState,
}

export type TemplateSyncState = {
    /** The current state that the synchronizer is at */
    currentState: SyncState

    /** Whether the synchronizer has been started or not */
    isStarted: boolean

    /**
     * Remember that when on idle state has to pause.
     * Note: that it can only transition to the idle state if it is paused
     */
    hasToPause: boolean

    /**
     * The limited list of previous transitions.
     * This could be helpful to debug or take conclusions about what had happened.
     */
    transitions: SynchronisationTransition[]

    /**
     * The id of the current timer.
     *
     * It will be set when going from:
     * 1. {@link SyncState.FetchScheduled} to {@link SyncState.Fetching} state. **OR**
     * 2. {@link SyncState.UpdateScheduled} to {@link SyncState.Updating} state.
     *
     * Note the timer is always cleared when successfully transitioning to any state.
     * This means that the timer is only valid for the current state.
     */
    timer: number | null

    updateTasks: TemplateUpdateTask[]
}

export function makeInitialSyncState(): TemplateSyncState {
    return {
        currentState: SyncState.Idle,
        isStarted: false,
        hasToPause: false,
        transitions: [],
        timer: null,
        updateTasks: [],
    };
}
