
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import TitleUtil from '@/components/TitleUtil';
    import BlankTitle from '@/components/title/BlankTitle.vue';

    import AnalyticsUtil from '@/lib/analytics/AnalyticsUtil';
    import { mapStores } from 'pinia';
    import { useVersion } from '@/stores/version';

    @Component({ components: { BlankTitle }, computed: { ...mapStores(useVersion) } })
    export default class About extends Vue {
        versionStore!: ReturnType<typeof useVersion>;

        async mounted(): Promise<void> {
            TitleUtil.$emit(this, { titleComponentType: BlankTitle, notificationComponentType: '' });

            AnalyticsUtil.screenView(this.$gtag, AnalyticsUtil.ScreenName.About);

            this.versionStore.load();
        }
    }
