import ResourceService from '@/lib/api/ResourceService';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import HipComponentsCatalogResource from '@/lib/api/resource/components/global-catalog/HipComponentsCatalogResource';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import {
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';

/**
 * This class provides an interface to fetch the different components available for the hip
 */
export default class ComponentsCatalogResource implements ResourceService {

    /**
     * Loads components catalog based on the case resource type.
     */
    public static async getComponentsCatalog(
        apiResource: ApiRepresentation, options: CacheOptions): Promise<HipComponentsCatalogRepresentation> {
        return await HipComponentsCatalogResource.getComponentsCatalog(apiResource, options);
    }
}
