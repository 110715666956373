import { getUri, Uri } from 'semantic-link';
import { AxiosInstance } from 'axios';
import { DicomMessage, DicomMessageLevelUtil, DicomSeries } from '@/lib/dicom/DicomSeries';
import Bottleneck from 'bottleneck';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import {
    makeSeriesCreateDataRepresentation,
    SeriesCreateDataRepresentation,
    SeriesMessageRepresentation,
    StudyDicomFileCreateDataRepresentation,
} from '@/lib/api/representation/SeriesRepresentation';
import CaseResource from '@/lib/api/resource/case/CaseResource';

import anylogger from 'anylogger';
import JsonUtil from '@/lib/dicom/JsonUtil';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import CaseStudyResource from '@/lib/api/resource/case/study/CaseStudyResource';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { DicomUploadTask } from '@/components/case-settings/scan/workflow/DicomUploadTask';

const log = anylogger('DicomUploadUtil');

export default class DicomUploadUtil {
    /**
     * Abandon the $api and just bang the resources in. This needs to be followed
     * up as this approach will have negative downstream effects.
     */
    public static async uploadDicomFiles(
        $http: AxiosInstance,
        caseItem: CaseRepresentation,
        task: DicomUploadTask,
        fileMutator: (file: File) => Promise<File>,
        options?: CacheOptions): Promise<void> {
        const { value: series, id: seriesId } = task.getUploadData();
        const abortSignal: AbortSignal = task.getAbortSignal();

        //
        // Get the list of files to upload.
        //
        const fileInfos = series.items;
        log.info('Begin upload of %d DICOM files', fileInfos.length);
        const study = await CaseStudyResource.createStudy(
            caseItem, this.makeStudyCreateData(seriesId, series), { signal: abortSignal, ...options });
        if (study && await CaseStudyResource.getStudy(study, options)) {
            const ctFileCollectionUri = getUri(study, 'files');
            if (ctFileCollectionUri) {
                // The operation of reading the dicom file and mutating the file is intensive on
                // local disk I/O and memory. This shouldn't be the rate limiting operation and
                // having a couple 'ready' in the queue helps reduce latency.
                const mutatorLimiter = new Bottleneck({ maxConcurrent: 2 });

                // The operation of uploading (POSTing) the file is network I/O intensive. In theory
                // we limit this so that the server is not overwhelmed (i.e. DOS attack on ourselves) and
                // so that under adverse conditions some uploads progress.
                const uploadLimiter = new Bottleneck({ maxConcurrent: 6 });

                await Promise.all(fileInfos.map(async (dicomInfo) => {
                    const dicomFile = dicomInfo.file;
                    await uploadLimiter.schedule(async () => {
                        const anonymousDicom = await mutatorLimiter.schedule(async () => {
                            task.incrementStarted();
                            log.debug('ANONYMISE file \'%s\'', dicomFile.name);

                            if (abortSignal.aborted) {
                                throw new Error('upload dicoms aborted');
                            }
                            return await fileMutator(dicomFile);
                        });

                        log.debug('POST file \'%s\' to \'%s\'', dicomFile.name, ctFileCollectionUri);
                        if (abortSignal.aborted) {
                            throw new Error('upload dicoms aborted');
                        }

                        const ctFileUri = await this.createStudyDicomFile(
                            ctFileCollectionUri,
                            {
                                name: dicomFile.name,
                                messages: dicomInfo.messages.map<SeriesMessageRepresentation>(m => {
                                    return {
                                        level: DicomMessageLevelUtil.toString(m.level),
                                        message: m.message,
                                    };
                                }),
                            },
                            anonymousDicom,
                            $http,
                            abortSignal);
                        log.debug('CT File: %s', ctFileUri);

                        task.incrementCompleted();
                        return ctFileUri;
                    });
                }));

                await CaseResource.updateCaseStudy(caseItem, getRequiredUri(study, LinkRelation.self), options);
                log.debug('Upload of DICOM study files complete');
            } else {
                // all studies must have a file collection
                throw new Error('Study has no file collection');
            }
        } else {
            throw new Error('Failed to create study');
        }
    }

    /**
     * Create a new study file using the an anonymous DICOM file as the content
     * for the DICOM.
     *
     * It is important to note that creating a DICOM file is an atomic operation (as the resource
     * is immutable). Both the file content and the resource meta-data (a small JSON document) are
     * provided in a single http request.
     */
    private static async createStudyDicomFile(
        ctFileCollectionUri: Uri,
        createData: StudyDicomFileCreateDataRepresentation,
        mutatedDiCom: File,
        $http: AxiosInstance,
        signal: AbortSignal): Promise<Uri> {
        const response = await $http
            .post(
                ctFileCollectionUri,
                mutatedDiCom,
                {
                    headers: {
                        'Content-Type': mutatedDiCom.type,
                        'X-Create-Data-JSON': JsonUtil.toJsonHeader(createData),
                    },
                    signal,
                });

        if (response && response.status === 201 && response.headers.location) {
            return response.headers.location;
        }
        throw new Error(`Failed to upload DICOM file ('${mutatedDiCom.name}')`);
    }

    /**
     * Make a message in wire format (with enum as a string)
     */
    public static makeMessage(message: DicomMessage): SeriesMessageRepresentation {
        return {
            level: DicomMessageLevelUtil.toString(message.level),
            message: message.message,
        };
    }

    private static makeStudyCreateData(seriesId: string, series: DicomSeries): SeriesCreateDataRepresentation {
        return makeSeriesCreateDataRepresentation(
            'drag-n-drop upload',
            seriesId,
            series.items.length,
            series.messages?.map(DicomUploadUtil.makeMessage),
            series
                .items
                ?.filter(info => info.isExcluded)
                .map(info => {
                    return {
                        name: info.file.name || '',
                        messages: info.messages?.map(DicomUploadUtil.makeMessage),
                    };
                }));
    }
}
