import { SynchronisationTransition } from '@/hipPlanner/stores/template/TemplateSyncState';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';

export type HipTemplateAndPrevious = {
    current: HipSurgicalTemplateRepresentation
    previous: HipSurgicalTemplateRepresentation
};

export type HipTemplateAndServer = {
    current: HipSurgicalTemplateRepresentation
    server: HipSurgicalTemplateRepresentation
};

export const HipEvent = {
    Transition: {
        name: 'event:hip-synchronisation-event-transition',
        payload: (transitions: SynchronisationTransition[]): SynchronisationTransition[] => transitions,
    },
    ChangesDetected: {
        name: 'event:hip-synchronisation-event-changes-detected',
        payload: (template: HipSurgicalTemplateRepresentation): HipSurgicalTemplateRepresentation => template,
    },
    RecordStateChange: {
        name: 'event:surgical-template-record-state-change',
        payload: (
            current: HipSurgicalTemplateRepresentation,
            previous: HipSurgicalTemplateRepresentation): HipTemplateAndPrevious => {
            return { current, previous };
        },
    },
    /**
     * Event emitted when it is detected that the surgical template version in the server is not
     * the same version that the current surgical template being planned.
     *
     * e.g:
     * - This could be the case of 2 people working on the surgical template in different sessions.
     * - The same user is using the planner in two different tabs.
     */
    VersionMismatch: {
        name: 'event:surgical-template-version-mismatch',
        payload: (
            current: HipSurgicalTemplateRepresentation,
            server: HipSurgicalTemplateRepresentation): HipTemplateAndServer => {
            return { current, server };
        },
    },
};
