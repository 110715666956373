/**  * A guard to detect whether the object is a {@link Tooltip}  */
import { instanceOfTrackedRepresentation } from '@/lib/semantic-network';
import { instanceOfLinkedRepresentation, LinkedRepresentation } from 'semantic-link';

/**
 *  This type is only an alias at the moment
 *  TODO: Needs to be something like this: Omit<Tracked, 'state'> but Omit does not work with symbols ;
 */
export type NotTracked = LinkedRepresentation

/**
 * Guard clause to determine a value is a linked representation but does not have a State symbol.
 * This is useful when we want to validate an object is only data.
 */
export function instanceOfNotTrackedRepresentation(item: unknown): item is NotTracked {
    return instanceOfLinkedRepresentation(item) && !instanceOfTrackedRepresentation((item));
}
