import {
    AngleDefinition,
    CaseIdRepresentation,
    OperativeSide,
    PreoperativePlanRepresentation,
} from '@/lib/intellijoint/PreoperativePlanRepresentation';
import assert from 'assert';
import { isHipCaseRepresentation } from '@/lib/api/representation/case/CaseUtil';
import StudyMeasurementsUtil from '@/lib/api/resource/case/study/StudyMeasurementsUtil';
import { NumberUtil } from '@/lib/base/NumberUtil';
import { isCupRotation } from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateModel';
import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';
import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
import { BodySide, BodySideType } from '@/lib/api/representation/interfaces';
import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
import LinkRelation from '@/lib/api/LinkRelation';
import { LinkUtil } from 'semantic-link';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import CupRotationUtil from '@/hipPlanner/components/state/CupRotationUtil';
import {
    HipReportMeasurementsRepresentation,
} from '@/lib/api/representation/case/plan/HipReportMeasurementsRepresentation';

const uglySize = /^\s*(\d+)\s*(?:mm)?\s*$/;

/**
 * Reverse map horrible cup sizes of the form '10 mm' to a simple number.
 * Some of the numbers are numbers (rather than strings), so handle that as well.
 */
function mapStringMillimiterSizeToNumber(aSize?: string | number): number | undefined {
    if (typeof aSize === 'number') {
        return aSize;
    } else if (typeof aSize === 'string') {
        const matches = aSize.match(uglySize);
        if (matches?.[1]) {
            return Number.parseInt(matches[1]);
        }
    }
    return undefined;
}

function makeOperativeSide(side?: BodySideType): OperativeSide {
    switch (side) {
        case BodySide.Left:
            return OperativeSide.Left;
        case BodySide.Right:
            return OperativeSide.Right;
        default:
            return OperativeSide.Unknown;
    }
}

/**
 * @return the value of a number rounded to the nearest integer.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
 */
function asWholeNumber(angle: number): number {
    return Math.round(angle);
}

/**
 * Make a string of a similar form to that which is displayed in the PDF report.
 */
function makeCaseName(project: HipCaseRepresentation, template: HipSurgicalTemplateRepresentation, plan: PlanRepresentation): string {
    const caseId = UriDeconstructionUtil.pathNameOfLinkAsNumber(project, LinkRelation.self);
    const surgicalTemplateHistoryId = UriDeconstructionUtil.pathNameOfLinkAsNumber(template, LinkRelation.canonical);
    const planId = UriDeconstructionUtil.pathNameOfLinkAsNumber(plan, LinkRelation.self);
    return `Case ${caseId} (Plan ${surgicalTemplateHistoryId}.${planId})`;
}

function makeIdentifiers(
    project: HipCaseRepresentation, template: HipSurgicalTemplateRepresentation, plan: PlanRepresentation): CaseIdRepresentation {
    const caseId = UriDeconstructionUtil.pathNameOfLinkAsNumber(project, LinkRelation.self);
    const planId = UriDeconstructionUtil.pathNameOfLinkAsNumber(plan, LinkRelation.self);
    return {
        p: {
            h: LinkUtil.getUri(plan, LinkRelation.self) || '',
            id: planId,
        },
        st: {
            h: LinkUtil.getUri(template, LinkRelation.nonCanonical) || '',
        },
        sth: {
            h: LinkUtil.getUri(template, LinkRelation.canonical) || '',
        },
        c: {
            h: LinkUtil.getUri(project, LinkRelation.self) || '',
            id: caseId,
        },
    };
}

export default class PreoperativePlanRepresentationFactory {
    public static make(projectStore: ProjectStore, planStore: PlanReportStore): PreoperativePlanRepresentation {
        const project = projectStore.project;
        const surgeon = projectStore.surgeon;
        const plan = planStore.plan;
        const template = planStore.template;
        const specification = planStore.specification;
        const preferences = planStore.preferences;
        const studyMeasurements = planStore.studyMeasurements;
        const reportMeasurements = planStore.measurements;

        assert.ok(isHipCaseRepresentation(project), 'not a hip project');
        assert.ok(!!surgeon, 'no surgeon');

        const projectOperativeSide = project.side;
        assert.ok(!!projectOperativeSide, 'no preoperative side');
        const operativeSide = makeOperativeSide(projectOperativeSide);

        assert.ok(!!plan, 'no plan');
        assert.ok(!!specification, 'no specification');
        assert.ok(!!preferences, 'no preferences');
        assert.ok(!!studyMeasurements, 'no study measurements');
        assert.ok(!!reportMeasurements, 'no report measurements');

        assert.ok(!!template, 'no template');
        const stem = template.stem;
        const cup = template.cup;
        const liner = template.liner;
        const head = template.head;

        assert.ok(!!stem, 'no stem');
        assert.ok(!!head, 'no head');
        assert.ok(!!cup, 'no cup');
        assert.ok(!!liner, 'no liner');

        const cupSize = mapStringMillimiterSizeToNumber(cup.size);
        const stemSize = mapStringMillimiterSizeToNumber(stem.size);
        const headSize = mapStringMillimiterSizeToNumber(head.size);

        assert.ok(!!cupSize, 'no cupSize');
        assert.ok(!!stemSize, 'no stemSize');
        assert.ok(!!headSize, 'no headSize');

        const femurAnteversionAngle = StudyMeasurementsUtil.getFemurAnteversionAngle(
            studyMeasurements, projectOperativeSide);
        const acetabularAnteversion = StudyMeasurementsUtil.getAcetabularAnteversion(
            studyMeasurements, projectOperativeSide);
        const acetabularInclination = StudyMeasurementsUtil.getAcetabularAbduction(
            studyMeasurements, projectOperativeSide);

        const combinedVersion = PreoperativePlanRepresentationFactory.getCombinedVersion(reportMeasurements);
        const combinedLegLengthDifference = reportMeasurements.leg_length_change;
        const combinedLegOffsetDifference = reportMeasurements.offset_change;

        const femurAnteversionValue = femurAnteversionAngle?.value;
        const acetabularInclinationValue = acetabularInclination?.value;
        const acetabularAnteversionValue = acetabularAnteversion?.value;
        const stemAnteversionValue = reportMeasurements.stem_angle_anteversion;
        const stemResectionlessTrochanterValue = reportMeasurements.resection_distances_lesser_trochanter;

        const createdOn = planStore.plan.created_on;
        assert.ok(!!createdOn, 'no created_on plan');

        assert.ok(NumberUtil.isFiniteNumber(femurAnteversionValue), 'femurAnteversionValue is not a finite number');
        assert.ok(NumberUtil.isFiniteNumber(acetabularAnteversionValue), 'acetabularAnteversionValue is not a finite number');
        assert.ok(NumberUtil.isFiniteNumber(acetabularInclinationValue), 'acetabularInclinationValue is not a finite number');
        assert.ok(NumberUtil.isFiniteNumber(stemAnteversionValue), 'stemAnteversionValue is not a finite number');
        assert.ok(NumberUtil.isFiniteNumber(stemResectionlessTrochanterValue), 'stemResectionlessTrochanterValue is not a finite number');
        assert.ok(NumberUtil.isFiniteNumber(combinedVersion), 'combinedVersion is not a finite number');
        assert.ok(NumberUtil.isFiniteNumber(combinedLegLengthDifference), 'combinedLegLengthDifference is not a finite number');
        assert.ok(NumberUtil.isFiniteNumber(combinedLegOffsetDifference), 'combinedLegOffsetDifference is not a finite number');

        const anatomicCupRotation = template.cup_rotation;
        assert.ok(isCupRotation(anatomicCupRotation), 'not a cup rotation');

        const radiographicCupRotation = CupRotationUtil.toRadiographic(anatomicCupRotation);

        return {
            qid: 1,
            id: makeCaseName(project, template, plan),
            ids: makeIdentifiers(project, template, plan),
            n: project.name,
            nm: NameRepresentationUtil.format(surgeon.name),
            hs: operativeSide,
            dt: createdOn,
            def: AngleDefinition.Radiographic,
            cupa: {
                av: asWholeNumber(radiographicCupRotation.anteversion),
                in: asWholeNumber(radiographicCupRotation.inclination),
            },
            lega: {
                ll: asWholeNumber(combinedLegLengthDifference),
                os: asWholeNumber(combinedLegOffsetDifference),
                /* the 'ap' is not calculated at this time */
            },
            arpa: {
                av: asWholeNumber(acetabularAnteversionValue),
                in: asWholeNumber(acetabularInclinationValue),
            },
            cnm: cup.system,
            csz: cupSize,
            lnr: `${liner.size} (${liner.type})`,
            snm: stem.system,
            stp: stem.type,
            ssz: stemSize,
            hdm: headSize,
            hdp: head.offset,
            ncp: asWholeNumber(stemResectionlessTrochanterValue),
            fv: asWholeNumber(femurAnteversionValue),
            sv: asWholeNumber(stemAnteversionValue),
            cv: asWholeNumber(combinedVersion),
        };
    }

    /**
     * Formula:
     * -------
     * Widmer and Zurfluh (2004) proposed the following formula, suggesting that cup anteversion has a higher impact
     * on combined version.
     *
     * Cup anteversion + 0.7 x Stem Anteversion = 37.3° (Target combined version)
     */
    public static getCombinedVersion(reportMeasurements: HipReportMeasurementsRepresentation): number | null {
        const scaledStemVersion = NumberUtil.isFiniteNumber(reportMeasurements.stem_angle_anteversion) ?
            0.7 * reportMeasurements.stem_angle_anteversion : null;

        return NumberUtil.sumIfFinite(scaledStemVersion, reportMeasurements.cup_anteversion ?? null);
    }
}
