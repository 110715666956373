import { CaseSettingsState } from '@/stores/caseSettings/types';
import ResourceUtil from '@/lib/api/ResourceUtil';
import LinkRelation from '@/lib/api/LinkRelation';
import { STEMTYPE_KEY_SEPARATOR } from '@/components/data/combobox/hip/HipStemTypeSelectorOptions';
import { HipCupAnteversionMode } from '@/lib/api/representation/interfaces';
import { HipCupFitMethodEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { Link, LinkUtil } from 'semantic-link';

export const projectRequestBodyFromState = (state: CaseSettingsState) => {
    const {
        name,
        description,
        procedure,
        implantSide,
    } = state.settings.caseInfo;

    // Update selected surgeon by updating the `surgeon` link href in the links object.
    ResourceUtil.setLink(
        state.project,
        LinkRelation.surgeon,
        {
            rel: LinkRelation.surgeon,
            href: state.settings.selectedSurgeon,
        });

    return {
        description: description,
        name: name,
        procedure: procedure,
        side: implantSide,
        version: state.project?.version || '',
        links: state.project?.links || [],
    };
};

export const unassignSurgeonRequestBody = (state: CaseSettingsState) => {
    // Create an update project request body with the store original state but remove the selected surgeon from the links object
    const {
        name,
        description,
        procedure,
        implantSide,
    } = state.original.caseInfo;

    const firstMatch = state.project.links.findIndex((item: Link) => LinkUtil.matches([item], LinkRelation.surgeon));
    state.project.links.splice(firstMatch, 1);

    return {
        description: description,
        name: name,
        procedure: procedure,
        side: implantSide,
        version: state.project?.version || '',
        links: state.project?.links || [],
    };
};

export const patientRequestBodyFromState = (state: CaseSettingsState) => {
    const {
        birth_date,
        sex,
        name,
    } = state.settings.patient;

    return {
        birth_date: birth_date,
        sex: sex,
        name: name,
        links: state.project?.patient?.links || [],
    };
};

export const surgicalSpecificationRequestBodyFromState = (state: CaseSettingsState) => {
    const {
        stemTypes,
        legLengthChange,
        offsetChange,
        anteversionAngle,
        anteversionMode,
        inclinationAngle,
        fittingMode,
    } = state.settings.surgicalSpecification;
    const selectedStemTypes = stemTypes
        .map((selected:string) => selected.split(STEMTYPE_KEY_SEPARATOR))
        .map(([system, type]: string[]) => ({ system, type, size: null }));
    return {
        cup: {
            abduction_angle: Number(inclinationAngle),
            anteversion_angle: (
                anteversionMode === HipCupAnteversionMode.Manual &&
                fittingMode !== HipCupFitMethodEnum.Beverland
            ) ? Number(anteversionAngle) : null,
            fit_method: fittingMode,
        },
        stem: {
            selector: selectedStemTypes,
        },
        target: {
            leg_length_change: Number(legLengthChange),
            offset_change: Number(offsetChange),
        },
        links: state.project?.surgicalSpecification?.links || [],
    };
};
