import { Euler, Matrix4, Vector2, Vector3, Vector4 } from 'three';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';
import { getPosition } from '@/lib/base/matrix';
import { radToDeg } from 'three/src/math/MathUtils';
import { identity } from 'ramda';
import { positionalPart } from '@/lib/base/RigidTransform';
import { ThreeUtil } from '@/lib/base/ThreeUtil';

const DEFAULT_PRECISION = 2;
const DEFAULT_SEPARATOR = ', ';

export function formatVector(value: Vector2 | Vector3 | Vector4, precision = DEFAULT_PRECISION): string {
    return formatArrayNumber(value.toArray(), precision);
}

export function formatEuler(angles: Euler, options: { degrees?: boolean, precision?: number } = {}): string {
    return formatArrayNumber(
        angles.toArray()
            .slice(0, -1)
            .map((options.degrees ?? true) ? a => radToDeg(a) : identity),
        options.precision ?? DEFAULT_PRECISION,
    );
}

export function formatMatrixEuler(matrix: Matrix4, options: { separator?: string, precision?: number } = {}): string {
    return [
        `position: ${formatArrayNumber(getPosition(matrix).toArray(), options.precision ?? DEFAULT_PRECISION)}`,
        `rotation: ${formatEuler(new Euler().setFromRotationMatrix(matrix), options)}`,
    ].join(options.separator ?? DEFAULT_SEPARATOR);
}

export function formatMatrixBasis(value: Matrix4, options: { separator?: string, precision?: number } = {}): string {
    const position = positionalPart(value);
    const { x, y, z } = ThreeUtil.getBasis(value);
    return [
        `position: ${formatVector(position, options.precision)}`,
        `x: ${formatVector(x, options.precision)}`,
        `y: ${formatVector(y, options.precision)}`,
        `z: ${formatVector(z, options.precision)}`,
    ].join(options.separator ?? DEFAULT_SEPARATOR);
}
