import { getUri, LinkedRepresentation, RelationshipType, Uri } from 'semantic-link';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { AxiosInstance } from 'axios';
import { BufferGeometry } from 'three';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import { ModelRepresentation } from '@/lib/api/representation/ModelRepresentation';
import ResourceService from '@/lib/api/ResourceService';
import LinkRelation from '@/lib/api/LinkRelation';

import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import AxiosBrowserCacheUrlMutation from '@/lib/http/AxiosBrowserCacheUrlMutation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import {
    HipCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCatalogComponentRepresentation';
import {
    HipComponentsCatalogPropCollection,
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import ResourceUtil from '@/lib/api/ResourceUtil';
import { getRequiredSelfUri, getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import HipComponentsCatalogResource from '@/lib/api/resource/components/global-catalog/HipComponentsCatalogResource';
import { CatalogComponentRepresentation } from '@/lib/api/representation/catalog/CatalogComponentRepresentation';

import anylogger from 'anylogger';
import { HipComponentsRepresentationAPI } from '@/lib/api/representation/case/hip/HipComponentsRepresentation';
import { CupComponentsRepresentation, StemComponentsRepresentation } from '@/hipPlanner/components/state/types';

const log = anylogger('HipSurgicalTemplateComponentResource');

export default class HipSurgicalTemplateComponentResource implements ResourceService {

    /** Get the collection of fitted stems */
    public static async getFittedComponentsAPIData(
        http: AxiosInstance, surgicalTemplate: HipSurgicalTemplateRepresentation): Promise<HipComponentsRepresentationAPI | null> {
        const componentsUri = getRequiredUri(surgicalTemplate, LinkRelation.componentsNew);
        const { data } = await http.get(componentsUri);

        if (data) {
            return data;
        } else {
            log.warn('no components for template: %s', getRequiredSelfUri(surgicalTemplate));

            return null;
        }
    }

    /**
     * Get a non-json/xml/yaml native representation of the resource, e.g PLY file
     * for a model resource.
     * The result is a promise of the given media type.
     * Important: the media type uses the built-in media type converters of Axios,
     * of which one exists for PLY files.
     */
    public static getMediaType<T extends LinkedRepresentation, TResult>(
        r: T,
        rel: RelationshipType,
        itemName: keyof T,
        axios: AxiosInstance): Promise<TResult> {
        const uri = getUri(r, rel);
        if (uri) {
            return axios.get<TResult>(
                uri,
                AxiosBrowserCacheUrlMutation.makeMutationOption({
                    headers: {
                        accept: 'model/*,*/*;q=0.5',
                    },
                    responseType: 'arraybuffer',
                }))
                .then((response) => {
                    if (response && response.status === 200) {
                        const data = response.data as TResult;
                        r[itemName] = data as any;
                        return data;
                    }
                    throw new Error(`Failed to get media: ${status}`);
                });
        }
        return Promise.reject(new Error('No such link relation'));
    }

    /**
     * Get the geometry model of a selected implant component (stem, cup, liner, etc.) in its unaligned position
     */
    public static getComponentModelPLY<T extends CaseComponentRepresentation>(
        axios: AxiosInstance,
        component: T): Promise<never | BufferGeometry> {
        return this.getMediaType<ModelRepresentation, BufferGeometry>(
            component, LinkRelation.component, 'model', axios);
    }

    /** Get a hip case stem if it exists */
    public static findStem(
        stems: StemComponentsRepresentation[], stemComponentUri: Uri): StemComponentsRepresentation | null {
        return stems.find((stem: HipStemRepresentation): boolean => {
            return getRequiredUri(stem, LinkRelation.component) === stemComponentUri;
        }) ?? null;
    }

    /**
     * Get a hip case stem if it exists
     */
    public static findCup(cups: CupComponentsRepresentation[], cupComponentUri: Uri): CupComponentsRepresentation | null {
        return cups.find((cup: HipCupRepresentation): boolean => {
            return getRequiredUri(cup, LinkRelation.component) === cupComponentUri;
        }) ?? null;
    }


    /**
     * Get the component for a case component (project).
     */
    public static getCatalogComponent<T extends HipCatalogComponentRepresentation>(
        caseComponent: CaseComponentRepresentation,
        componentsCatalog: HipComponentsCatalogRepresentation,
        collectionName: HipComponentsCatalogPropCollection,
        options?: CacheOptions): T | null {
        const catalogComponentUri = getRequiredUri(caseComponent, LinkRelation.component);
        const catalogComponent = HipComponentsCatalogResource.getComponentInCollectionByUri<T>(
            componentsCatalog, collectionName, catalogComponentUri);

        if (catalogComponent) {
            ResourceUtil.setResource(caseComponent, LinkRelation.component, catalogComponent, options);
            return catalogComponent;
        }

        log.error('Could not load component: %s from case component', catalogComponentUri);
        return null;
    }

    public static loadComponentOnComponentBucket<T extends CatalogComponentRepresentation>(
        bucket: CaseComponentRepresentation,
        componentsCatalog: HipComponentsCatalogRepresentation,
        collectionName: HipComponentsCatalogPropCollection,
        options?: CacheOptions): T {
        if (bucket.component) {
            return bucket.component as T;
        }

        // get the component item representation
        const component = HipSurgicalTemplateComponentResource
            .getCatalogComponent<T>(bucket, componentsCatalog, collectionName, options);

        if (component) {
            return component;
        }

        throw new Error('Error loading component for bucket component');
    }
}
