import {
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import { StemComponentsRepresentation, } from '@/hipPlanner/components/state/types';
import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import { getRequiredSelfUri } from '@/lib/api/SemanticNetworkUtils';
import { ComponentSelectorRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { includes } from 'ramda';
import anylogger from 'anylogger';

export const TAPERLOC_COMPLETE_SYSTEM = 'taperloc-complete';

const log = anylogger('RefreshAction');

function _getStemsByStemSelector(
    catalog: HipComponentsCatalogRepresentation,
    stemSelectors: ComponentSelectorRepresentation[]) {
    const isSelectorMatch = (
        stem: HipStemCatalogComponentRepresentation,
        selector: ComponentSelectorRepresentation): boolean => {
        return (selector.system === undefined || selector.system == TAPERLOC_COMPLETE_SYSTEM) &&
            (selector.type === undefined || selector.type === stem.type) &&
            (selector.size === undefined || selector.size == stem.size);
    };

    return catalog
        .stems
        .filter((stem: HipStemCatalogComponentRepresentation) => {
            return stemSelectors.some((selector: ComponentSelectorRepresentation) => isSelectorMatch(stem, selector));
        });
}

export function filterStems(
    stemToFilter: StemComponentsRepresentation[],
    catalog: HipComponentsCatalogRepresentation,
    stemSelectors: ComponentSelectorRepresentation[]): StemComponentsRepresentation[] {

    if (!stemSelectors || stemSelectors.length <= 0) {
        return stemToFilter;
    }

    const catalogueStemIds = _getStemsByStemSelector(catalog, stemSelectors).map(getRequiredSelfUri);

    return stemToFilter.filter((stem: HipStemRepresentation) => {
        if (!stem.component) {
            return false;
        }

        const stemComponentUri = getRequiredSelfUri(stem.component);
        return includes(stemComponentUri, catalogueStemIds);
    });
}
