import { defineStore } from 'pinia';
import anylogger from 'anylogger';
import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { SurgicalTemplateUtil } from '@/lib/api/resource/case/surgical-template/SurgicalTemplateUtil';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { HipSurgicalSpecificationRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { isEmpty } from 'ramda';
import assert from 'assert';
import Bugsnag from '@bugsnag/js';
import { Uri } from 'semantic-link';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import { HipCaseState, LoadingState } from '@/hipPlanner/stores/case/HipCaseState';
import {
    loadCase,
    loadParentOnSurgicalTemplate,
    loadStudyOnSurgicalTemplate,
    loadSurgicalSpecificationOnSurgicalTemplate,
    loadSurgicalTemplate,
    PlannerLoadingContext,
    reloadCurrentPlanOnSurgicalTemplate,
    validateCaseProduct,
} from '@/hipPlanner/stores/case/loading';
import ApiResource from '@/lib/api/resource/ApiResource';
import ComponentsCatalogResource from '@/lib/api/resource/components/global-catalog/ComponentsCatalogResource';
import { Product } from '@/lib/api/representation/ProductRepresentation';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import { BodySide } from '@/lib/api/representation/interfaces';
import { calculateSceneOrigin } from '@/hipPlanner/stores/case/sceneOrigin';
import { formatVector } from '@/lib/base/formatMath';
import { isErrorCausedBy } from '@/hipPlanner/views/hipPlannerServices';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import SurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/SurgicalTemplateResource';

const log = anylogger('hip-case');

const HipCaseStoreCancelReason = 'hip case store cancelled';

export const useHipCaseStore = defineStore('hipCase', {
    state: (): HipCaseState => ({
        loadingState: LoadingState.Init,
        errorMessages: [],
        caseResource: null,
        componentsCatalog: null,
        sceneOrigin: null,
        cancellation: new AbortController(),
    }),
    getters: {
        project(): HipCaseRepresentation | null {
            return this.caseResource as HipCaseRepresentation ?? null;
        },
        surgicalTemplate(): HipSurgicalTemplateRepresentation | null {
            return this.caseResource?.surgicalTemplate as HipSurgicalTemplateRepresentation || null;
        },
        automatedTemplate(): HipSurgicalTemplateRepresentation | null {
            return this.surgicalTemplate?.parent as HipSurgicalTemplateRepresentation ?? null;
        },
        /**
         * The current manual plan.
         * Before a user approves a plan this value will be null.
         * If a user has approved a plan, it will be populated and available.
         */
        manualPlan(): PlanRepresentation | null {
            return this.caseResource?.surgicalTemplate?.currentPlan as PlanRepresentation ?? null;
        },
        /**
         * If the user surgical template if being created. This will be true only the first time the user
         * enters the 3D page after a new acid-surgical-template was processed.
         */
        isUserSurgicalTemplateBeingCreated(): boolean {
            const surgicalTemplate = this.surgicalTemplate;
            if (surgicalTemplate) {
                return SurgicalTemplateUtil.isNew(surgicalTemplate) ||
                    SurgicalTemplateUtil.isProcessing(surgicalTemplate);
            }

            return false;
        },
        study(): StudyRepresentation | null {
            return this.surgicalTemplate?.study || null;
        },
        studyMeasurements(): MeasurementsRepresentation | null {
            return this.study?.measurements || null;
        },
        surgicalSpecification(): HipSurgicalSpecificationRepresentation | null {
            return this.surgicalTemplate?.surgicalSpecification || null;
        },
        hasErrors(): boolean {
            return !isEmpty(this.errorMessages);
        },
        caseResourceSelfUri(): Uri | null {
            return this.caseResource ? getRequiredUri(this.caseResource, LinkRelation.self) : null;
        },
        context(): PlannerLoadingContext {
            return {
                api: this.$api,
                apiOptions: { ...this.$apiOptions, signal: this.cancellation.signal },
                product: Product.Hip,
                addErrorMessage: (message) => (this.errorMessages = [...this.errorMessages, message]),
            };
        },
        isReady(): boolean {
            const predicates = [
                this.loadingState === LoadingState.Completed,
                !this.hasErrors,
                this.caseResource,
                this.study,
                this.studyMeasurements,
                this.surgicalTemplate,
                this.surgicalSpecification,
                !!this.sceneOrigin,
            ];

            return predicates.every(p => !!p);
        },
        operativeSide(): BodySide {
            if (this.caseResource?.side) {
                return this.caseResource.side;
            } else {
                throw Error('Operative side is not loaded');
            }
        },
    },
    actions: {
        cancel(): void {
            this.cancellation.abort({ name: HipCaseStoreCancelReason });
        },
        async loadCase(apiUri: string): Promise<void> {
            log.info(`Loading case: ${apiUri}`);
            try {
                this.loadingState = LoadingState.LoadingResources;

                await this.loadNeededResources(apiUri);

                this.loadingState = LoadingState.LoadingSceneOrigin;

                await this.loadSceneOrigin();

                this.loadingState = LoadingState.Completed;
            } catch (e: unknown) {
                if (isErrorCausedBy(e, HipCaseStoreCancelReason)) {
                    log.info(
                        'Case store loading cancelled while in state: \'%s\' - (%s)',
                        this.loadingState,
                        apiUri);
                    this.loadingState = LoadingState.Cancelled;
                    // nothing do to
                } else {
                    this.loadingState = LoadingState.Error;

                    assert.ok(e instanceof Error);
                    const message =
                        `Case store loading: Something unexpected happened while in state ` +
                        `'${this.loadingState}' - (${apiUri}). Stack: ${e}'`;
                    log.error(message);
                    this.addErrorMessage(message);
                    Bugsnag.notify(e);
                }
            }
        },

        async loadNeededResources(apiUri: Uri): Promise<void> {
            await ApiResource.getApi(this.context.api, this.context.apiOptions);

            log.info(`Loading case resources...`);
            const caseResource = await loadCase<HipCaseRepresentation>(this.context, apiUri);

            // Mutating the state here to reproduce original behavior, but I'm not sure if it's meaningful or not.
            this.caseResource = caseResource;

            if (caseResource && validateCaseProduct(this.context, caseResource)) {
                log.info('Loading components catalog...');
                this.componentsCatalog = await ComponentsCatalogResource.getComponentsCatalog(
                    this.context.api, this.context.apiOptions);

                log.info('Loading surgical template...');
                const surgicalTemplate = await loadSurgicalTemplate(
                    this.context, caseResource);

                if (surgicalTemplate) {
                    log.info('Loading automated surgical template...');
                    await loadParentOnSurgicalTemplate(this.context, surgicalTemplate);

                    log.info('Loading study...');
                    await loadStudyOnSurgicalTemplate(this.context, caseResource, surgicalTemplate);

                    log.info('Loading surgical specification...');
                    await loadSurgicalSpecificationOnSurgicalTemplate(this.context, surgicalTemplate);

                    log.info('Loading manual plan if any...');
                    await reloadCurrentPlanOnSurgicalTemplate(this.context, caseResource, surgicalTemplate);

                    log.info(`Resources loaded for case ${apiUri}`);
                }
            }
        },

        async loadSceneOrigin(): Promise<void> {
            if (!this.study) {
                assert.ok(!!this.study, 'study is not defined');
            }

            this.sceneOrigin = await calculateSceneOrigin(this.study, this.context);

            log.info('Calculated scene-origin at %s', formatVector(this.sceneOrigin));
        },

        /** Logs and error message and add it to the list of messages */
        addErrorMessage(message: string): void {
            this.errorMessages = [...this.errorMessages, message];
        },

        /**
         * Fetch the user surgical template (and the manual user plan if any)
         */
        async syncManualTemplate(): Promise<HipSurgicalTemplateRepresentation | null> {
            try {
                const project = this.project;
                assert.ok(project, 'project must be store');

                const surgicalTemplate = await SurgicalTemplateResource
                    .getCaseUserSurgicalTemplate<HipSurgicalTemplateRepresentation>(
                        project, { ...this.context.apiOptions, forceLoad: true });

                if (surgicalTemplate) {
                    await reloadCurrentPlanOnSurgicalTemplate(this.context, project, surgicalTemplate);
                }

                return surgicalTemplate ?? null;
            } catch (e: unknown) {
                if (isErrorCausedBy(e, HipCaseStoreCancelReason)) {
                    log.info(
                        'Case store syncManualTempalte cancelled while in state: \'%s\'', this.loadingState
                    );
                    return null;
                } else {
                    throw e;
                }
            }
        },
    },
});

export type HipCaseStore = ReturnType<typeof useHipCaseStore> & HipCaseState;
