
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import { IsLoading } from '@/lib/LoadingDecorator';
    import PlanDownloadReportButton from '@/components/case-plan/PlanDownloadReportButton.vue';
    import AnalyticsUtil from '@/lib/analytics/AnalyticsUtil';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';

    @Component({ components: { PlanDownloadReportButton } })
    export default class PlanDownloadReportAutomatedButton extends Vue {
        @InjectReactive() planStore!: PlanReportStore;

        /** whether the report download is loading data */
        protected isDownloading = false;

        /**
         * Turn a button click handler into a downlink link ('A' element) to allow the
         * user to download a file.
         *
         * @see {@link https://stackoverflow.com/a/53775165}
         * {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a}
         */
        @IsLoading('isDownloading')
        protected async onDownloadReport(): Promise<void> {
            await this.planStore.downloadReport();

            AnalyticsUtil.event(this.$gtag, AnalyticsUtil.ScreenName.Plan, AnalyticsUtil.Event.PlanDownloadAutomatedIntent);
        }
    }
