import { ComponentSelectorRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { CatalogComponentRepresentation } from '@/lib/api/representation/catalog/CatalogComponentRepresentation';
import { head, last, split } from 'ramda';

export default class ComponentSelectorRepresentationUtil {
    /**
     * Perform a very rough check to see that a selector looks valid. That is it has
     * some from of selections.
     */
    public static isValid(value?: ComponentSelectorRepresentation[]): boolean {
        return !!value && value.length > 0 && value.some(item => item.system || item.type || item.size);
    }

    public static equals(value?: ComponentSelectorRepresentation[]): boolean {
        return !!value && value.length > 0 && value.some(item => item.system || item.type || item.size);
    }

    /**
     * Map a catalog component to a ComponentSelectorRepresentation
     */
    public static fromCatalogComponent(catalogComponent: CatalogComponentRepresentation): ComponentSelectorRepresentation {
        return {
            system: catalogComponent.system,
            type: catalogComponent.type,
            size: catalogComponent.size,
        };
    }

    /**
     * Selector that can be used to find all sizes matching a 'system' and 'type'
     */
    public static allSizesMatchingSelector(catalogComponent: CatalogComponentRepresentation): ComponentSelectorRepresentation {
        return {
            system: catalogComponent.system,
            type: catalogComponent.type,
            size: undefined,
        };
    }

    /**
     * Selector that can be used to find all types matching a 'system' and 'size'
     */
    public static allTypesMatchingSelector(catalogComponent: CatalogComponentRepresentation): ComponentSelectorRepresentation {
        return {
            system: catalogComponent.system,
            type: undefined,
            size: catalogComponent.size,
        };
    }

    // The 'shape' is the first part of the type
    // * E.g: Reduced Stem[High Offset] -> shape: Reduced Stem
    public static getStemShapeFromStemType(type = ''): string {
        return head(split('[', type)) || '';
    }

    // The 'offset' is the second part of the type
    // * E.g: Reduced Stem[High Offset] -> shape: High Offset
    public static getStemOffsetFromStemType(type = ''): string {
        return last(split('[', type))?.replace(']', '') || '';
    }
}
