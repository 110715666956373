import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import {
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import HipSurgicalTemplateComponentResource
    from '@/lib/api/resource/case/surgical-template/components/HipSurgicalTemplateComponentResource';
import { HipComponentsRepresentation } from '@/lib/api/representation/case/hip/HipComponentsRepresentation';
import assert from 'assert';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import {
    CupComponentsRepresentation,
    isCupComponentsRepresentation,
    isStemComponentsRepresentation,
    StemComponentsRepresentation,
} from '@/hipPlanner/components/state/types';
import {
    HipCupCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    HipLinerCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';
import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import HipSurgicalTemplateResource from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateResource';
import { RigidTransformRepresentation } from '@/lib/api/representation/geometry/transforms';
import { ComponentSelectorRepresentation } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import anylogger from 'anylogger';
import { AxiosInstance } from 'axios';
import ResourceUtil from '@/lib/api/ResourceUtil';
import { filterStems } from './filterStems';

const log = anylogger('RefreshAction');

export default class HipPlannerAPIService {
    constructor(
        private _http: AxiosInstance,
        private _template: HipSurgicalTemplateRepresentation,
        private _componentsCatalog: HipComponentsCatalogRepresentation,
        private _$apiOptions?: CacheOptions) {
    }

    public async getFittedComponentsAndSetOnTemplate(): Promise<HipComponentsRepresentation> {
        const components = await this.getFittedComponents(this._template);

        return ResourceUtil.setResource(this._template, 'components', components, this._$apiOptions);
    }

    public async getFittedComponents(template: HipSurgicalTemplateRepresentation): Promise<HipComponentsRepresentation> {
        const componentsAPIRawData = await HipSurgicalTemplateComponentResource.getFittedComponentsAPIData(
            this._http, template);

        assert.ok(componentsAPIRawData !== null, 'fitted components are not present');

        const configuredStems = componentsAPIRawData.stems.map(
            this.configureStemComponent.bind(this));
        const configuredCupComponents = componentsAPIRawData.cups.map(
            this.configureCupComponent.bind(this));

        return {
            ...componentsAPIRawData,
            stems: configuredStems,
            cups: configuredCupComponents,
        } as HipComponentsRepresentation;
    }

    /** Get an acetabular group from a cup */
    public configureCupComponent(cup: HipCupRepresentation): CupComponentsRepresentation {
        const cupComponent = HipSurgicalTemplateComponentResource
            .loadComponentOnComponentBucket<HipCupCatalogComponentRepresentation>(
                cup, this._componentsCatalog, LinkRelation.hipCupComponents, this._$apiOptions);

        if (cupComponent) {
            if (cup.liner) {
                const linerComponent = HipSurgicalTemplateComponentResource
                    .loadComponentOnComponentBucket<HipLinerCatalogComponentRepresentation>(
                        cup.liner,
                        this._componentsCatalog,
                        LinkRelation.hipCupLinerComponents,
                        this._$apiOptions);

                if (linerComponent) {
                    assert.ok(isCupComponentsRepresentation(cup), 'cup components undefined');
                    return cup;
                } else {
                    throw new Error('Component not set on liner');
                }
            } else {
                throw new Error('No liner found for cup');
            }
        } else {
            throw new Error('Component not set on cup');
        }
    }

    /** Get a femoral group from a stem */
    public configureStemComponent(stem: HipStemRepresentation): StemComponentsRepresentation {
        HipSurgicalTemplateComponentResource.loadComponentOnComponentBucket<HipStemCatalogComponentRepresentation>(
            stem, this._componentsCatalog, LinkRelation.hipStemComponents, this._$apiOptions);

        assert.ok(stem.head, 'Could not change stem. No head found for stem');

        HipSurgicalTemplateComponentResource.loadComponentOnComponentBucket<HipStemHeadCatalogComponentRepresentation>(
            stem.head, this._componentsCatalog, LinkRelation.hipStemHeadComponents, this._$apiOptions);

        assert.ok(stem.head, 'Could not change stem. Component not set on head');
        assert.ok(isStemComponentsRepresentation(stem), 'stem components undefined');

        return stem;
    }

    /**
     * Reset cup settings on surgical template
     * Update the surgical template with the ACID surgical template default values (cup link and rotation/offset values)
     */
    public getAutomatedTemplateCupComponents(
        automatedTemplate: HipSurgicalTemplateRepresentation): [
        HipSurgicalTemplateRepresentation,
        HipCupCatalogComponentRepresentation,
        HipLinerCatalogComponentRepresentation] {
        log.debug(
            'acid surgical template used for reset stem settings: %s',
            getRequiredUri(automatedTemplate, LinkRelation.canonicalOrSelf));

        const originalCup = HipSurgicalTemplateResource.getCurrentCup(
            automatedTemplate, this._componentsCatalog, this._$apiOptions);

        const originalLiner = HipSurgicalTemplateResource.getCurrentCupLiner(
            automatedTemplate, this._componentsCatalog, this._$apiOptions);

        assert.ok(originalCup, 'Could not reset cup. Could not load cup from acid surgical template');
        assert.ok(originalLiner, 'Could not reset cup. Could not load liner from acid surgical template');

        return [automatedTemplate, originalCup, originalLiner];
    }

    /**
     * Reset the stem and head (links and client side components artifact) on a surgical template
     * using the default values of the acid surgical template.
     */
    public getAutomatedTemplateStemComponents(automatedTemplate: HipSurgicalTemplateRepresentation): [
        HipStemCatalogComponentRepresentation, HipStemHeadCatalogComponentRepresentation, RigidTransformRepresentation | null] {
        log.debug(
            'acid surgical template used for reset stem settings: %s',
            getRequiredUri(automatedTemplate, LinkRelation.canonicalOrSelf));

        const originalStem = HipSurgicalTemplateResource.getCurrentStem(automatedTemplate, this._componentsCatalog, this._$apiOptions);
        const originalStemHead = HipSurgicalTemplateResource.getCurrentStemHead(automatedTemplate, this._componentsCatalog, this._$apiOptions);

        if (originalStem && originalStemHead) {
            return [originalStem, originalStemHead, automatedTemplate.stem_transform];
        } else {
            throw new Error('Could not reset stem. Could not load stem or stem head from Formus surgical template');
        }
    }

    public filterStems(
        components: HipComponentsRepresentation,
        stemSelectors: ComponentSelectorRepresentation[]): StemComponentsRepresentation[] {
        return filterStems(components.stems, this._componentsCatalog, stemSelectors);
    }
}
