import Bone3d from '@/hipPlanner/assembly/objects/Bone3d';
import GroupObject from '@/hipPlanner/assembly/objects/GroupObject';
import { Matrix4, Vector3 } from 'three';
import { asVector3 } from '@/lib/base/ThreeUtil';
import { HipRenderOrder } from '@/hipPlanner/assembly/objects/HipRenderOrder';
import { FemoralAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import { BodySide } from '@/lib/api/representation/interfaces';
import { RigidMatrix4 } from '@/lib/base/RigidTransform';
import StudyMeasurementsUtil from '@/lib/api/resource/case/study/StudyMeasurementsUtil';
import { normalize, orthogonalUnit } from '@/lib/geometry/vector3';
import anylogger from 'anylogger';
import AxisObject, { attachAxis } from '@/hipPlanner/assembly/objects/AxisObject';
import { NumberArray3 } from '@/lib/api/representation/geometry/arrays';
import { HipAssembly } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { formatMatrixBasis } from '@/lib/base/formatMath';

const log = anylogger('femoral-assembly');

export enum FemoralAssemblyAxis {
    FemurShaft = 'femur-shaft',
    FemurProximalShaft = 'femur-proximal-shaft',
    AnteversionCondylar = 'anteversion-condylar',
    AnteversionNeck = 'anteversion-neck',
}

/**
 * Create a {@link FemoralAssembly} with group at the given position aligned to a femoral
 * coordinate-system.
 */
export function makeFemoralAssembly(
    position: Vector3,
    femur: Bone3d,
    innerSurface: Bone3d,
    studyMeasurements: MeasurementsRepresentation,
    operativeSide: BodySide): FemoralAssembly {
    // Set the render-order for child objects
    femur.setRenderOrder(HipRenderOrder.OperativeFemur);
    innerSurface.setRenderOrder(HipRenderOrder.InnerCorticalSurface);

    // Calculate the femoral-group transform
    const { transform, aligned } = makeFemoralGroupTransform(studyMeasurements, position, operativeSide);

    log.info('Femur transform is:\n  %s', formatMatrixBasis(transform, { precision: 5, separator: '\n  ' }));

    // Create the femoral group and attach femur and inner-surface objects
    const femoralGroup = new GroupObject({
        name: HipAssembly.FemoralGroup,
        transform,
        debugColor: FEMORAL_GROUP_COLOR,
    });
    femoralGroup.attach(femur, innerSurface);

    const shaft = StudyMeasurementsUtil.getFemurShaftAxis(studyMeasurements, operativeSide);

    // If the femoral-group transform is 'aligned' we have access to the proximal-shaft and so can use it.
    // Otherwise, we have an old case and without the proximal-shaft so substitute the shaft.
    const proximalShaft = aligned ?
        StudyMeasurementsUtil.getFemurProximalShaftAxis(studyMeasurements, operativeSide) :
        shaft;

    const femoralAnteversion = StudyMeasurementsUtil.getFemurAnteversionAngle(studyMeasurements, operativeSide);

    return {
        femoralGroup,
        femur,
        innerSurface,
        nativeTransform: transform,
        isAligned: aligned,
        shaftAxis: tryAttachAxis(
            femoralGroup, FemoralAssemblyAxis.FemurShaft, '#eec55d', shaft?.position, shaft?.value),
        proximalShaftAxis: tryAttachAxis(
            femoralGroup, FemoralAssemblyAxis.FemurProximalShaft, '#ef4bc4', proximalShaft?.position, proximalShaft?.value),
        anteversionCondylarAxis: tryAttachAxis(
            femoralGroup, FemoralAssemblyAxis.AnteversionCondylar, '#bbe830',
            femoralAnteversion?.position,
            femoralAnteversion?.axes?.[0].value),
        anteversionNeckAxis: tryAttachAxis(
            femoralGroup, FemoralAssemblyAxis.AnteversionNeck, '#ab30e8',
            femoralAnteversion?.position,
            femoralAnteversion?.axes?.[1].value),
    };
}

const FEMORAL_GROUP_COLOR = '#0dec52'; // Green

/**
 * Create a transformation at the given position aligned to the femoral coordinate system:
 * - superior direction along the proximal shaft-axis
 * - left/right medial direction in the same plane as the neck-axis but orthogonal to the superior direction
 * - posterior direction orthogonal to the superior and medial directions
 *
 * If the data needed to determine coordinate-system is unavailable the resulting transformation will be at the
 * given position but no rotation, meaning it will be aligned to scene/CT axes.
 *
 * @returns an object containing the transformation and a boolean 'aligned' flag, which will be true if the
 * transformation is aligned to femoral coordinates.
 */
function makeFemoralGroupTransform(
    studyMeasurements: MeasurementsRepresentation,
    position: Vector3,
    operativeSide: BodySide): { transform: RigidMatrix4, aligned: boolean } {
    const shaftAxis = StudyMeasurementsUtil.getFemurProximalShaftAxis(studyMeasurements, operativeSide);
    const neckAxis = StudyMeasurementsUtil.getFemurNeckAxis(studyMeasurements, operativeSide);

    if (!shaftAxis || !neckAxis) {
        log.warn('Study is missing data needed for aligned femoral coordinate system (old case?)');

        // Create an identity transform at the expected origin
        return { transform: new Matrix4().setPosition(position), aligned: false };
    }

    const superior = normalize(asVector3(shaftAxis.value));
    const medial = normalize(asVector3(neckAxis.value).projectOnPlane(superior));

    // The medial direction points left in a right-side case, and we need to flip it otherwise
    const left = operativeSide === BodySide.Right ? medial : medial.negate();
    const posterior = orthogonalUnit(superior, left);

    // Create the transform using LPS conventions
    return {
        transform: new Matrix4().makeBasis(left, posterior, superior).setPosition(position),
        aligned: true,
    };
}

/**
 * Attach an axis to the femoral group object, throwing an exception if the origin or direction
 * are not defined.
 */
function tryAttachAxis(
    femoralGroup: GroupObject,
    name: string,
    color: string,
    origin?: NumberArray3,
    dir?: NumberArray3): AxisObject {
    if (origin && dir) {
        return attachAxis(femoralGroup, origin, dir, {
            name,
            color,
            visible: false,
        });
    } else {
        throw Error(`Not able to add axis '${name}' to femoral assembly: representation is missing data`);
    }
}
