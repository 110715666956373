import { Matrix4, Vector3 } from 'three';
import { MatrixArray16 } from '@/lib/api/representation/geometry/arrays';

/**
 * Return the position of the matrix.
 */
export function getPosition(matrix: Matrix4): Vector3 {
    return new Vector3().setFromMatrixPosition(matrix);
}

/**
 * Return the position of the matrix.
 *
 * By convention the API represents matrix in [Row-major order](https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order)
 * Three js represents matrix4 internally in column-major order, so it needs to be transposed before calling 'toArray()'
 *
 * @see https://threejs.org/docs/#api/en/math/Matrix4
 */
export function toMatrixRepresentation(matrix: Matrix4): MatrixArray16 {
    return matrix.clone().transpose().toArray();
}
