export enum HipRoles {
    Admin = 'admin',
    OrgAdmin = 'org_admin',
    Surgeon = 'surgeon',
    SurgeonAssist = 'surgeon_assist',
    Sales = 'sales',
}

export interface UserHipRolesState {
    isLoading: boolean
    myUserUri: string
    userRoles: HipRoles[]
}

export interface UserHipRole {
    id: number
    title: HipRoles
}
