import anylogger from 'anylogger';
import { CacheOptions, update } from '@/lib/semanticNetworkMigrationUtils';
import assert from 'assert';
import { cloneDeep } from 'lodash';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import {
    anyCupAssemblyChange,
    anyStemAssemblyChange,
    hasTargetsChanged,
} from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    SynchronisationTransition,
    SyncState,
    TemplateSyncState,
    TemplateTasksState,
    TemplateUpdateTask,
} from '@/hipPlanner/stores/template/TemplateSyncState';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { HipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';
import { cloneSurgicalTemplate } from '@/hipPlanner/stores/template/customDeepClone';

const log = anylogger('SurgicalTemplateSynchronizer');

/** States where the latest task in the queue has to be to consider the synchronisation is dirty */
const UPDATE_TASK_DIRTY_STATES = [
    TemplateTasksState.New,
    TemplateTasksState.Scheduled,
    TemplateTasksState.InProgress,
];

/** The maximum number of transitions to keep record of */
const TRANSITIONS_MAX_LENGTH = 10;

/** Arbitrary time to delay the execution of the fetch */
const FETCH_TIMEOUT = 5000;

/** Arbitrary time to transition to the {@link SyncState.Updating} */
const UPDATE_TIMEOUT = 3000;

type StateTransitionMap = { [K in SyncState]: SyncState[] }

/** The states that can be transitioned from any other state */
const FROM_ANYWHERE_STATES = [
    SyncState.Stopped,
    SyncState.Error,
];

type OnFetchCallback = (template: HipSurgicalTemplateRepresentation) => void;
type OnTransitionCallback = (transitions: SynchronisationTransition[]) => void;

export class TransitionError extends Error {
    constructor(from: SyncState, to: SyncState, current: SyncState) {
        super(`cannot transition from '${from}' to '${to}'. Current state is '${current}'`);

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, TransitionError.prototype);
    }
}

export default class SurgicalTemplateSynchroniser {
    /**
     * An object to defined which transitions are available and which one not
     */
    private _stateTransitions: StateTransitionMap = {
        [SyncState.Idle]: [
            SyncState.Paused,
            SyncState.FetchScheduled,
            SyncState.UpdateScheduled,

            ...FROM_ANYWHERE_STATES,
        ],
        //
        // Fetch states
        //
        [SyncState.FetchScheduled]: [
            SyncState.UpdateScheduled,
            SyncState.Fetching,

            ...FROM_ANYWHERE_STATES,
        ],
        [SyncState.Fetching]: [
            SyncState.Idle,

            ...FROM_ANYWHERE_STATES,
        ],
        //
        // Update states
        //
        [SyncState.UpdateScheduled]: [
            SyncState.Idle, // back to the beginning
            SyncState.UpdateScheduled, // re-entrant state
            SyncState.Updating,

            ...FROM_ANYWHERE_STATES,
        ],
        [SyncState.Updating]: [
            SyncState.Fetching,

            ...FROM_ANYWHERE_STATES,
        ],
        //
        //
        //
        [SyncState.Stopped]: [/* cannot go anywhere */],
        [SyncState.Paused]: [
            SyncState.Idle,

            ...FROM_ANYWHERE_STATES,
        ],
        [SyncState.Error]: [
            ...FROM_ANYWHERE_STATES,
        ],
    };

    /** Optional callback to execute at the end of the fetching state */
    private _onFetchCallback?: OnFetchCallback;

    /** Optional callback to execute when transitioning from one state to another */
    private _onTransitionCallback?: OnTransitionCallback;

    protected readonly _state: TemplateSyncState;

    protected readonly _template: HipSurgicalTemplateRepresentation;

    constructor(
        protected _caseStore: HipCaseStore,
        protected _templateStore: HipTemplateStore,
        private options: CacheOptions) {
        this._template = this._templateStore.userTemplate;
        this._state = _templateStore.sync;
    }

    public isState(state: SyncState): boolean {
        return this._state.currentState === state;
    }

    public get transitions(): SynchronisationTransition[] {
        return this._state.transitions;
    }

    public start(): this {
        if (this._state.isStarted) {
            throw new Error('Surgical template synchronizer is already started');
        }

        if (this.isState(SyncState.Idle)) {
            this._state.isStarted = true;
            this.idleState();
        } else {
            this._state.isStarted = false;
            throw new Error(`Cannot start the synchronizer. Current state: '${this._state.currentState}'`);
        }

        return this;
    }

    /**
     * Stop the service and mark it as not resumable.
     * Note: Following attempts to `start` will throw an exception.
     */
    public stop(): void {
        this.transition(this._state.currentState, SyncState.Stopped);
    }

    /**
     * Overrides the update document, so on the next time is idle, an update will be scheduled.
     * If the current state is dirty, the state will be re-entered, restarting the PUT execution.
     */
    public update(template: HipSurgicalTemplateRepresentation): void {
        if (this._state.isStarted) {
            const updateTask = this.addUpdateTask(template);
            log.debug('Update task added %o while in %s', updateTask, this._state.currentState);

            if (this.isState(SyncState.UpdateScheduled)) {
                // Accounts for previous PUT
                this.clearTimer();

                if (this.isDirty()) {
                    // Re-enter dirty state if there are changes
                    //
                    // This is logically the same as moving to the idle state, but without having the idle transition.
                    // So: instead of: dirty -> idle -> dirty transition, it is a dirty -> dirty.
                    this.transition(SyncState.UpdateScheduled, SyncState.UpdateScheduled, () => this.updateScheduledState());
                } else {
                    // This is the scenario where it was in dirty state, but the PUT did not happen yet,
                    // and new changes from user take it back to a no change state.
                    this.transition(SyncState.UpdateScheduled, SyncState.Idle, () => this.idleState());
                }
            } else if (this.isState(SyncState.FetchScheduled)) {
                if (this.isDirty()) {
                    // Accounts for scheduled GET
                    this.clearTimer();

                    // Transition to update workflow, abandoning fetch
                    this.transition(SyncState.FetchScheduled, SyncState.UpdateScheduled, () => this.updateScheduledState());
                }
            }
        } else {
            log.debug('skipped update given it is not started yet');
        }
    }

    /**
     * This method must be called from the Vue visibility directive.
     * The synchroniser will be paused if the value is false.
     * The synchroniser will be restarted if the value is true.
     */
    public onVisibilityChange(hidden: boolean): void {
        if (this._state.isStarted) {
            log.debug('onVisibilityChange called. Remember to pause: %s', hidden);
            this._state.hasToPause = hidden;

            // resume if it was already paused
            if (this.isState(SyncState.Paused)) {
                this.transition(SyncState.Paused, SyncState.Idle, () => this.idleState());
            }
        } else {
            log.debug('skipped onVisibilityChange given it is not started yet');
        }
    }

    /** public method to set a callback after the fetch is successful */
    public onFetch(cb: OnFetchCallback): this {
        this._onFetchCallback = cb;
        return this;
    }

    /** public method to set a callback after on each transition is successful */
    public onTransition(cb: OnTransitionCallback): this {
        this._onTransitionCallback = cb;
        return this;
    }

    /**
     * Transition from a state iff:
     * 1. fromState is the current state
     * 2. toState is in the allowed transitions from the state {@link this._stateTransitions}
     *
     * Exception to the rule:
     * ======================
     * - If the method is called while the synchroniser is in the {@link SyncState.Paused} state,
     * the transitions are skipped.
     * - If the method is called while the synchroniser is in the {@link SyncState.Stopped} state,
     * the transitions are skipped.
     */
    protected transition(fromState: SyncState, toState: SyncState, cb?: () => unknown): void {
        if (this.isState(SyncState.Stopped)) {
            //
            // Cannot go anywhere, as stopped is a final state
            //
            log.info('Skipping transition from: \'%s\' to \'%s\' as it stopped', fromState, toState);
        } else {
            if (fromState === this._state.currentState) {
                const allowedStates = this._stateTransitions[fromState];
                if (allowedStates && allowedStates.length > 0 && allowedStates.includes(toState)) {
                    this.addTransition(fromState, toState);
                    this._state.currentState = toState;

                    this.clearTimer();

                    if (cb) {
                        cb();
                    }
                } else {
                    throw new TransitionError(fromState, toState, this._state.currentState);
                }
            } else {
                throw new TransitionError(fromState, toState, this._state.currentState);
            }
        }
    }

    private idleState(): void {
        this.validateInState(SyncState.Idle);

        if (this._state.hasToPause) {
            this.transition(SyncState.Idle, SyncState.Paused, () => this.pauseState());
        } else {
            if (this.isDirty()) {
                this.transition(SyncState.Idle, SyncState.UpdateScheduled, () => this.updateScheduledState());
            } else {
                this.transition(SyncState.Idle, SyncState.FetchScheduled, () => this.fetchScheduledState());
            }
        }
    }

    private errorState(): void {
        this.validateInState(SyncState.Error);

        log.warn('error in surgical template synchronisation.');
        // TODO: Is it final? Retry?
    }

    private fetchScheduledState(): void {
        this.validateInState(SyncState.FetchScheduled);

        const fetch = async () => {
            if (fetchTimer === this._state.timer) {
                this.transition(SyncState.FetchScheduled, SyncState.Fetching, async () => await this.fetchingState());
            } else {
                // Accounts for a race-condition where the timer was fired,
                // but another change came into scene.
                log.warn('fetch race condition ???');
            }
        };

        // Disabling an inspection below that "local variable 'fetchTimer' is redundant"
        // fetchTimer is captured in the closure above, and so the variable is not redundant
        // See tests.misc.closures
        // noinspection UnnecessaryLocalVariableJS
        const fetchTimer = window.setTimeout(fetch, FETCH_TIMEOUT);
        this._state.timer = fetchTimer;
    }

    private async fetchingState(): Promise<void> {
        this.validateInState(SyncState.Fetching);

        const surgicalTemplate = await this._caseStore.syncManualTemplate();

        // Check still is in fetching state as a race condition could occurred:
        // e.g: The user navigates away and the synchroniser is stopped  while doing the get
        if (this.isState(SyncState.Fetching)) {
            if (surgicalTemplate) {
                if (this._onFetchCallback) {
                    this._onFetchCallback(this._template);
                }

                this.transition(SyncState.Fetching, SyncState.Idle, () => this.idleState());
                return;
            } else {
                log.error('template was not fetched successfully');
            }

            this.transition(SyncState.Fetching, SyncState.Error, () => this.errorState());
        } else {
            log.info('Skipping _onFetchCallback. Current state is: \'%s\'', this._state.currentState);
        }
    }

    private updateScheduledState(): void {
        this.validateInState(SyncState.UpdateScheduled);

        const updateTask = this.getLatestUpdateTask();
        assert.ok(updateTask, `update document cannot not be null while in dirty state`);
        updateTask.state = TemplateTasksState.Scheduled;

        const update = async () => {
            if (updateTimer === this._state.timer) {
                this.transition(
                    SyncState.UpdateScheduled, SyncState.Updating, async () => await this.updatingState(updateTask));
            } else {
                // Accounts for a race-condition where the timer was fired,
                // but another change came into scene.
                log.warn('update race condition ???');
            }
        };

        // Disabling an inspection below that "local variable 'updateTimer' is redundant"
        // updateTimer is captured in the closure above, and so the variable is not redundant
        // See tests.misc.closures
        // noinspection UnnecessaryLocalVariableJS
        const updateTimer = window.setTimeout(update, UPDATE_TIMEOUT);
        this._state.timer = updateTimer;
    }

    private async updatingState(updateTask: TemplateUpdateTask): Promise<void> {
        this.validateInState(SyncState.Updating);

        log.info('Updating template %o', updateTask.value);

        //
        // const updatedTemplate = await update<HipSurgicalTemplateRepresentation>(
        //     this.template, updateDocument, { ...this.options, ifMatch: true, ifUnmodifiedSince: true });

        updateTask.state = TemplateTasksState.InProgress;

        const updatedTemplate = await update<HipSurgicalTemplateRepresentation>(
            this._template, updateTask.value, this.options);

        if (updatedTemplate) {
            updateTask.state = TemplateTasksState.Completed;
            await this.transition(SyncState.Updating, SyncState.Fetching, () => this.fetchingState());
        } else {
            updateTask.state = TemplateTasksState.Error;

            //
            // TODO: This should be the case of a 412 (precondition failed), but at the moment semantic-network
            //  does not keep track of it.
            //
            await this.transition(SyncState.Updating, SyncState.Error, () => this.errorState());
        }
    }

    private pauseState(): void {
        this.validateInState(SyncState.Paused);

        this._state.hasToPause = false;

        log.debug(
            'Surgical template synchroniser paused for template: %s', LinkUtil.getUri(this._template, LinkRelation.self));
    }

    /**
     * @returns whether the latest update task not been completed
     * and has changes compared to the template in the network of data.
     */
    public isDirty(): boolean {
        const hasChanges = (
            template: HipSurgicalTemplateRepresentation,
            otherTemplate: HipSurgicalTemplateRepresentation): boolean => {
            const hasTargetChanges = hasTargetsChanged(template, otherTemplate);
            if (hasTargetChanges) {
                log.debug('targets changes detected');
            }

            const hasFemoralChanges = anyStemAssemblyChange(
                template, otherTemplate, this._templateStore.enableStemTransform);
            if (hasFemoralChanges) {
                log.debug('femoral changes detected');
            }

            const hasAcetabularChanges = anyCupAssemblyChange(template, otherTemplate);
            if (hasAcetabularChanges) {
                log.debug('acetabular changes detected');
            }

            return hasTargetChanges || hasFemoralChanges || hasAcetabularChanges;
        };

        const updateTask = this.getLatestUpdateTask();
        if (updateTask) {
            // only consider the synchroniser dirty if the latest task has not been completed yet
            if (UPDATE_TASK_DIRTY_STATES.includes(updateTask.state)) {
                return hasChanges(this._template, updateTask.value);
            } else {
                log.debug('latest task is already in state: %s', updateTask.state);
            }
        } else {
            log.debug('no update task detected');
        }

        return false;
    }

    private validateInState(state: SyncState): void {
        assert.ok(this._state.currentState === state, `expected to be in ${state}`);
    }

    protected clearTimer(): void {
        if (this._state.timer) {
            clearTimeout(this._state.timer);
            this._state.timer = null;
        }
    }

    /**
     * Prepend to the list of transitions, keeping it up to a maximum length of {@link TRANSITIONS_MAX_LENGTH}
     */
    private addTransition(fromState: SyncState, toState: SyncState): void {
        log.debug('synchroniser state transition from: \'%s\' to \'%s\'', fromState, toState);

        // prepend to list of transitions
        this._state.transitions.unshift({ from: fromState, to: toState });

        // remove the last transition
        this._state.transitions.splice(TRANSITIONS_MAX_LENGTH, 1);

        if (this._onTransitionCallback) {
            this._onTransitionCallback(cloneDeep(this._state.transitions));
        }
    }

    /**
     * Add an update task to the end of the queue of the list of tasks
     */
    private addUpdateTask(template: HipSurgicalTemplateRepresentation): TemplateUpdateTask {
        const updateTask = { value: cloneSurgicalTemplate(template), state: TemplateTasksState.New };
        this._state.updateTasks.push(updateTask);

        return updateTask;
    }

    /** Get the latest task if any */
    private getLatestUpdateTask(): TemplateUpdateTask | null {
        return this._state.updateTasks[this._state.updateTasks.length - 1] || null;
    }
}
