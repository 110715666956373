import _Vue from 'vue';
import { formatAngle } from '@/lib/filters/format/formatAngle';
import { formatLength } from '@/lib/filters/format/formatLength';
import { formatNumberSign } from '@/lib/filters/format/formatNumberSign';
import { formatNumber } from '@/lib/filters/format/formatNumber';
import { formatPercentageNumber } from '@/lib/filters/format/formatPercentageNumber';
import { formatLengthSign } from '@/lib/filters/format/formatLengthSign';
import { formatLongDateTime } from '@/lib/filters/format/formatDateTime';
import { formatDate } from '@/lib/filters/format/formatDate';

/**
 * Register the filters used in the Vue app
 */
export function FiltersPlugin(Vue: typeof _Vue): void {
    Vue.filter('formatAngle', formatAngle);
    Vue.filter('formatLength', formatLength);
    Vue.filter('formatLengthSign', formatLengthSign);
    Vue.filter('formatNumberSign', formatNumberSign);
    Vue.filter('formatNumber', formatNumber);
    Vue.filter('formatPercentageNumber', formatPercentageNumber);
    Vue.filter('formatDate', formatDate);
    Vue.filter('formatLongDateTime', formatLongDateTime);
}

export default _Vue.use(FiltersPlugin);
