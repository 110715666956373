import LinkRelation from '@/lib/api/LinkRelation';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import anylogger from 'anylogger';
import { LinkedRepresentation, LinkUtil, RelationshipType } from 'semantic-link';
import { all } from 'ramda';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import {
    HipCupCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';
import {
    HipLinerCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';

const log = anylogger('HipSurgicalTemplateForm');

type SurgicalTemplateValidation<T extends LinkedRepresentation> = (_resource: T) => boolean;

/**
 * A factory class that returns validations for the a surgical template.
 */
class SurgicalTemplateValidationFactory {
    public static mandatoryLink<T extends LinkedRepresentation>(rel: RelationshipType): SurgicalTemplateValidation<T> {
        return (resource: LinkedRepresentation): boolean => {
            if (LinkUtil.matches(resource, rel)) {
                return true;
            }
            log.error('Missing a %s link', rel);
            return false;
        };
    }
}

/** A factory class that returns custom validations for the hip surgical template */
export class HipSurgicalTemplateValidationUtil {
    public static cupMatchesLiner(
        cup: HipCupCatalogComponentRepresentation, liner: HipLinerCatalogComponentRepresentation): boolean {
        const cupLinerSize = cup ? cup.liner_size_ : undefined;
        const linerSize = liner?.size;
        if (!cupLinerSize || !linerSize) {
            log.info('Invalid surgical template. Could not validate cup liner size matches liner size');
            return false;
        } else if (cupLinerSize !== linerSize) {
            log.info(
                'Invalid surgical template. Cup liner size %s does not match liner size %s',
                cupLinerSize,
                linerSize);
            return false;
        }

        return true;
    }
}

abstract class SurgicalTemplateValidator<T extends SurgicalTemplateRepresentation> {
    abstract validate(template: T): boolean
}

export class HipSurgicalTemplateValidator implements SurgicalTemplateValidator<HipSurgicalTemplateRepresentation> {
    private validations = [
        SurgicalTemplateValidationFactory.mandatoryLink(LinkRelation.hipCurrentStemComponent),
        SurgicalTemplateValidationFactory.mandatoryLink(LinkRelation.hipCurrentStemHeadComponent),
        SurgicalTemplateValidationFactory.mandatoryLink(LinkRelation.hipCurrentCupComponent),
        SurgicalTemplateValidationFactory.mandatoryLink(LinkRelation.hipCurrentCupLinerComponent),
    ];

    public validate(template: HipSurgicalTemplateRepresentation): boolean {
        return all((validation) => validation(template), this.validations);
    }
}
