
    import { Component, Prop, Vue } from 'vue-property-decorator';

    import DragDropUtil from '@/lib/dragdrop/DragDropUtil';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import AppFormControl from 'sol-ui/src/components/basic/AppFormControl.vue';

    /**
     * This the upload 'panel' used to allow upload (via drag-n-drop) of DICOM files.
     */
    @Component({ components: { AppFormControl } })
    export default class ScanUploadDragHere extends Vue {
        @Prop({ required: true, validator: notNilValidator('isNew') })
        protected isNew!: boolean;

        /** Allows to customise the css by adding the 'over' to the target element */
        public onDragover(event: DragEvent): boolean {
            return DragDropUtil.dragover(event);
        }

        /** Allows to customise the css by adding the 'over' to the target element */
        public onDragenter(event: DragEvent): boolean {
            return DragDropUtil.dragenter(event);
        }

        /** Allows to customise the css by removing the 'over' to the target element */
        public onDragleave(event: DragEvent): void {
            DragDropUtil.dragleave(event);
        }
    }
