import { BufferGeometry, Matrix4, Mesh, Object3D, Vector3 } from 'three';
import { MatrixArray16 } from '@/lib/api/representation/geometry/arrays';

/**
 * Collection of Viewer utility methods
 */
export default class ViewerUtils {
    /**
     * Make a Matrix4 from an array of length 16.
     *
     * The array representing the matrix will usually be the transformation matrix that comes from master,
     * found in the case-data
     *
     * This is by convention in [Row-major order](https://en.wikipedia.org/wiki/Row-_and_column-major_order#Row-major_order)
     */
    public static makeMatrixFromArray(matrixArray: MatrixArray16): Matrix4 {
        const matrix4 = new Matrix4();

        matrix4.set(
            matrixArray[0], matrixArray[1], matrixArray[2], matrixArray[3],
            matrixArray[4], matrixArray[5], matrixArray[6], matrixArray[7],
            matrixArray[8], matrixArray[9], matrixArray[10], matrixArray[11],
            matrixArray[12], matrixArray[13], matrixArray[14], matrixArray[15]);

        return matrix4;
    }

    /**
     * Make an object buffered geometry from BufferGeometry
     */
    public static makeMeshBufferGeometry(geometryLoaded: BufferGeometry): BufferGeometry {
        this.computeGeometrySpacialAttr(geometryLoaded);

        return geometryLoaded;
    }

    /**
     * Compute geometry essential spacial attributes
     */
    private static computeGeometrySpacialAttr(geometry: BufferGeometry): void {
        geometry.computeVertexNormals();
        geometry.computeBoundingSphere();
        geometry.computeBoundingBox();
    }

    /**
     * Get center point between 2 positional vectors
     */
    public static getCenterPoint(min: Vector3, max: Vector3): Vector3 {
        const center = new Vector3();

        center.x = (max.x + min.x) / 2;
        center.y = (max.y + min.y) / 2;
        center.z = (max.z + min.z) / 2;

        return center;
    }

    /**
     *  Get normalised directional vector from two points
     */
    public static getDirectionVector(fromPoint: Vector3, toPoint: Vector3): Vector3 {
        return fromPoint.clone().sub(toPoint).normalize();
    }

    /**
     * Get spacial position along a directional vector
     */
    public static getPositionAlongDirection(
        initialPosition: Vector3,
        direction: Vector3,
        distance: number): Vector3 {
        const targetPosition = new Vector3();

        targetPosition.addVectors(initialPosition, direction.clone().multiplyScalar(distance));

        return targetPosition;
    }

    /**
     * Move an object along a directional vector
     */
    public static moveAlongVector(
        obj: Object3D,
        direction: Vector3,
        distance: number,
        initialPosition?: Vector3): void {
        const startPosition = (initialPosition !== undefined) ? initialPosition : obj.position.clone();
        const targetPosition = ViewerUtils.getPositionAlongDirection(startPosition, direction, distance);

        obj.position.copy(targetPosition.clone());
    }

    /**
     * Move an object from one point to another target point
     */
    public static moveToPoint(
        obj: Object3D,
        startPosition: Vector3,
        targetPosition: Vector3): void {
        const distance = startPosition.distanceTo(targetPosition);
        const direction = ViewerUtils.getDirectionVector(targetPosition, startPosition);

        ViewerUtils.moveAlongVector(obj, direction, distance, startPosition);
    }

    /**
     * Update the center (pivot point) of a mesh object
     * We want to move the center of the mesh, and leave the geometry in the same world position
     * To do that we do three things
     *
     *                                      Initial state At the beginning
     *              M -------------------   M:  is the frame (mesh object) where the Geometry (G) is contained
     *              |                    |  TP: is our targeted center point. The point where we want the geometry
     *              |      D ____        |      to rotate around. Our pivot point
     *              |  TP<--|-MC |       |  MC: is the mesh's current center
     *              |  |    |____|       |      (in this case we assume it matches the geometry center)
     *              |  |      G          |  G:  is the geometry
     *               --|-----------------   D:  is a vector representing the relative position of the MC from the TP"
     *                 |
     *                 |                    1)  We calculate the negated version of D to move the geometry
     *                 |                        in the opposite direction to D.
     *                 |                        This results D being the relative position of the geometry (G)
     *                 |                        from the original pivot point (mesh object centre)
     *              M--|-----------------
     *              |  |                 |
     *              |  |         -D____  |
     *              |  TP     MC--|->  | |
     *              |  |          |____| |
     *              |  |              G  |
     *               --|-----------------
     *                 |                     2) Once we set the TP as the center point, we obtain two things:
     *                 |                        - TP is now at the center of the mesh (frame)
     *                 |                        - Geometry is in the the same position as the beginning
     *        M -------|-----------
     *        |        |           |
     *        |        |    ____   |
     *        |        TP<-|--  |  |
     *        |            |____|  |
     *        |                    |
     *         --------------------
     */
    public static updateMeshObjectCenter(meshObject3d: Mesh, center: Vector3): void {
        const targetPoint = new Vector3();
        targetPoint.copy(center);

        // 1) Check explanation above
        const targetPointNegated = targetPoint.clone().negate();
        meshObject3d.geometry.translate(targetPointNegated.x, targetPointNegated.y, targetPointNegated.z);

        // 2) Check explanation above
        meshObject3d.position.set(targetPoint.x, targetPoint.y, targetPoint.z);

        // 3 is this needed ?
        meshObject3d.geometry.computeBoundingSphere();
    }

    /**
     * Get the normal vector of 3 positional vectors (eg. face points)
     */
    public static getNormalFromVectors(v1: Vector3, v2: Vector3, v3: Vector3): Vector3 {
        const faceCB = new Vector3();
        const faceAB = new Vector3();
        faceCB.subVectors(v3, v2);
        faceAB.subVectors(v1, v2);
        faceCB.cross(faceAB);

        faceCB.normalize();
        return faceCB;
    }
}
