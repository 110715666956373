import { LinkUtil, Uri } from 'semantic-link';
import { ArraySortValue, SortingUtil } from '@/lib/base/SortingUtil';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import { HipStemUtil } from '@/hipPlanner/components/form/panels/stem/HipStemUtil';
import { formatNumberSign } from '@/lib/filters/format/formatNumberSign';
import { StemComponentsRepresentation } from '@/hipPlanner/components/state/types';

import { cloneCatalogComponent } from '@/hipPlanner/stores/template/customDeepClone';

export enum StemOffsetNames {
    standard = 'Standard',
    high = 'High',
    xr = 'XR123'
}

// mapping of representation offset name to display name
const StemOffsetNamesMapping: { [id: string]: string } = {
    'Standard Offset': StemOffsetNames.standard,
    'High Offset': StemOffsetNames.high,
    XR: StemOffsetNames.xr,
};

export interface SelectStemOption {
    text: string;
    value: string | null;
    item: StemComponentsRepresentation;
}

export interface SelectHeadOption {
    text: string; // display name
    value: number; // offset value
    item: HipStemHeadCatalogComponentRepresentation;
}

/**
 * Group of functions related with making options that were dispersed on stemController,
 * and stem selection related components (dropdowns, offset selector, head offset selector, etc).
 * Temporary step to group functions here.
 */
export class StemOptionsFactory {
    /**
     * Get all the suitable stems
     *
     * Suitable stems have a score of 0 (zero)
     */
    public static getSuitableStems(stems: SelectStemOption[]): SelectStemOption[] {
        return stems.filter((i) => i.item && HipStemUtil.isSuitableStem(i.item));
    }

    /**
     * Get all the stems that are not suitable (have score greater than 0)
     */
    public static getNonSuitableStems(stems: SelectStemOption[]): SelectStemOption[] {
        return stems.filter((i) => i.item && HipStemUtil.isNotSuitableStem(i.item));
    }

    /**
     * Sort stems by their suitability score
     *
     * Stems with lower score will be sorted on top of the list
     */
    public static sortStemsBySuitabilityScore(stemA?: SelectStemOption, stemB?: SelectStemOption): ArraySortValue {
        const s1 = (stemA?.item) ? stemA.item.suitability_score : 0;
        const s2 = (stemB?.item) ? stemB.item.suitability_score : 0;

        return SortingUtil.sortNumericString(s1, s2);
    }

    /**
     * Soft stem sizes by numeric size
     */
    public static sortStemsOptionByText(stemA?: SelectStemOption, stemB?: SelectStemOption): number {
        const s1 = stemA?.text ? stemA.text : '';
        const s2 = stemB?.text ? stemB.text : '';

        return s1.localeCompare(s2);
    }

    /**
     * Soft stem sizes by numeric size
     */
    public static sortStemSizes(stemA?: SelectStemOption, stemB?: SelectStemOption): ArraySortValue {
        const s1 = (stemA?.item?.component) ? stemA.item.component.size : '';
        const s2 = (stemB?.item?.component) ? stemB.item.component.size : '';

        return SortingUtil.sortNumericString(s1, s2);
    }

    /**
     * Make a stem label/name using the stem type, size, and offset
     */
    public static makeStemLabel(stemComponent: HipStemCatalogComponentRepresentation): string {
        // Hack for compatibility with previous labels
        // E.g: "Reduced Stem[Standard Offset]" => Reduced Stem
        const withoutSubType = stemComponent.type.split('[')[0];

        return `${withoutSubType} ${stemComponent.size} ${stemComponent.offset_mode}`;
    }

    /**
     * Makes a select stem option from a case stem
     */
    public static makeCatalogComponentOption(stem: StemComponentsRepresentation): SelectStemOption {
        if (!stem.component) {
            throw new Error('stem should have component already loaded');
        }
        // Make the stem label/name
        const stemLabel = StemOptionsFactory.makeStemLabel(stem.component);
        const stemCatalogComponentUri = LinkUtil.getUri(stem.component, LinkRelation.self);
        return StemOptionsFactory.makeOption(stemLabel, stemCatalogComponentUri || null, stem);
    }

    private static makeOption(text: string, value: string | null, item: StemComponentsRepresentation): SelectStemOption {
        return { text, value, item };
    }

    /**
     * Produce a StemSelectionOption from the given stem representation for stem offset selection
     */
    private static makeStemOffsetOption(stem: StemComponentsRepresentation): SelectStemOption {
        const catalogComponent = stem.component;
        if (!catalogComponent) {
            throw new Error('stem should have component already loaded');
        }

        return StemOptionsFactory.makeOption(
            StemOffsetNamesMapping[catalogComponent.offset_mode],
            catalogComponent.offset_mode,
            stem,
        );
    }

    /**
     * Makes a select stem option from a case stem
     */
    public static makeSizeOption(stem: StemComponentsRepresentation): SelectStemOption {
        // Make the stem label/name
        const stemCatalogComponentUri = LinkUtil.getUri(stem.component, LinkRelation.self);

        return {
            text: stem.component.size,
            value: stemCatalogComponentUri || null,
            item: stem,
        };
    }

    static getOffsetOptions(stems: StemComponentsRepresentation[]): SelectStemOption[] {
        return stems.map((stem) => StemOptionsFactory.makeStemOffsetOption(stem));
    }

    /**
     * Given a heads collection, make a list of heads options which are just data (no State, semantic network information)
     */
    static makeHeadOptions(heads: HipStemHeadCatalogComponentRepresentation[]): SelectHeadOption[] {
        return heads.map((head) => StemOptionsFactory.getHeadOption(
            cloneCatalogComponent(head)));
    }

    /**
     * Produce a SelectHeadOption from the given head representation
     */
    private static getHeadOption(head: HipStemHeadCatalogComponentRepresentation): SelectHeadOption {
        return {
            text: StemOptionsFactory.formatHeadOffset(head),
            value: head.offset,
            item: head,
        };
    }

    public static formatHeadOffset(head: HipStemHeadCatalogComponentRepresentation): string {
        return formatNumberSign(head.offset, 0);
    }
}

export class SelectStemOptionUtil {
    public static includes(options: SelectStemOption[], uri: Uri): boolean {
        const uris = options.map((caseStem) => caseStem.value);
        return uris.includes(uri);
    }
}
