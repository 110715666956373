import assert from 'assert';
import { AxiosInstance } from 'axios';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { makeMatrix, RigidTransform } from '@/lib/base/RigidTransform';
import { watch, WatchStopHandle } from 'vue';
import {
    doInNativePosition,
    loadAndReplaceStemAndHead,
    setStemTransform,
} from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import anylogger from 'anylogger';
import HipImplantsMaterialMode from '@/hipPlanner/assembly/controllers/HipImplantsMaterialMode';
import HipCrossSectionPlanes from '@/hipPlanner/assembly/controllers/cross-sections/HipCrossSectionPlanes';

const log = anylogger('StemController');

/**
 * Stem controller that creates/filters the selection options (recommended, type/offset, and stem size) lists
 * for the stem toolbar, and shows the selected stem and head models in the Viewer, based on the selected stem option
 */
export default class StemController {
    constructor(
        private templateStore: HipTemplateStore,
        private plannerStore: HipPlannerStore,
        private axios: AxiosInstance,
        private sceneAssembly: SceneAssembly,
        private assembly: HipPlannerAssembly,
        private implantsMaterial: HipImplantsMaterialMode,
        private crossSectionPlanes: HipCrossSectionPlanes) {
        this.stopHandles = [
            watch(
                () => templateStore.stemTransform,
                (value: RigidTransform) => {
                    if (plannerStore.enableStemTransform) {
                        if (!assembly.isInNativePosition) {
                            // This warning tries to identify if this scenario occurs very often,
                            // The code below (`doInNativePosition`) will compensate for it,
                            // but ideally we would like to know why this happens.
                            log.warn('Attempt to set the stem transform when not in native position.');
                        }
                        // Only apply the transformation if we are in native arrangement
                        doInNativePosition(assembly, () => setStemTransform(assembly, value));
                    } else {
                        log.debug('MSP is disabled. Skipping setStemTransform() on stem transform change');
                    }
                }, { deep: true }),
        ];

        if (plannerStore.enableStemTransform) {
            // When the stem controller is initialised the stem transform from the server side is applied to the assembly
            doInNativePosition(assembly, () => setStemTransform(assembly, templateStore.stemTransform));
        } else {
            log.debug('MSP is disabled. Skipping setStemTransform() on stem controller initialization');
        }
    }

    private stopHandles: WatchStopHandle[];

    public off(): void {
        this.stopHandles.forEach(h => h());
        this.stopHandles = [];
    }

    public async updateSelectedStem(resetStemTransform = true): Promise<void> {
        const stemComponents = this.templateStore.stemComponents;
        const headOffset = this.templateStore.currentHeadOffset;

        assert.ok(!!stemComponents, 'stemComponents must be defined');
        assert.ok(headOffset !== null, 'currentHeadOffset cannot be null');

        await loadAndReplaceStemAndHead(
            this.assembly, this.axios, stemComponents, headOffset, resetStemTransform);

        // Note: This is to support adjustments on cases prior to MSP
        //
        // For simplicity, given all the data is available here,
        // this side effect to the store is done here instead of in AdjustmentCalculator,
        // which will require passing in the vue event payload the components and headOffset.
        this.plannerStore.calculateAdjustmentsStemOld(stemComponents, headOffset);

        this.plannerStore.calculateAdjustments(this.assembly);
        this.implantsMaterial.onAssemblyChange(this.assembly);
        this.crossSectionPlanes.onStemUpdated(this.assembly);
        this.crossSectionPlanes.onCupAssemblyChange(this.assembly);
    }

}
