import { defineStore } from 'pinia';
import { DateTime } from 'luxon';
import { productReleaseDate, productVersion } from '@/lib/version';
import { SHORT_DATE } from '@/lib/DateUtil';
import { apiBaseUrl } from '@/lib/url';

interface ApiResponse {
    'acid': string | null
    'acidDeployTime': string | null
}

export const VersionSuffix = 'CA';

export const useVersion = defineStore('version', {
    state: () => ({
        isLoading: true,
        acidDeployTime: DateTime.fromMillis(0),
        acidVersion: '',
    }),
    getters: {
        /**
         * There must be a single version number.
         *
         * Display all separately deployed component version numbers concatenated together.
         *
         * @example 1.3.2458-3893
         */
        version(): string {
            if (this.isLoading) return '';

            return [this.acidVersion, productVersion()]
                .filter(version => version.length > 0)
                .join('-') + '-' + VersionSuffix;
        },
        versionWithPrefix(): string {
            return `v${this.version}`;
        },

        /**
         * There must be a single release date.
         *
         * Display the latest release date across all components.
         */
        releaseDate(): string {
            if (this.isLoading) return '';

            const releaseDate = this.acidDeployTime > productReleaseDate() ?
                this.acidDeployTime :
                productReleaseDate();

            return releaseDate.toFormat(SHORT_DATE);
        },
    },
    actions: {
        async load() {
            this.isLoading = true;

            const { data } = await this.$http.get<ApiResponse>(`${apiBaseUrl()}/version?format=json`);

            this.acidDeployTime = DateTime.fromISO(data.acidDeployTime || '');
            this.acidVersion = data.acid || '';

            this.isLoading = false;
        },
    },
});

export type VersionStore = ReturnType<typeof useVersion>;
