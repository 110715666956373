
    import { Component, Prop, ProvideReactive, Vue } from 'vue-property-decorator';
    import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
    import { PlanType } from '@/lib/constants/PlanType';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import PlanItem from '@/components/case-plan/PlanItem.vue';
    import {
        HipSurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
    import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import plannerEventBus from '@/lib/planning/events/PlannerEventBus';
    import {
        HipSpecificationStore,
        useHipSpecificationStore,
    } from '@/hipPlanner/stores/specifications/hipSurgicalSpecificationStore';
    import { mapStores } from 'pinia';

    /**
     * This component is a v-card with the details of one of the plans within a case. Each
     * case may have zero or more of them items.
     *
     * If the plan has no report then it is assumed to be still being processed and will
     * show as being processed. The v-card will poll for updates so that the user doesn't
     * need to reload the web application.
     */
    @Component({
        components: { PlanItem },
        computed: {
            ...mapStores(
                useHipSpecificationStore,
            ),
        },
    })
    export default class PlanItemStateProvider extends Vue {
        @ProvideReactive()
        @Prop({ required: true, validator: notNilValidator('project') })
        private readonly project!: CaseRepresentation;

        /** * The plan that is being presented as a vcard */
        @Prop({ required: true, validator: notNilValidator('value') })
        private readonly value!: PlanRepresentation;

        @Prop({ required: true, validator: notNilValidator('surgicalTemplate') })
        @ProvideReactive('latestTemplate') surgicalTemplate!: HipSurgicalTemplateRepresentation;

        /**
         * Whether the plan is a acid/current/other plan.
         */
        @Prop({ required: true })
        private planType!: PlanType;

        @ProvideReactive()
        public planStore: PlanReportStore | null = null;

        declare protected hipSpecificationStore: HipSpecificationStore;

        /**
         * Indicates whether the data needed to display the plan card is being loaded.
         *
         * The plan card (including the QR code) displays/use as the plan, but most of the data comes from
         * different resources which are not the plan.
         * Once the plan card is intersected, it loads the following resources using the plan as the starting point.
         *
         * Resources loaded:
         * plan -> files
         *      -> surgical template -> project -> surgeon
         *                                      -> patient
         *                           -> surgical preferences ->  surgeon preferences
         *                           -> study -> measurements
         *                           -> measurements
         *                           -> stem
         *                           -> head
         *                           -> cup
         *                           -> liner
         *
         * Most of these resources/data will be available whether the plan is 'completed' or not.
         * The only data that will be available once the plan reaches the 'completed' state is:
         * - the 'files' collection: Will come to existence indicating that there models to be downloaded.
         * - the 'report' link: Indicates there is a report available.
         * This has an impact on whether to show the download PDF and models buttons.
         */

        protected created(): void {
            this.planStore = new PlanReportStore(
                this.project,
                this.value,
                this.planType,
                plannerEventBus,
                this.hipSpecificationStore,
                this.$http,
                this.$api,
                this.$apiOptions);

            // @ts-ignore this is for debug purposes xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
            // window.planStore = this.planStore;
        }

        /**
         * Once the plan card is intersected, it loads the main data to render the card.
         * All these data will be available whether or not the plan is 'completed' or not.
         * The only thing that will change and will be available once the plan reaches the 'completed' state are
         * the 'report' link and the 'files' collection. This has an impact on
         * whether to show the download buttons or not.
         */
        protected async onIntersect(
            entries: IntersectionObserverEntry[],
            observer: IntersectionObserver,
            isIntersecting: boolean): Promise<void> {
                if (isIntersecting) {
                    await this.planStore?.loadReportData();
                }
            }
    }
