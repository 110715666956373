import { HipStemRepresentation } from '@/lib/api/representation/case/hip/HipStemRepresentation';
import { isComponentFromCatalog } from '@/lib/api/representation/catalog/CatalogComponentRepresentation';
import { DeepNonNullable } from '@/lib/base/CustomTypes';
import {
    ComponentOffsetRepresentation,
} from '@/lib/api/representation/case/surgical-template/common/ComponentOffsetRepresentation';
import { HipHeadRepresentation } from '@/lib/api/representation/case/hip/HipHeadRepresentation';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import {
    HipLinerCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipLinerCatalogComponentRepresentation';
import { HipLinerRepresentation } from '@/lib/api/representation/case/hip/HipLinerRepresentation';
import { HipCupRepresentation } from '@/lib/api/representation/case/hip/HipCupRepresentation';
import {
    HipCupCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCupCatalogComponentRepresentation';

export type CupOffset = DeepNonNullable<ComponentOffsetRepresentation>;

export type CompleteHipHeadRepresentation = HipHeadRepresentation & {
    component: HipStemHeadCatalogComponentRepresentation;
}

export type StemComponentsRepresentation = HipStemRepresentation & {
    component: HipStemCatalogComponentRepresentation;
    head: CompleteHipHeadRepresentation;
}

export function isCompleteStemHeadRepresentation(head: HipHeadRepresentation): head is CompleteHipHeadRepresentation {
    return head !== undefined && isComponentFromCatalog(head.component);
}

export function isStemComponentsRepresentation(stem: HipStemRepresentation): stem is StemComponentsRepresentation {
    return stem?.component !== undefined &&
        isComponentFromCatalog(stem.component) &&
        stem?.head !== undefined &&
        isCompleteStemHeadRepresentation(stem.head);
}

export type CompleteHipLinerRepresentation = HipLinerRepresentation & {
    component: HipLinerCatalogComponentRepresentation;
}

export type CupComponentsRepresentation = HipCupRepresentation & {
    component: HipCupCatalogComponentRepresentation;
    liner: CompleteHipLinerRepresentation;
}

export function isCompleteLinerRepresentation(liner: HipLinerRepresentation): liner is CompleteHipLinerRepresentation {
    return liner !== undefined && isComponentFromCatalog(liner.component);
}

/** A guard clause to state that all the nada relates with the stem has been loaded */
export function isCupComponentsRepresentation(cup: HipCupRepresentation): cup is CupComponentsRepresentation {
    return cup?.component !== undefined &&
        isComponentFromCatalog(cup.component) &&
        cup?.liner !== undefined &&
        isCompleteLinerRepresentation(cup.liner);
}
