import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { MathUtils, OrthographicCamera, PerspectiveCamera, Vector3 } from 'three';
import { CameraAnimation } from '@/lib/planning/camera/CameraAnimation';
import CameraActions from '@/lib/planning/camera/CameraActions';
import anylogger from 'anylogger';
import { CameraAnimationState } from '@/lib/planning/camera/CameraAnimationState';

const log = anylogger('SmoothCameraAnimation');

export default class SmoothCameraAnimation implements CameraAnimation {
    private counter = 0;

    public state = CameraAnimationState.Created;

    constructor(
        /**
         *
         * @param camera
         * @param controls
         * @param initialPosition Starting position of the camera
         * @param targetPosition The new position of the camera
         * @param lookAtPoint Point where the camera will be looking at (rotated towards)
         * @param cameraUp Normalized "up" directional vector of the camera. Default is on the Y axis
         * @param animationCycleAmount The lower the animationCycleAmount value is, the faster the animation
         *                             Minimum value is 1 (10 millisecond animation).
         *                             Default value is 100 (smooth animation)
         * @param distance Distance between the camera and the this.lookAtPoint. OrthographicCamera uses this
         *                 to set its viewing frustum
         */
        private camera: PerspectiveCamera | OrthographicCamera,
        private controls: TrackballControls,
        private initialPosition: Vector3,
        private targetPosition: Vector3,
        private lookAtPoint: Vector3,
        private cameraUp: Vector3,
        private animationCycleAmount = 100,
        private distance?: number) {
    }

    /**
     * Animate the camera from one position to another and rotate it towards a specific point.
     *
     * Returns a Promise, which is resolved when the camera is on the end position
     */
    public async animate(): Promise<void> {
        const counterLoops = this.animationCycleAmount ? Math.floor(this.animationCycleAmount) : 100;
        // Get the current look at point fo the camera

        const lookAtStart = this.controls.target.clone();

        let smoothTime: number;

        return new Promise((resolve, reject) => {
            this.state = CameraAnimationState.InProgress;

            const intervalHandle = setInterval(
                () => {
                    try {
                        if (this.state === CameraAnimationState.InProgress) {
                            // Get the eased value of the time internal
                            smoothTime = CameraActions.easeClamp(this.counter / counterLoops, 0.0, 1.0);

                            // Set the transitional camera position, using the linear interpolation between (aka. lerp) the
                            // initial and target look at points.
                            // We do not use the built-in Vector3.lerp() method, because it does not produce the desired,
                            // smooth motion that we want. Instead, we can lerp the individual x,y,z coordinates to
                            // produce smoother motion.
                            this.camera.position.set(
                                MathUtils.lerp(this.initialPosition.x, this.targetPosition.x, smoothTime),
                                MathUtils.lerp(this.initialPosition.y, this.targetPosition.y, smoothTime),
                                MathUtils.lerp(this.initialPosition.z, this.targetPosition.z, smoothTime));

                            // Update the "up" directional vector of the camera, using the interpolation directional vector.
                            // We must update the "up" direction, because panning the camera depends on it. If it's not
                            // updated with correct direction, panning may cause/become zooming in/out.
                            //
                            // Updating the up vector will ensure that the camera roll is at the correct rotation.
                            // The up direction is also used by the .lookAt() method, which rolls and focuses the camera
                            // to a specified vector point.
                            //
                            // When we update the up vector, there is no need to update the camera quaternion.
                            this.camera.up.lerp(this.cameraUp, smoothTime);

                            // If we have an OrthographicCamera, update the camera frustum based on the distance.
                            // This is important because when using OrthographicCamera the objects' size are constant
                            // (do not change) no matter of the objects' distance from the camera.
                            //
                            if (this.distance && this.camera instanceof OrthographicCamera) {
                                CameraActions.updateCameraAspect(
                                    this.camera, this.distance / 2, this.distance / 2, this.distance);
                            }

                            const lookAt = new Vector3();

                            // Similar to getting the lerp position of the camera, we lerp the individual x,y,z coordinates
                            // to produce smoother motion.
                            lookAt.set(
                                MathUtils.lerp(lookAtStart.x, this.lookAtPoint.x, smoothTime),
                                MathUtils.lerp(lookAtStart.y, this.lookAtPoint.y, smoothTime),
                                MathUtils.lerp(lookAtStart.z, this.lookAtPoint.z, smoothTime));

                            // Update the camera "look at" point using the camera controls.
                            // We use the transitional look at point instead of the target look at point,
                            // in order to have a smooth camera rotation.
                            //
                            // We DO NOT update the camera look at point using the camera.lookAt(), because we control
                            // the camera through the camera controls. If we don't update the camera controls, they may
                            // become out-of-sync with camera and cause very sharp/sudden movement of the camera when the
                            // user starts manipulating the camera
                            // manually.
                            this.controls.target.copy(lookAt);
                            this.controls.update();

                            this.counter++;
                        } else {
                            log.debug('Camera animation not in progress. Last animation round.');
                        }

                        // End the animation when the camera position is at the target destination,
                        // or if the "smooth time" has reached 1.
                        //
                        // Check the camera position in case the camera is already at the desired position.
                        // However checking only for camera position may not be enough, because the user may move the
                        // camera during the animation, and this condition check may not be satisfied.
                        // To overcome this issue, we also check if the "smooth time" has reached 1, at which point
                        // we end the animation.
                        if (smoothTime === 1 || this.camera.position.equals(this.targetPosition)) {
                            this.state = CameraAnimationState.Completed;
                        }

                        if (this.state === CameraAnimationState.Cancelled ||
                            this.state === CameraAnimationState.Completed) {
                            clearInterval(intervalHandle);
                            resolve();
                        }
                    } catch (e) {
                        reject(new Error('Camera animation did not finish as expected'));
                    }
                },
                10);
        });
    }

    /**
     * Set the state as cancelled, and the animation will be cancelled on the next animation loop.
     */
    cancel(): void {
        this.state = CameraAnimationState.Cancelled;
    }
}
