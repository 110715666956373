import Implant3d from '@/hipPlanner/assembly/objects/Implant3d';
import { LinkUtil } from 'semantic-link';
import { AxiosInstance } from 'axios';
import { CaseComponentRepresentation } from '@/lib/api/representation/case/CaseComponentRepresentation';
import LinkRelation from '@/lib/api/LinkRelation';
import anylogger from 'anylogger';
import { HipImplantAlias, implantRenderOrder } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import HipSurgicalTemplateComponentResource
    from '@/lib/api/resource/case/surgical-template/components/HipSurgicalTemplateComponentResource';
import { assignMesh } from '@/hipPlanner/assembly/objects/Object3DFactory';
import { StemComponentsRepresentation } from '@/hipPlanner/components/state/types';
import { asVector3 } from '@/lib/base/ThreeUtil';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';

const log = anylogger('ViewerObjectsController');

/**
 * Create a three-js object representing an implant. The 'object matrix' of the
 * representation will be applied to the object so that it is transformed into its
 * templated position.
 */
export async function loadImplant(
    axios: AxiosInstance,
    representation: CaseComponentRepresentation,
    alias: HipImplantAlias): Promise<Implant3d> {
    const implant = new Implant3d(representation, {
        // The component self uri is assigned as a name
        name: LinkUtil.getUri(representation, LinkRelation.self) as string,
        alias,
    });

    // Check if the model is loaded for this item
    if (!representation.model) {
        // Load the buffered geometry into the "model" key of the item
        await HipSurgicalTemplateComponentResource.getComponentModelPLY<CaseComponentRepresentation>(
            axios, representation);
    }

    if (representation.model) {
        assignMesh(implant, representation.model);
        implant.setRenderOrder(implantRenderOrder(alias));
    } else {
        log.warn('Could not load model for implant');
    }

    // Apply the 'object matrix' (tmatrix on the api) this will place the the local-space of the original
    //  mesh to planning-space.
    implant.applyMatrix(implant.objectMatrix);

    return implant;
}

/**
 * Make a head Implant3d model that will be attached to the stem.
 * Note: The head will be in the native/patient coordinate system
 */
export async function loadHeadImplant(
    axios: AxiosInstance,
    representation: StemComponentsRepresentation,
    newOffset: number): Promise<Implant3d> {
    const headImplant = await loadImplant(axios, representation.head, HipImplantAlias.Head);

    const stemNeckAxis = asVector3(representation.neck_axis).negate();

    // get the difference in offset between the default head and the selected component head
    const headOffsetDifference = representation.head.component.offset - newOffset;

    log.debug(
        `Make head implant. Original fitted head offset was %s, and current offset is %s`,
        representation.head.component.offset,
        newOffset);

    // move the head to the desired position
    ViewerUtils.moveAlongVector(headImplant.theObject, stemNeckAxis, headOffsetDifference, headImplant.worldPosition);
    return headImplant;
}
