export enum CameraAnimationState {
    /**
     * The animation is created. animate() was not called yet.
     */
    Created = 'created',
    /**
     * The animation is in progress. This is the state straight after the animate() is called
     */
    InProgress = 'in-progress',
    /**
     * The animation was completed successfully.
     */
    Completed = 'completed',
    /**
     * The animation was cancelled.
     */
    Cancelled = 'cancelled',
}
