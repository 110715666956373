
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
    import { notNilValidator } from '@/lib/vue/prop-validators/notNilValidator';
    import { Fragment } from 'vue-fragment';
    import ScanUploadDragHere from '@/components/case-settings/scan/ScanUploadDragHere.vue';
    import AppFormControl from 'sol-ui/src/components/basic/AppFormControl.vue';
    import AppInputText from 'sol-ui/src/components/inputs/AppInputText.vue';

    /**
     * This the upload 'panel' used to allow upload (via drag-n-drop) of DICOM files.
     *
     */
    @Component({
        components: {
            Fragment,
            ScanUploadDragHere,
            AppFormControl,
            AppInputText,
        },
    })
    export default class StudyDetails extends Vue {
        /** The case (project) */
        @Prop({ required: true, validator: notNilValidator('value') })
        protected value!: StudyRepresentation;

        get fileCount(): string {
            return this.value.file_count ? String(this.value.file_count) : '';
        }
    }
