
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import { IsLoading } from '@/lib/LoadingDecorator';

    @Component({ components: {} })
    /** * A button to download the raw models of the plan */
    export default class PlanDownloadModelsButton extends Vue {
        @InjectReactive() planStore!: PlanReportStore;

        /** whether the raw models are being downloaded */
        protected isModelsFetching = false;

        @IsLoading('isModelsFetching')
        protected async modelButtonClicked(): Promise<void> {
            await this.planStore.downloadModels();
        }
    }
