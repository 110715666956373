
    import Login from '@/components/authentication/Login.vue';
    import InactivityObserver from '@/components/idle/InactivityObserver.vue';
    import { getUri } from 'semantic-link';
    import { uriMapping } from '@/router/UriMapping';
    import { Component, Vue } from 'vue-property-decorator';

    import anylogger from 'anylogger';
    import { RouteUtils } from './router/RouteUtils';
    import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
    import AppBar from '@/components/app-bar/AppBar.vue';
    import { TitleComponent } from '@/components/TitleUtil';
    import NavigationSidebar from '@/views/NavigationSidebar.vue';
    import AuthenticationProvider from '@/components/authentication/AuthenticationProvider.vue';
    import TrialBanner from '@/views/trialBanner/TrialBanner.vue';

    const log = anylogger('App');

    const settingsComponents = { AuthenticationProvider, AppBar, Login, NavigationSidebar, InactivityObserver, TrialBanner };

    @Component({ components: settingsComponents })
    export default class App extends Vue {
        public declare $refs: Vue['$refs'] & { main: HTMLElement };

        /**
         * The component type and the component property data for that component
         * as a single value.
         *
         * Note: This is a single value (with two concepts) so that the value can be set
         * in a single event by the child view component.
         */
        title: TitleComponent = {
            titleComponentType: '',
            notificationComponentType: '',
        };

        /** access to $api in a form that the vue plugin will find - DO NOT USE IN CODE */
        protected get api(): ApiRepresentation {
            return this.$api;
        }

        protected created(): void {
            const apiUri: string = getUri('HEAD', 'api') as string;
            const clientUri: string = window.location.href;

            log.info('api \'%s\', client uri \'%s\'', apiUri, clientUri);
            log.debug('VUE %o, axios %o, $api %o', this, this.$http, this.$api);

            if (!this.$htmlToPaper) {
                log.warn('$htmlToPaper didn\'t install');
            }

            uriMapping(clientUri, apiUri);
        }

        /**
         * This event is trigger by a child component in the main router-view slot
         * from the child's `created()` method when it emits a 'title' event.
         **/
        protected onUpdateTitle(event: TitleComponent): void {
            log.debug('Title set to %o', event.titleData);
            this.title = event;
        }

        /**
         * Don't allow a drop operation.
         *
         * Use in the form:
         * ```
         *     @dragover.prevent="onNoDrop"
         * ```
         * so that the default action (to allow a copy/move) is disabled.
         */
        protected onNoDrop(event: DragEvent): boolean {
            if (event.dataTransfer) {
                event.dataTransfer.dropEffect = 'none';
            } else {
                log.warn('Drag over event has no data transfer');
            }
            return false;
        }

        /**
         * A computed property to help determine which layout to use
         *
         * As the problem evolves in complexity, consider:
         * 1) Split code into components (preferred)
         * 2) Try named view strategy: https://router.vuejs.org/guide/essentials/named-views.html (experimental feature)
         */
        protected get isLayoutWithNavigationSidebar(): boolean {
            return [
                RouteUtils.isOnDashboard,
                RouteUtils.isOnCaseSettings,
                RouteUtils.isOnDicom,
                RouteUtils.isOnReport,
                RouteUtils.isOnAboutPage,
                RouteUtils.isOnPrivacyPage,
                RouteUtils.isOnTermsPage,
                RouteUtils.isOnPreferencesPage,
            ].some((fn) => fn(this.$route));
        }

        protected get requiresAuth(): boolean {
            return this.$route.meta?.requireAuth ?? false;
        }
    }
