import ResourceService from '@/lib/api/ResourceService';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { CollectionRepresentation, LinkSelector, LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';

import { CatalogComponentRepresentation } from '@/lib/api/representation/catalog/CatalogComponentRepresentation';
import ResourceUtil from '@/lib/api/ResourceUtil';
import { StudyRepresentation } from '@/lib/api/representation/case/study/StudyRepresentation';
import { ModelRepresentation } from '@/lib/api/representation/ModelRepresentation';
import _ from 'lodash';
import {
    SegmentedModelCollectionRepresentation,
} from '@/lib/api/representation/case/study/SegmentedModelRepresentation';

import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';
import { SurgicalSpecificationRepresentationType } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { getIfLink, getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import {
    HipComponentsCatalogPropCollection,
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import HipComponentsCatalogResource from '@/lib/api/resource/components/global-catalog/HipComponentsCatalogResource';
import { isHipSurgicalTemplate } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateUtil';
import { ComponentsCatalogRepresentation } from '@/lib/api/representation/catalog/ComponentsCatalogRepresentation';
import {
    HipSurgicalTemplateComponentRel,
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { RelationshipType } from 'semantic-link/lib/filter';

import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import {
    SurgicalTemplateCollectionRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateCollectionRepresentation';
import LinkRelationTitle from '@/lib/api/LinkRelationTitle';
import { ApiUtil, pooledSingletonMakeStrategy } from '@/lib/semantic-network';
import anylogger from 'anylogger';
import {
    FemoralSuitabilityRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/femoral/FemoralSuitabilityRepresentation';
import {
    FemoralRankingRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/femoral/FemoralRankingRepresentation';

const log = anylogger('SurgicalTemplateResource');

export default class SurgicalTemplateResource implements ResourceService {
    /** Gets a surgical template from the cache */
    public static async get<T extends SurgicalTemplateRepresentation>(
        template: T, options?: CacheOptions): Promise<T | null> {
        return await ApiUtil.get<T>(template, options) ?? null;
    }

    public static async getSurgicalSpecification<T extends SurgicalSpecificationRepresentationType>(
        surgicalTemplate: SurgicalTemplateRepresentation,
        options?: CacheOptions):
        Promise<T | null> {
        return getIfLink<SurgicalTemplateRepresentation, T>(
            surgicalTemplate, LinkRelation.surgicalSpecification, options);
    }

    /**
     * Get the measurements of a surgical template
     *
     * Note: At this point only 'hip' has some measurements being exposed.
     */
    public static async getMeasurements(
        surgicalTemplate: SurgicalTemplateRepresentation,
        options?: CacheOptions): Promise<MeasurementsRepresentation | null> {
        return getIfLink(surgicalTemplate, LinkRelation.measurements, options);
    }

    /**
     * Get the suitability of a surgical template
     *
     * Note: At this point only 'hip' has some measurements being exposed.
     */
    public static async getSuitability(
        surgicalTemplate: SurgicalTemplateRepresentation,
        options?: CacheOptions): Promise<FemoralSuitabilityRepresentation | null> {
        return getIfLink(surgicalTemplate, LinkRelation.femoralSuitability, options);
    }

    /**
     * Get the ranking of a surgical template
     *
     * Note: At this point only 'hip' has some measurements being exposed.
     */
    public static async getRanking(
        surgicalTemplate: SurgicalTemplateRepresentation,
        options?: CacheOptions): Promise<FemoralRankingRepresentation | null> {
        return getIfLink(surgicalTemplate, LinkRelation.femoralRanking, options);
    }

    /**
     * Get the history surgical template if there is a link
     */
    public static async getHistory<T extends SurgicalTemplateRepresentation>(
        surgicalTemplate: T,
        options?: CacheOptions): Promise<SurgicalTemplateCollectionRepresentation | null> {
        return await ApiUtil.get<SurgicalTemplateCollectionRepresentation>(
            surgicalTemplate, { rel: LinkRelation.history, ...options }) ?? null;
    }

    /**
     * Get the collection of all surgical templates for a project. This will include both 'user'
     * and 'acid' surgical specification.
     */
    public static async getCaseSurgicalTemplates<T extends SurgicalTemplateRepresentation>(
        caseResource: CaseRepresentation, options?: CacheOptions):
        Promise<CollectionRepresentation<T> | null> {
        return await ApiUtil.get<CollectionRepresentation<T>>(
            caseResource, { rel: LinkRelation.surgicalTemplates, ...options }) ?? null;
    }

    public static async getCaseUserSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<T | null> {
        return await SurgicalTemplateResource.getCaseSurgicalTemplate(
            caseResource, 'surgicalTemplate', LinkRelation.currentSurgicalTemplate, options);
    }

    public static async getCaseAutomatedSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
        caseResource: CaseRepresentation,
        options?: CacheOptions): Promise<T | null> {
        return await SurgicalTemplateResource.getCaseSurgicalTemplate(
            caseResource, 'acidSurgicalTemplate', LinkRelation.acidSurgicalTemplate, options);
    }

    /**
     * Load a surgical template using the case (project) surgical template collection as a cache.
     */
    private static async getCaseSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
        caseResource: CaseRepresentation,
        key: keyof CaseRepresentation,
        surgicalTemplateRelationship: RelationshipType,
        options?: CacheOptions): Promise<T | null> {
        if (LinkUtil.matches(caseResource, surgicalTemplateRelationship)) {
            const caseSurgicalTemplates = ResourceUtil.makePooledCollection(
                caseResource, { rel: LinkRelation.surgicalTemplates });
            return await ApiUtil.get<T>(
                caseResource, {
                    ...options,
                    rel: surgicalTemplateRelationship,
                    name: key,
                    makeSparseStrategy: (o) => {
                        return pooledSingletonMakeStrategy(caseSurgicalTemplates, o);
                    },
                }) ?? null;
        }
        return null; // there is no manual/automated (user/acid) surgical template
    }

    /**
     * For a 'user' surgical template, load the parent surgical template (which will be
     * an 'acid' surgical template). The parent surgical template provides default values
     * for the user.
     *
     * There is some implicit knowledge the the parent will be a acid template
     */
    public static async getAcidSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
        surgicalTemplate: T, options?: CacheOptions): Promise<T> | never {
        return await this.getParentSurgicalTemplate(surgicalTemplate, options);
    }

    /**
     * Surgical templates can be linked up in a parent/child relationship. When a 'user' surgical
     * template is created it will be created with a parent surgical template using the current
     * 'acid' surgical template. This allows the 'acid'  fitted components to be used. If a 'user'
     * surgical template is created without an acid surgical template the selection of components
     * becomes more difficult.
     *
     * This will load the rel===surgicalTemplate and title===parent
     */
    public static async getParentSurgicalTemplate<T extends SurgicalTemplateRepresentation>(
        surgicalTemplate: T, options?: CacheOptions): Promise<T> | never {
        const parentSurgicalTemplate = await ApiUtil.get<T>(
            surgicalTemplate, { rel: LinkRelation.parentSurgicalTemplate, ...options });
        if (parentSurgicalTemplate) {
            return parentSurgicalTemplate;
        }

        throw new Error('A surgical template should have a parent set');
    }

    /**
     * Update a surgical template resource. Merge the fields from the document into the
     * resource.
     */
    public static merge<T extends SurgicalTemplateRepresentation>(
        resource: T, updateDocument: Partial<T>, options?: CacheOptions): T | never {
        return ResourceUtil.merge<T>(resource, { ...updateDocument }, options);
    }

    /**
     * Get the study from a surgical template. This study resource should not change over time, and
     * MUST be present.
     *
     * The context where all the studies are loaded is the case. We use the 'study' link
     * in the surgical template to load the study from the list of studies.
     * The strategy is to load it from a virtual list of studies 'studies' collection
     * which exists in the case (project) (This should help to avoid round-trips to the server)
     */
    public static async getStudy(
        caseResource: CaseRepresentation,
        surgicalTemplate: SurgicalTemplateRepresentation,
        options?: CacheOptions):
        Promise<StudyRepresentation | null> {
        if (LinkUtil.matches(surgicalTemplate, LinkRelation.study)) {
            const caseStudies = ResourceUtil.makePooledCollection(caseResource, { rel: LinkRelation.studies });
            return await ApiUtil.get<StudyRepresentation>(
                surgicalTemplate, {
                    ...options,
                    rel: LinkRelation.study,
                    makeSparseStrategy: (o) => pooledSingletonMakeStrategy(caseStudies, o),
                }) ?? null;
        }
        return null; // the surgical template has no study (this should not be possible)
    }

    /** Get the 'models' collection of a surgical template */
    public static async getModels<T extends SurgicalTemplateRepresentation>(
        surgicalTemplate: T,
        options?: CacheOptions):
        Promise<SegmentedModelCollectionRepresentation | null> {
        const surgicalTemplateModels = await ApiUtil.get<SegmentedModelCollectionRepresentation>(
            surgicalTemplate, { rel: LinkRelation.models, includeItems: false, ...options });
        if (surgicalTemplateModels) {
            return surgicalTemplateModels;
        }

        throw new Error('A surgical template should be have a models rel');
    }

    /**
     * Get a model by title in the 'models' resource.
     *
     * WARNING: This collection is different from the models inside the study
     *
     * The 'surgical-template' resource, has a 'study' link which has some models (scapula, humerus)
     * and there is also another 'models' link in the 'surgical-template' that has other models (humerus-resection)
     * This method only get models that live in the 'models' resource
     */
    public static async getModelByTitle<T extends SurgicalTemplateRepresentation>(
        surgicalTemplate: T, title: string, options?: CacheOptions): Promise<ModelRepresentation | null> {
        const models = await SurgicalTemplateResource.getModels(surgicalTemplate, options);
        if (models) {
            // TODO Don't do client side filtering.
            // This is compensating the missing links relations on the surgical template
            // When present, instead consume a link relation
            // :rel===model && title==='type operative-humerus (resection-remaining')
            const model = _.find(models.items, (item: ModelRepresentation) => {
                return item.name === title;
            });
            return model || null;
        } else {
            return null;
        }
    }

    /**
     * Get a catalog component using linkSelector on a surgical template
     */
    protected static getComponentByLinkSelector<TR extends CatalogComponentRepresentation>(
        surgicalTemplate: HipSurgicalTemplateRepresentation,
        linkSelector: LinkSelector,
        componentsCatalog: ComponentsCatalogRepresentation,
        collectionName: HipComponentsCatalogPropCollection,
        options?: CacheOptions): TR | null {


        /**
         * TODO
         * This method is a bit generic, and could work also for recommended components
         * Decide if we want to restrict the type of linkedRel, or change the interface of this method
         */
        if (LinkUtil.matches(surgicalTemplate, linkSelector)) {
            const uri = getRequiredUri(surgicalTemplate, linkSelector);
            let component = null;
            if (isHipSurgicalTemplate(surgicalTemplate)) {
                component = HipComponentsCatalogResource.getComponentInCollectionByUri(
                    componentsCatalog as HipComponentsCatalogRepresentation,
                    collectionName as HipComponentsCatalogPropCollection,
                    uri
                );

                if (component) {
                    // Set the component in the surgical template
                    // Map the rel to the surgical template representation
                    // E.g => {rel: 'stem, title: 'current' } to 'currentStem
                    const keyInSurgicalTemplate = SurgicalTemplateResource
                        .mapLinkSelectorToHipSurgicalTemplateProperty(linkSelector);
                    ResourceUtil.setResource(surgicalTemplate, keyInSurgicalTemplate, component, options);

                    return component as TR;
                }
            }
        }

        return null;
    }

    public static mapLinkSelectorToHipSurgicalTemplateProperty(
        linkSelector: LinkSelector): HipSurgicalTemplateComponentRel | never {
        // Mapping current components
        if (linkSelector.title === LinkRelationTitle.currentComponent) {
            return linkSelector.rel as HipSurgicalTemplateComponentRel;
        }

        throw new Error(`rel: ${linkSelector.rel}, title: rel: ${linkSelector.title} 
            cannot be mapped to SurgicalTemplateResource`);
    }
}
