import {
    hipPlannerModeObjectSettingKey,
    hipPlannerModeObjectSettings,
    HipPlannerModeRepresentation,
} from '@/hipPlanner/assembly/controllers/AcidPlannerModeSettings';
import { ViewingMode } from '@/lib/viewer/ViewingMode';
import { moveToNativePosition, moveToRetractedPosition } from '@/hipPlanner/assembly/controllers/hipPlannerAssembly';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';

import anylogger from 'anylogger';
import { HipBoneAlias } from '@/lib/constants/Bones';
import HipViewerObjectUtil from '@/hipPlanner/assembly/objects/HipViewerObjectUtil';
import { HipImplantAlias } from '@/hipPlanner/assembly/objects/HipImplantAlias';
import { AcidMeshObject3d } from '@/hipPlanner/assembly/objects/AcidMeshObject3d';
import CameraMan, { HipCameraPresetEnum } from '@/lib/planning/camera/CameraMan';
import MaterialUtils from '@/lib/viewer/MaterialUtils';
import { SceneAssembly } from '@/lib/planning/viewer/SceneAssembly';
import { HipPlannerViewStore } from '@/hipPlanner/stores/plannerView/hipPlannerView';
import { watch, WatchStopHandle } from 'vue';

const log = anylogger('AcidPlannerModeController');

export enum PlannerMode {
    Stem = 'Stem',
    Cup = 'Cup',
    Combined = 'Combined',
    Closed = 'Closed',
}

/** Available planner modes */
export type plannerModeName = PlannerMode.Combined | PlannerMode.Stem | PlannerMode.Cup | PlannerMode.Closed;

export default class AcidPlannerModeController {
    private cameraController?: CameraMan;
    private sceneAssembly?: SceneAssembly;
    private isXrayMode = false;
    private assembly?: HipPlannerAssembly;
    private watchHandle: WatchStopHandle | null = null;

    constructor(private plannerViewStore: HipPlannerViewStore) {
        this.watchHandle = watch(
            () => plannerViewStore.pendingMode,
            (newMode: PlannerMode, oldMode: PlannerMode | undefined) => {
                if (newMode !== oldMode) {
                    this.setInPlannerMode(newMode);
                }
            },
        );
    }

    // TODO: BAD BAD BAD: need to get rid of this two stage construct and AcidPlannerModeController
    public init(assembly: SceneAssembly, cameraController: CameraMan): void {
        this.cameraController = cameraController;
        this.sceneAssembly = assembly;
        this.setInPlannerMode(this.plannerViewStore.plannerMode);
    }

    /**
     * Switch between normal and xray viewing mode
     */
    public changeXRayMode(viewMode: ViewingMode): void {
        if (this.sceneAssembly) {
            this.isXrayMode = (viewMode === ViewingMode.Xray);

            HipViewerObjectUtil.onEachMeshObject3D(
                this.sceneAssembly.hipObjects,
                (acidMeshObject3D) => {
                    acidMeshObject3D.changeViewMode(viewMode);
                });
        }
    }

    public off() {
        if (this.watchHandle)  {
            this.watchHandle();
            this.watchHandle = null;
        }
    }

    /**
     * Toggle visibility of acid objects matching the aliases;
     */
    public toggleVisibilityByAlias(visible: boolean, aliases: Array<HipBoneAlias | HipImplantAlias>): void {
        if (this.sceneAssembly) {
            HipViewerObjectUtil.onEachByAlias(
                this.sceneAssembly.hipObjects, aliases, (acidObject) => {
                    acidObject.theObject.visible = visible;
                });
        } else {
            log.debug('No planner mode set, due to controller not being initialised');
        }
    }

    /**
     * Get planner mode settings for the requested mode
     */
    public getPlannerModeSettings(modeName: plannerModeName): HipPlannerModeRepresentation {
        switch (modeName) {
            case PlannerMode.Stem:
                return hipPlannerModeObjectSettings.stem;
            case PlannerMode.Cup:
                return hipPlannerModeObjectSettings.cup;
            case PlannerMode.Combined:
            case PlannerMode.Closed:
                return hipPlannerModeObjectSettings.combined;
        }
    }

    public setAssembly(assembly: HipPlannerAssembly): void {
        this.assembly = assembly;
    }

    /**
     * Set in specific planner mode (combined, stem, cup)
     *
     * TODO This does a lof of things when the planner mode changes, and it is written in an imperative way
     * TODO That style of code suggest that they are in some way related and need to be executed in order,
     * TODO but if look deeper, they are not
     * TODO
     * TODO 1. Changes to X Ray mode
     * TODO 2. Updates camera view point
     * TODO 3. Updates material
     * TODO 4. Updates assembly mode (native to assembly position)
     * TODO 5. Updates cross sections
     * TODO 6. Emit event when done
     *
     * TODO The only real dependency at this stage is 1. and 3.
     * TODO Refactor into smaller observers/listeners of a planner mode that do they own thing
     */
    private setInPlannerMode(plannerMode: plannerModeName): void {
        log.info('Changing planner mode to %s', plannerMode);

        // If planning mode is in the Cup step, then activate xray mode,
        // otherwise switch to normal mode
        if (plannerMode === PlannerMode.Cup) {
            this.changeXRayMode(ViewingMode.Xray);
        } else {
            this.changeXRayMode(ViewingMode.Normal);
        }

        // update the camera position, based on the planning mode step
        this.updateCameraViewpoint(plannerMode);

        const mode = this.getPlannerModeSettings(plannerMode);
        if (this.sceneAssembly && mode) {
            HipViewerObjectUtil.onEachMeshObject3D(
                this.sceneAssembly.hipObjects,
                (acidMeshObject3d: AcidMeshObject3d) => {
                    this.updateObjectMaterialForMode(acidMeshObject3d, mode);
                });

            // Update the stem assembly position (native or adjusted) based on the planner mode
            const assembly = this.assembly;
            if (assembly) {
                if (plannerMode === PlannerMode.Stem) {
                    moveToNativePosition(assembly);
                } else {
                    moveToRetractedPosition(assembly);
                }
            } // else do nothing

            this.plannerViewStore.completeModeChange(plannerMode);
        } else {
            log.warn('Unknown planner mode %s', mode);
        }
    }

    /**
     * Update object's material based on a planner mode
     */
    private updateObjectMaterialForMode(acidMeshObject3d: AcidMeshObject3d, mode: HipPlannerModeRepresentation) {
        const modeName = acidMeshObject3d.alias as hipPlannerModeObjectSettingKey;
        if (mode[modeName]) {
            MaterialUtils.applyObjectMaterial(acidMeshObject3d, mode[modeName], this.isXrayMode);
        } else {
            log.warn('No mode \'%s\' for model %s', modeName, acidMeshObject3d.name);
        }
    }

    /**
     * Move the camera to the desired viewpoint position, based on the planning mode step
     */
    private updateCameraViewpoint(plannerMode: plannerModeName): void {
        if (this.cameraController) {
            switch (plannerMode) {
                case PlannerMode.Closed:
                    this.cameraController.animateTo(HipCameraPresetEnum.InitialAP);
                    break;

                case PlannerMode.Combined:
                    // when on combined panel, the user (surgeon) wants to fine-tune
                    // the leg length and offset of the femur through different stem
                    // offsets and heads, so camera focuses on the joint (cup centre)
                    this.cameraController.animateTo(HipCameraPresetEnum.Combined);
                    break;

                case PlannerMode.Stem:
                    this.cameraController.animateTo(HipCameraPresetEnum.StemAP);
                    break;

                case PlannerMode.Cup:
                    this.cameraController.animateTo(HipCameraPresetEnum.CupAP);
                    break;
            }
        } else {
            log.warn('Could not update camera viewpoint. Viewer object controller not set up');
        }
    }
}
