import { render, staticRenderFns } from "./MeasurementGroup.vue?vue&type=template&id=0ccbab1e&"
import script from "./MeasurementGroup.vue?vue&type=script&lang=ts&"
export * from "./MeasurementGroup.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Measurements.en.json5?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fcache%2Fteamcity-agent%2F85dc11f99c6c38aa%2Fapp%2Fsrc%2Fcomponents%2Fcase-manage%2Fmeasurement%2FMeasurementGroup.vue&locale=en&lang=json5"
if (typeof block0 === 'function') block0(component)

export default component.exports