import ResourceService from '@/lib/api/ResourceService';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { CollectionRepresentation, LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import {
    HipComponentsCatalogPropCollection,
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import {
    HipCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipCatalogComponentRepresentation';
import { ApiUtil } from '@/lib/semantic-network';
import {
    HipStemHeadCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemHeadCatalogComponentRepresentation';
import { getRequiredSelfUri } from '@/lib/api/SemanticNetworkUtils';

/**
 * This class provides an interface to fetch the different components available for
 * the hip (aka shoulder components catalog)
 */
export default class HipComponentsCatalogResource implements ResourceService {
    /**
     * Get the hip components catalog resource
     */
    public static async getComponentsCatalog(apiResource: ApiRepresentation, options?: CacheOptions):
        Promise<HipComponentsCatalogRepresentation | never> {
        const componentsCatalog = await ApiUtil.get<HipComponentsCatalogRepresentation>(
            apiResource, { rel: LinkRelation.hipComponentsCatalogue, ...options, name: 'hipComponentsCatalog' });
        if (componentsCatalog) {
            return componentsCatalog;
        }

        throw new Error('No Hip Components Catalog available');
    }

    public static getComponentInCollectionByUri<T extends HipCatalogComponentRepresentation>(
        componentsCatalog: HipComponentsCatalogRepresentation,
        collectionName: HipComponentsCatalogPropCollection,
        uri: string): T | null {
        const components = componentsCatalog[collectionName] as T[];
        return components.find((component: T) => getRequiredSelfUri(component) === uri) as T ?? null;
    }
}
