import { DateTime } from 'luxon';

/**
 * The version of the Vue application which is baked into the package at build time.
 */
export function applicationVersion(): string {
    const version = process.env.VUE_APP_VERSION;
    return version || '';
}

/**
 * The overall product version which constitutes a release of the product (whole solution).
 */
export function productVersion(): string {
    const element = document.querySelector('head meta[name="acid-version"]');

    if (element === undefined || element === null) {
        throw new Error('acid-version meta tag missing');
    }

    const version = element.getAttribute('content');

    if (version === undefined || version === null || version.length === 0) {
        throw new Error('acid-version meta tag content empty');
    }

    return version;
}

/**
 * The overall release date of the product.
 */
export function productReleaseDate(): DateTime {
    const element = document.querySelector('head meta[name="acid-deploy-utc-time"]');
    if (element === undefined || element === null) {
        throw new Error('acid-deploy-utc-time meta tag missing');
    }

    const timestamp = element.getAttribute('content');
    if (timestamp === undefined || timestamp === null || timestamp.length === 0) {
        throw new Error('acid-deploy-utc-time meta tag content empty');
    }

    const isoReleaseDate = DateTime.fromISO(timestamp);
    if (!isoReleaseDate.isValid) {
        throw new Error(`release date could not be parsed from: ${timestamp}`);
    }

    return isoReleaseDate;
}
