import { BodySide, BodySideType, HipCupAnteversionModeType } from '@/lib/api/representation/interfaces';
import { HipCupFitMethodEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { PatientSex, PatientSexType } from '@/lib/api/representation/PatientRepresentation';
import {
    HipCupProductPreferencesRepresentation,
    HipStemProductPreferencesRepresentation,
} from '@/lib/api/representation/ProductPreferencesRepresentation';

export const sideOptions = [
    { text: 'Left', value: BodySide.Left },
    { text: 'Right', value: BodySide.Right },
];

export const sexOptions = [
    { text: 'Male', value: PatientSex.Male },
    { text: 'Female', value: PatientSex.Female },
];

export interface SelectSurgeonOption {
    text: string;
    value: string;
}

export interface CaseInfo {
    name: string
    description: string
    procedure: string
    implantSide: BodySideType | ''
}

export interface SurgicalSpecInfo {
    stemTypes: string[]
    legLengthChange: string
    offsetChange: string
    anteversionMode: HipCupAnteversionModeType | string
    anteversionAngle: string
    inclinationAngle: string
    fittingMode: HipCupFitMethodEnum | string
}

export interface PatientInfo {
    name: {
        given: string
        middle: string
        family: string
    }
    birth_date: string
    sex: PatientSexType | string
}

export interface CaseSettings {
    caseInfo: CaseInfo
    patient: PatientInfo
    selectedSurgeon: string
    surgicalSpecification: SurgicalSpecInfo
}

export interface CaseSettingsState {
    isLoading: boolean
    isSubmitting: boolean
    isSubmitted: boolean
    isSuitable: boolean  // Able to assign surgeon to a case. When False, surgeon was explicitly unassigned from case.
    hasError: boolean
    hasSurgeonError: boolean

    /* form is dirty if there are invalid field when saving. */
    isDirty: boolean

    project: any  // Type is supposed to be CaseRepresentation but, we cannot define this type in store for some reason

    selectedSurgeonError: string
    availableSurgeons: SelectSurgeonOption[]
    availableStemTypes: any[]

    /* keep a copy of the original state so that we can figure out if the user has made any changes */
    original: CaseSettings
    settings: CaseSettings
}

export interface SurgeonPreferences {
    stem: HipStemProductPreferencesRepresentation;
    cup: HipCupProductPreferencesRepresentation;
}
