import { HipSurgicalTemplateStoreUtil } from '@/hipPlanner/components/state/HipSurgicalTemplateStoreUtil';
import SurgicalTemplateSynchroniser from '@/hipPlanner/stores/template/SurgicalTemplateSynchroniser';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { watch, WatchStopHandle } from 'vue';
import anylogger from 'anylogger';

const log = anylogger('HipSurgicalTemplateUpdater');
/**
 *  A render-less component that:
 *
 *  1. Listen to events when the cup is set/moved, and calculate the new leg length offset
 *  2. Notifies the global state about the new values calculated.
 */
export default class HipSurgicalTemplateUpdater {
    constructor(
        private templateStore: HipTemplateStore,
        private templateSynchroniser: SurgicalTemplateSynchroniser,
    ) {
        this.stopHandles.push(
            watch(
                () => templateStore.stemTransform,
                () => {
                    if (this.templateStore.enableStemTransform) {
                        this.templateSynchroniser.update(this.makeUpdateDocument());
                    } else {
                        log.debug('MSP is disabled. Skipping templateSynchroniser.update() onHipSurgicalTemplateUpdater');
                    }
                }
            ),
            watch(
                () => [
                    this.templateStore.targetLegLengthChange,
                    this.templateStore.targetOffsetChange,
                    this.templateStore.cup,
                    this.templateStore.cupComponents,
                    this.templateStore.cupRotation,
                    this.templateStore.cupOffset,
                    this.templateStore.liner,
                    this.templateStore.head,
                    this.templateStore.stem,
                    this.templateStore.stemComponents,
                    this.templateStore.stemTransform,
                ],
                () => {
                    this.templateSynchroniser.update(this.makeUpdateDocument());
                },
            ),
        );
    }

    private stopHandles: WatchStopHandle[] = [];

    public off(): void {
        this.stopHandles.forEach(h => h());
        this.stopHandles = [];
    }

    private makeUpdateDocument(): HipSurgicalTemplateRepresentation {
        const template = HipSurgicalTemplateStoreUtil.makeUpdateDocument(this.templateStore);
        if (HipSurgicalTemplateStoreUtil.validate(template, this.templateStore)) {
            return template;
        } else {
            //
            // TODO: Placeholder for writing code to emit error and display a nice looking error in the ui
            //
            throw new Error('Failed to validate template');
        }
    }
}
