import { watch, WatchStopHandle } from 'vue';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import { HipPlannerAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import { RigidTransform } from '@/lib/base/RigidTransform';
import assert from 'assert';
import { addHandlers } from '@/lib/vue/addHandlers';
import anylogger from 'anylogger';

const log = anylogger('AdjustmentCalculator');

/**
 * At the moment the cup & stem leg length & offset calculation are different.
 *
 * 1. The stem reacts to changes on the stemTransform.
 * 2. The cup uses the old model of listening to events that are triggered after
 * the cup assembly was moved in 3d js space.
 *
 * Notes:
 * 1. Pinia does not support at the moment to watch changes to a store inside a store.
 * Therefore, the watch is done in this component.
 * 2. I am trying to move the calculation of the adjustment to the client side
 * to do not rely on the api information (ll_diff, offset_diff calculated server side)
 * 3. From here:
 *  Stem adjustments assumption from server side code:
 *
 *  1. We should have all the data to make the calculation client side without relying on server side data
 *
 *  @staticmethod
 *     def leg_length_change(
 *             hjc_new: np.ndarray,
 *             hjc_native: np.ndarray,
 *             ll_vector: np.ndarray) -> float:
 *         """
 *             The change in leg length from native
 *
 *             ll_diff = (HJC_new - HJC_native) . ll_vector
 *         """
 *         return float(np.dot(hjc_new - hjc_native, ll_vector))
 *
 *  2. The ll_vector seems to correlate to the LPS scene coordinate system,
 *  given in python in was only able to track to this line of code
 *
 *  measurement_axes = HipStemMeasurementAxes.from_alignment_and_side(AlignmentMode.NoAlignment, side)
 *
 */
export default class AdjustmentCalculator {
    private stopHandles: WatchStopHandle[] = [];
    constructor(
        assembly: HipPlannerAssembly,
        plannerStore: HipPlannerStore,
        templateStore: HipTemplateStore,
        plannerEventBus: Vue) {
        const calculateAdjustments = () => plannerStore.calculateAdjustments(assembly);

        this.stopHandles = [
            watch(
                () => templateStore.stemTransform,
                (_transform: RigidTransform) => {
                    assert.ok(assembly, 'assembly is null');
                    if (plannerStore.enableStemTransform) {
                        plannerStore.calculateAdjustments(assembly);
                    } else {
                        log.debug('MSP is disabled. Skipping calculateAdjustments');
                    }
                },
                { immediate: true, deep: true }
            ),
            addHandlers(plannerEventBus, [
                [PlannerEvent.HipPlannerCupAssemblyMoved, calculateAdjustments],
                [PlannerEvent.HipPlannerCupAssemblyCupSet, calculateAdjustments],
            ]),
        ];
    }

    off(): void {
        this.stopHandles.forEach(h => h());
        this.stopHandles = [];
    }
}
