import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';
import { equals } from 'ramda';
import { NumberUtil } from '@/lib/base/NumberUtil';
import {
    ComponentOffsetRepresentation,
} from '@/lib/api/representation/case/surgical-template/common/ComponentOffsetRepresentation';
import {
    AnteversionInclinationAngleRepresentation,
} from '@/lib/api/representation/case/surgical-template/common/AnteversionInclinationAngleRepresentation';
import { areEqualTransforms } from '@/lib/api/representation/geometry/transforms';
import {
    SurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/SurgicalTemplateRepresentation';

const TOLERANCE = 0.01;

/**
 * @return whether the target changed on the surgical template local copy
 */
export function hasTargetsChanged(
    a: HipSurgicalTemplateRepresentation, b: Partial<HipSurgicalTemplateRepresentation>): boolean {
    return hasTargetLegLengthChange(a, b) || hasTargetOffsetChange(a, b);
}

export function hasTargetOffsetChange(
    a: HipSurgicalTemplateRepresentation, b: Partial<HipSurgicalTemplateRepresentation>): boolean {
    return a.target_offset_change !== b.target_offset_change;
}

export function hasTargetLegLengthChange(
    a: HipSurgicalTemplateRepresentation, b: Partial<HipSurgicalTemplateRepresentation>): boolean {
    return a.target_leg_length_change !== b.target_leg_length_change;
}

/** @return whether there is any difference between the stem assembly selection of both surgical templates */
export function anyStemAssemblyChange(
    a: HipSurgicalTemplateRepresentation,
    b: HipSurgicalTemplateRepresentation,
    /**
     * TODO: Remove this argument once MSP is implemented. This is only here to support MSP as a feature flag for the time being
     */
    compareStemTransform = true): boolean {
    if (compareStemTransform) {
        return hasStemUriChanged(a, b) || hasHeadUriChanged(a, b) || !equalStemTransform(a, b);
    } else {
        return hasStemUriChanged(a, b) || hasHeadUriChanged(a, b);
    }
}

/**
 * @return whether there is any difference between the cup assembly selection of both surgical templates.
 * Specifically checks the 'cup' link, 'cup_rotation', 'cup_offset'
 */
export function anyCupAssemblyChange(
    a: HipSurgicalTemplateRepresentation, b: HipSurgicalTemplateRepresentation): boolean {
    return hasCupUriChanged(a, b) ||
        hasCupLinerUriChanged(a, b) ||
        !equalCupRotation(a.cup_rotation, b.cup_rotation) ||
        !equalCupOffset(a.cup_offset, b.cup_offset);
}

export function hasStateChanged(
    a: SurgicalTemplateRepresentation, b: SurgicalTemplateRepresentation): boolean {
    return !equals(a.state, b.state);
}

export function hasPlanUriChanged(
    a: SurgicalTemplateRepresentation, b: SurgicalTemplateRepresentation): boolean {
    return !equals(LinkUtil.getUri(a, LinkRelation.currentPlan), LinkUtil.getUri(b, LinkRelation.currentPlan));
}

export function hasComponentsUriChanged(
    a: SurgicalTemplateRepresentation, b: SurgicalTemplateRepresentation): boolean {
    return !equals(LinkUtil.getUri(a, LinkRelation.componentSet), LinkUtil.getUri(b, LinkRelation.componentSet));
}

export function hasMeasurementsUriChanged(
    a: SurgicalTemplateRepresentation, b: SurgicalTemplateRepresentation): boolean {
    return !equals(LinkUtil.getUri(a, LinkRelation.measurements), LinkUtil.getUri(b, LinkRelation.measurements));
}

export function hasRangeOfMotionUriChanged(
    a: SurgicalTemplateRepresentation, b: SurgicalTemplateRepresentation): boolean {
    return !equals(LinkUtil.getUri(a, LinkRelation.rangeOfMotion), LinkUtil.getUri(b, LinkRelation.rangeOfMotion));
}

export function hasStemUriChanged(
    a: HipSurgicalTemplateRepresentation, b: HipSurgicalTemplateRepresentation): boolean {
    return !equals(LinkUtil.getUri(a, LinkRelation.hipStemComponent), LinkUtil.getUri(b, LinkRelation.hipStemComponent));
}

export function hasHeadUriChanged(
    a: HipSurgicalTemplateRepresentation, b: HipSurgicalTemplateRepresentation): boolean {
    return !equals(
        LinkUtil.getUri(a, LinkRelation.hipStemHeadComponent), LinkUtil.getUri(b, LinkRelation.hipStemHeadComponent));
}

/**
 * @returns whether two cup offsets are the same within a tolerance of {@link TOLERANCE}.
 * Note: The cup offset is a float with 2 digit precision when calculated server side.
 */
export function equalCupOffset(a: ComponentOffsetRepresentation, b: ComponentOffsetRepresentation): boolean {
    return equalCupAnteriorPosterior(a, b) &&
        equalCupMedialLateral(a, b) &&
        equalCupSuperiorInferior(a, b);
}

/** Compares if the stem transform of templates is equivalent */
export function equalStemTransform(
    a: HipSurgicalTemplateRepresentation, b: HipSurgicalTemplateRepresentation): boolean {
    const transform = a.stem_transform;
    const otherTransform = b.stem_transform;

    if (transform && otherTransform) {
        return areEqualTransforms(transform, otherTransform);
    }

    return transform === otherTransform;
}

/**
 * Compares if the cup rotation of two template is the same within a tolerance of {@link TOLERANCE}.
 * Note: The cup rotation is a float with 2 digit precision when calculated server side.
 */
export function equalCupRotation(
    a: AnteversionInclinationAngleRepresentation,
    b: AnteversionInclinationAngleRepresentation): boolean {
    return equalCupAnteversion(a, b) && equalCupInclination(a, b);
}

export function equalCupAnteversion(
    a: AnteversionInclinationAngleRepresentation,
    b: AnteversionInclinationAngleRepresentation): boolean {
    return NumberUtil.areEqualNullableWithinTolerance(a.anteversion, b.anteversion, TOLERANCE);
}

export function equalCupInclination(
    a: AnteversionInclinationAngleRepresentation,
    b: AnteversionInclinationAngleRepresentation): boolean {
    return NumberUtil.areEqualNullableWithinTolerance(a.inclination, b.inclination, TOLERANCE);
}

export function equalCupMedialLateral(
    a: ComponentOffsetRepresentation, b: ComponentOffsetRepresentation): boolean {
    return NumberUtil.areEqualNullableWithinTolerance(a.ml, b.ml, TOLERANCE);
}

export function equalCupAnteriorPosterior(
    a: ComponentOffsetRepresentation, b: ComponentOffsetRepresentation): boolean {
    return NumberUtil.areEqualNullableWithinTolerance(a.ap, b.ap, TOLERANCE);
}

export function equalCupSuperiorInferior(
    a: ComponentOffsetRepresentation, b: ComponentOffsetRepresentation): boolean {
    return NumberUtil.areEqualNullableWithinTolerance(a.si, b.si, TOLERANCE);
}

export function hasCupUriChanged(a: HipSurgicalTemplateRepresentation, b: HipSurgicalTemplateRepresentation): boolean {
    return !equals(LinkUtil.getUri(a, LinkRelation.hipCupComponent), LinkUtil.getUri(b, LinkRelation.hipCupComponent));
}

export function hasCupLinerUriChanged(
    a: HipSurgicalTemplateRepresentation, b: HipSurgicalTemplateRepresentation): boolean {
    return !equals(
        LinkUtil.getUri(a, LinkRelation.hipCupLinerComponent), LinkUtil.getUri(b, LinkRelation.hipCupLinerComponent));
}
