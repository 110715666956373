import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { Md5 } from 'ts-md5';
import anylogger from 'anylogger';

const log = anylogger('AxiosBrowserCacheUrlMutation');

export interface AxiosRequestConfigExtra {
    mutateUrl?: boolean;
}

/**
 *
 * @see {@link https://www.smashingmagazine.com/2017/11/understanding-vary-header/}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Cache/keys}
 */
export default class AxiosBrowserCacheUrlMutation {
    public static makeMutationOption<T>(config?: AxiosRequestConfig<T>): AxiosRequestConfig<T> {
        return { mutateUrl: true, ...config } as any as AxiosRequestConfig<T>;
    }

    /**
     * If the request has the magic {@link AxiosRequestConfigExtra.mutateUrl} flag set, then put
     * 8 characters of message digest in as entropy on the URL.
     */
    public static mungeRequestUrl(config: AxiosRequestConfig): Promise<AxiosRequestConfig> | AxiosRequestConfig {
        if ((config as (AxiosRequestConfigExtra & AxiosRequestConfig)).mutateUrl) {
            if ((config.method === 'get' || config.method === 'GET') && config.url) {
                const acceptHeader = config.headers?.accept;
                if (acceptHeader) {
                    if (typeof (acceptHeader) === 'string') {
                        // Take the last 8 characters of the md5 hash
                        const mutation = Md5.hashStr(acceptHeader).slice(24);
                        const url = new URL(config.url);
                        url.searchParams.append('key', mutation);
                        config.url = url.toString();
                    } else {
                        log.warn(`Accept Header expected to be a string '${acceptHeader}'`);
                    }
                }
            }
        }
        return config;
    }

    /**
     * The browser cache does not support caching multiple representations of a resource (read URL). If a hyper-mmeida
     * API is used where the representation returned is determined base on 'Accept*' ('Accept', 'Accept-Encoding',
     * 'Accept-Language', etc..) header then only the last representation will be cached. This is likely to
     * result in zero caching as each get will flush the cache.
     *
     * This browser behaviour is well documented (but probably not well known). Real intermediary caches implement
     * correct caching behaviour as per the relevant RFCs.
     *
     * WARNING: This strategy will have problems with mutable resources. If the main resource is (say) deleted then
     * the alternate URLs will not be known about and the cache will not have those alternate values invalidated.
     *
     * @see {@link https://github.com/axios/axios#interceptors}
     */
    public static addBrowserCacheUrlMunging(axios: AxiosInstance): void {
        const _id = axios.interceptors.request.use(AxiosBrowserCacheUrlMutation.mungeRequestUrl);
    }
}
