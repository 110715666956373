import {
    CollectionRepresentation,
    getUri,
    HttpUtil,
    Link,
    LinkedRepresentation,
    LinkUtil,
    RelationshipType,
    Uri,
} from 'semantic-link';
import { CacheOptions, getName, UpdateOptions } from '@/lib/semanticNetworkMigrationUtils';
import LinkRelation from '@/lib/api/LinkRelation';
import ContentType from '@/lib/http/mimetype';

import anylogger from 'anylogger';
import { ModelRepresentation } from '@/lib/api/representation/ModelRepresentation';
import AxiosBrowserCacheUrlMutation from '@/lib/http/AxiosBrowserCacheUrlMutation';
import { getRequiredUri } from '@/lib/api/SemanticNetworkUtils';
import {
    ApiOptions,
    ApiUtil,
    instanceOfCollection,
    instanceOfTrackedRepresentation,
    LinkRelConvertUtil,
    SingletonMerger,
    SparseRepresentationFactory,
    Status,
    Tracked,
    TrackedRepresentationFactory,
    TrackedRepresentationUtil,
} from '@/lib/semantic-network';
import AxiosUtil from '@/lib/AxiosUtil';
import assert from 'assert';
import { cloneDeep } from 'lodash';

const log = anylogger('ResourceUtil');

export default class ResourceUtil {
    /**
     * Call a function and convert {@link AxiosError} errors with a code of 404 into
     * a `null` result.
     */
    public static async IgnoreNotFound<T extends LinkedRepresentation>(
        func: () => Promise<T | null>): Promise<T | null> {
        try {
            return await func();
        } catch (err: unknown) {
            assert.ok(err instanceof Error);
            if (AxiosUtil.isForbiddenError(err)) {
                log.debug('Not found (404)');
                return null;
            } else {
                throw err;
            }
        }
    }

    /**
     * Create or get a virtual tracked resource that is a collection to store search collections.
     *
     * This can be used to store search results in an adhoc manner where the search
     * result doesn't have to have a name. The 'self' link of the search result can be used
     * to identify what the search was (and should be unique).
     *
     * @see makePooledCollection
     */
    public static makeSearchCollection<T extends LinkedRepresentation,
        TKey extends keyof T & string,
        TResult extends LinkedRepresentation>(
        context: T,
        name: TKey,
        options?: ApiOptions): CollectionRepresentation<CollectionRepresentation<TResult>> {
        if (instanceOfTrackedRepresentation(context)) {
            const searches = context[name] as unknown as CollectionRepresentation<CollectionRepresentation<TResult>>;
            if (searches) {
                if (instanceOfTrackedRepresentation(searches)) {
                    return searches;
                }
            } else {
                const newSearches = SparseRepresentationFactory.make<CollectionRepresentation<CollectionRepresentation<TResult>>>({
                    ...options, sparseType: 'collection', status: Status.virtual,
                });
                SingletonMerger.add(context, name, searches, options);
                return newSearches;
            }
        }
        throw new Error(`Failed to create search collection ${name}`);
    }

    /**
     * Given a set of {@link ApiOptions}, use the {@link ApiOptions.name} and {@ApiOptions.rel}
     * properties to determine a name.
     *
     * Note: a valid name is always returns, or a
     */
    public static makeName(options?: ApiOptions): string {
        const { name, rel } = { ...options };
        if (name) {
            return name;
        }
        if (rel) {
            const relName = LinkRelConvertUtil.relTypeToCamel(rel);
            if (relName) {
                return relName;
            }
        }
        throw new Error(`Options must have a rel or name`);
    }

    /**
     * Create or get a tracked resource that is a collection to store search collections. It
     * is likely (but not required) that the resource is backed by a 'real' resource.
     *
     * This can be used to store search results in an ad-hoc manner where the search
     * result doesn't have to have a name. The 'self' link of the search result can be used
     * to identify what the search was (and should be unique).
     *
     * @see makeSearchCollection
     */
    public static makePooledCollection<T extends LinkedRepresentation,
        _TKey extends keyof T & string,
        TResult extends LinkedRepresentation>(
        context: T,
        options?: ApiOptions): CollectionRepresentation<TResult> {
        if (instanceOfTrackedRepresentation(context)) {
            const { rel } = { ...options };
            if (rel) {
                const uri = LinkUtil.getUri(context, rel);
                if (uri) {
                    const poolName = ResourceUtil.makeName(options) as keyof T & string;
                    const pool = context[poolName] as unknown as CollectionRepresentation<TResult>;
                    if (pool) {
                        if (instanceOfTrackedRepresentation(pool)) {
                            if (instanceOfCollection(pool)) {
                                return pool;
                            } else {
                                throw new Error(`Pool ${poolName} is not a collection`);
                            }
                        } else {
                            throw new Error(`Attribute ${poolName} is not a tracked resource`);
                        }
                    } else {
                        const newSearches = SparseRepresentationFactory.make<CollectionRepresentation<TResult>>(
                            { ...options, sparseType: 'collection', uri });
                        SingletonMerger.add(context, poolName, newSearches, options);
                        return newSearches;
                    }
                } else {
                    throw new Error(`Link relation ${rel} not found`);
                }
            } else {
                throw new Error(`The pool collection requires a link relation`);
            }
        }
        throw new Error(`Failed to create pool collection`);
    }

    /**
     * Update a resource.
     *
     * Logically this method needs to have a local 'resource' and an update document which is a partial
     * of the resource (i.e. the fields that need to be updated). This method performs
     * a wire level update, and if the changes are accepted merges the document back into the resource.
     *
     * Note: this method exists to change the signature of the {@parameter options}.
     */
    public static async update<T extends LinkedRepresentation>(
        resource: T,
        updateDocument: Partial<T>,
        options?: UpdateOptions): Promise<T> {
        return await ApiUtil.update<T>(resource, updateDocument, options);
    }

    /**
     * Merge the fields from the 'updateDocument' on the resource.  This is an in memory
     * operation.
     *
     * @deprecated This seems to be used when an existing resource is going to be PUT, but
     * a large number of step are involved in collating the information. Consider an alternate
     * merging/update strategy.
     */
    public static merge<T extends LinkedRepresentation>(
        resource: T,
        updateDocument: Partial<T>,
        options?: CacheOptions): T | never {
        if (instanceOfTrackedRepresentation(resource)) {
            return SingletonMerger.merge(resource, updateDocument, { ...options });
        }
        throw new Error(`Invalid resource`);
    }

    /**
     * Put a resource on the server
     *
     * @deprecated This is not valid
     */
    public static async putResource<T extends LinkedRepresentation>(
        resource: T,
        options?: UpdateOptions): Promise<T> | never {
        log.warn('In place PUT of an already mutated resource - this has got to go [135]');
        const selfUri = getUri(resource, LinkRelation.self);
        if (selfUri) {
            const resourceWithoutMetadata = ResourceUtil.duplicateResourceWithoutMetadata(resource, options);
            if (resourceWithoutMetadata) {
                return await ApiUtil.update(
                    resource,
                    resourceWithoutMetadata,
                    {
                        ...options,
                        rel: LinkRelation.self,
                        contentType: ContentType.Json,
                        validateStatus: (code: number): boolean => {
                            return code === 204; /* NO_CONTENT */
                        },
                    } as UpdateOptions);
            } else {
                throw new Error(`Could not duplicate resource: ${resource}`);
            }
        }
        throw new Error(`Invalid resource. It has not self or canonical link: ${resource}`);
    }

    /**
     * Duplicate a resource style object in memory, without any additional meta-data that is part of the
     * semantic-network library.
     *
     * Explicitly this **excludes** the 'state' object and all child resources and collections. The result
     * will have the links and other attributes.
     *
     * Note: At the beginning of the implementation of this app methods with a **clean** prefix were written to
     * hand craft this logic. These should be deprecated in preference to this strategy.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/assign#Polyfill}
     */
    public static duplicateResourceWithoutMetadata<T extends LinkedRepresentation>(
        resource: T, options?: CacheOptions): T | undefined {
        if (ResourceUtil.isItemHydrated(resource)) {
            const result = <T> {};
            for (const key in resource) {
                if (Object.prototype.hasOwnProperty.call(resource, key) &&
                    typeof key === 'string' &&
                    !instanceOfTrackedRepresentation(resource[key])) {
                    const value = resource[key];
                    if (Array.isArray(value)) {
                        // make a shallow copy of arrays, as 'links' and 'items' may be mutated.
                        // TODO: I do not why this decision was made. To keep it working this way,
                        //       tests were added that will break if this changes.
                        SingletonMerger.add<Partial<T>, unknown>(result, key, [...value], options);
                    } else {
                        SingletonMerger.add<Partial<T>, unknown>(result, key, value, options);
                    }
                }
            }
            return result;
        } else {
            log.debug('Attempt to duplicate sparse resource');
        }

        return undefined;
    }

    /**
     * Clone a resource style object in memory, without any additional meta-data that is part of the
     * semantic-network library.
     *
     * This is similar to {@link duplicateResourceWithoutMetadata} but with the difference that
     * object properties will be also cloned.
     */
    public static clone<T extends LinkedRepresentation>(resource: T, options?: CacheOptions): T {
        const clone = ResourceUtil.duplicateResourceWithoutMetadata(resource, options);
        if (clone) {
            return cloneDeep<T>(clone);
        } else {
            log.error('expected resource to be defined for clone. Original resource: %o', resource);
            throw new Error(`expected resource to be defined with uri: ${LinkUtil.getUri(resource, LinkRelation.self)}`);
        }
    }

    /**
     * Sets a {@param value} on a {@param key} in {@param destination}
     *
     * Similar to {@see set} but for a key, value.
     * TODO: inline and remove.
     *
     * @deprecated: Use with caution. Properties will not be tracked under the 'singleton' | 'collection'.
     */
    public static setPropertyButDontTrack<CT extends LinkedRepresentation | Record<string, unknown>,
        KT extends keyof CT & string,
        PT>(
        destination: CT, key: KT, value: PT, options?: CacheOptions): CT {
        log.warn('setPropertyButDontTrack called with key: \'%s\'. Maybe a mistake', key);
        return SingletonMerger.add<CT, PT>(destination, key, value, options);
    }

    /**
     * Sets and 'track' a resource on a context
     * Note: After this operation, the resource will be 'tracked' in the parent context resource.
     * {@see State.isTracked}
     * Note: If resource is already tracked, it updates it.
     *
     * Uses:
     * 1. When getting a resource from a global collection and need to add on a context, we want the resource
     * to be tracked
     * 2. If making a sparse resource and we want it to be tracked immediately
     *
     * @deprecated Remove the 'key' parameter and calculate the key based on name/nameStrategy/rel.
     */
    public static setResource<CT extends LinkedRepresentation,
        KT extends keyof CT & string,
        RT extends LinkedRepresentation>(
        context: CT,
        key: KT,
        resourceToAdd: RT,
        options?: CacheOptions): RT {
        if (instanceOfTrackedRepresentation(resourceToAdd)) {
            // If the child resource is already tracked (managed) by semantic-network then perform
            // a simple set operation. i.e. add it to the 'tree' of data.
            TrackedRepresentationUtil.add(context, key, resourceToAdd, options);

            // assert is kind of unnecessary, but just in case.
            assert.ok(
                TrackedRepresentationUtil.isTracked(context, key),
                `expected resource with property '${key}' to be tracked.`);

            return resourceToAdd;
        } else {
            SingletonMerger.add(context, key, resourceToAdd, options);
            return resourceToAdd;
        }
    }

    /**
     * Set a sparse resource from a uri, on a context resource.
     */
    public static setSparseSingletonFromUri<TChild extends LinkedRepresentation,
        T extends LinkedRepresentation>(context: T, uri: Uri, options?: CacheOptions): TChild | Tracked<TChild> {
        const name = getName(context, { ...options });
        return ResourceUtil.setResource(
            context,
            name as keyof T & string,
            SparseRepresentationFactory.make<TChild>({ sparseType: 'singleton', uri }),
            options);
    }

    /**
     * Support to create a copy of the links on a resource and set one uri to a specific value.
     *
     * @param resource the local resource
     * @param linkSelector a selector to find an existing link in the resource
     * @param link the new value for the link (must include rel and href)
     * @deprecated use `setLink()` on a cloned resource instead
     */
    public static duplicateLinksSetUri<T extends LinkedRepresentation>(
        resource: T, linkSelector: RelationshipType, link: Link): LinkedRepresentation {
        // deepish copy the links (as they may be modified)
        const links = Array.from(resource.links, (item: Link) => {
            return { ...item };
        });

        // find the existing matching link.
        const aLink = LinkUtil.matches(links, linkSelector) ? LinkUtil.getLink(links, linkSelector) : undefined;
        if (aLink) {
            // update the existing link (perhaps splice it in instead).
            Object.assign(aLink, link);
        } else {
            // add a new link.
            links.push(link);
        }

        return { links };
    }

    /**
     * A simple form of {@link setLink}, where the parameters are separated out and only a simple rel is supported.
     */
    public static setLink2<T extends LinkedRepresentation>(
        resource: T, rel: string, title: string | undefined, href: Uri): T {
        return ResourceUtil.setLink(
            resource,
            title ? { rel, title } : rel,
            { rel, title, href });
    }

    /**
     * Update all links matching a selector with the given link.
     *
     * This is a hack used for updates. This **should** only be used on copies
     * of a resource that are mutated before update.
     */
    public static setLink<T extends LinkedRepresentation>(
        resource: T, linkSelector: RelationshipType, link: Link): T {
        const firstMatch = resource.links.findIndex((item) => LinkUtil.matches([item], linkSelector));
        if (firstMatch >= 0) {
            resource.links.splice(firstMatch, 1, link);
        } else {
            resource.links.push(link);
        }
        return resource;
    }

    /**
     * Support to create a copy of the links on a resource and set one uri to a specific value.
     * @deprecated use `setLink()` on a cloned resource instead
     */
    public static duplicateLinksSetUris<T extends LinkedRepresentation>(
        resource: T, newLinks: { linkSelector: RelationshipType, link: Link }[]): LinkedRepresentation {
        // deepish copy the links (as they may be modified)

        const links = Array.from(resource.links, (item: Link) => {
            return { ...item };
        });

        // TODO: Duplicated code: Use Array.reduce and existingtent singular method ResourceUtil.duplicateLinksSetUri
        newLinks.forEach((newLink) => {
            // find the existing matching link.
            const linkSelector = newLink.linkSelector;
            const link = newLink.link;
            const aLink = LinkUtil.matches(links, linkSelector) ? LinkUtil.getLink(links, linkSelector) : undefined;
            if (aLink) {
                // update the existing link (perhaps splice it in instead).
                Object.assign(aLink, link);
            } else {
                // add a new link
                links.push(link);
            }
        });

        return { links };
    }

    /**
     * Get an item inside a collection, by specifying the item by URI.
     *
     * Instead of loading the item by directly get it, it is fetched from the collection
     *
     * WARNING
     * 1) If the collection does not exist on the context, it will sparse a new collection
     * 2) The URI is (optimistically) assumed to be a valid URI and is added to the
     * collection of resources inside the context.
     *
     * @param contextResource
     * @param collectionRel
     * @param itemUri
     * @param options (Given the collection could be sparse if it does not exist, a 'name' attribute
     * in the options will allow you to set where you want the collection to be stored in the context
     * see {@link QueryOptions}
     *    1. @rel:
     *     - If present will be used as the 'rel' for matching the uri.
     *     - If **NOT** present will try to match to 'canonical' first, and if canonical is not present to 'self'
     */
    public static async getItemByUriInCollection<T extends LinkedRepresentation>(
        contextResource: LinkedRepresentation,
        collectionRel: RelationshipType,
        itemUri: Uri,
        options?: CacheOptions): Promise<T | null> {
        return await ApiUtil.get<T>(
            contextResource, { includeItems: false, ...options, rel: collectionRel, where: itemUri }) ?? null;
    }

    /**
     * Utility to load a collection in a parent context, from a global collection
     *
     * E.g:
     *  Context: a glenosphere
     *  Collection: 'bearings' rel on glenosphere
     *  GlobalCollection: Catalog of bearings
     *
     * This method will load the collection from the global collection. For each item that does not exist yet,
     * on the global collection, it will go and hydrate/add that item to the global collection
     *
     * It will set the items on the collection, so after this method is called the "context.collection.items"
     *  are exactly the same objects that exist on the global collection
     *
     * @param contextResource: The parent resource where the child collection is held
     * @param collectionRel: the collection rel on the contextResource
     * @param globalCollection
     * @param options
     *
     * TODO: change language from 'global' to 'pooled'.
     */
    public static async getCollectionInContextResourceFromGlobalCollection<T extends LinkedRepresentation>(
        contextResource: LinkedRepresentation,
        collectionRel: RelationshipType,
        globalCollection: CollectionRepresentation<T>,
        options?: CacheOptions): Promise<CollectionRepresentation<T>> {
        // TODO
        // This is getting a LinkRepresentation already and we do not want this
        // Ideally we would like to bypass the get, and instead of a LinkedRepresentation we would like
        // a FeedRepresentation
        // A FeedRepresentation is not a a "resource" (does not have state, neither links) yet
        //
        // Why we want a FeedRepresentation instead of LinkedRepresentation?
        // Because we want to set the items on the parent context collection once we got LinkedRepresentation items
        // from the global collection
        // We do not want to be setting the FeedRepresentation, and then once we load the items replace them
        // with LinkedRepresentation. That would cause the objects to be replace completely
        // Get the list we want to search
        const collection = await ApiUtil.get<CollectionRepresentation<T>>(
            contextResource,
            { rel: collectionRel, includeItems: false, ...options });
        if (collection) {
            return await this.setCollectionItemMaybeFromGlobalCollectionOrFetch<T>(collection, globalCollection, options);
        }
        throw new Error(`Failed to fetch collection`);
    }

    /**
     * Get each item on a collection. Once it get all items, it set the items on the collection
     *
     * Note:
     * 1. For each item, first tries to get the it from a global collection (if it was already loaded)
     * 1. If not, it fetch the resource item
     *
     * TODO: change language from 'global' to 'pooled'.
     */
    public static async setCollectionItemMaybeFromGlobalCollectionOrFetch<T extends LinkedRepresentation>(
        collection: CollectionRepresentation<T>,
        globalCollection: CollectionRepresentation<T>,
        options?: CacheOptions): Promise<CollectionRepresentation<T>> {
        // TODO do I have to filter the hydrated ones?
        await Promise.all(collection.items.map(async (item: ModelRepresentation) => {
            const uri = LinkUtil.getUri(item.links, LinkRelation.canonicalOrSelf);
            if (uri) {
                // cache.getCollectionItemByUri is ensuring the resource is in sync with the server
                // we may want this to be optional and not always the case
                // E.g: We may want to just get just a sparse resource based on some flag,
                // instead of yhe hydrated version
                const itemFoundInGlobalCollection = await ApiUtil.get(globalCollection, { where: uri, ...options });
                if (itemFoundInGlobalCollection) {
                    return itemFoundInGlobalCollection;
                } else {
                    // logic is not getting into the else, given is returning a promise to resolve the
                    // resource
                    //
                    // put in collection if not found
                    const sparse = SparseRepresentationFactory.make<T>({ uri });
                    collection.items.push(sparse);
                    globalCollection.items.push(sparse);
                    return await TrackedRepresentationFactory.load(sparse, options);
                }
            }

            throw new Error(`${uri} does not exist on collection ${item.links}`);
        }));

        return collection;
    }

    /**
     * Get a non-json/xml/yaml native representation of the resource, e.g PLY file
     * for a model resource.
     * The result is a promise of the given media type.
     * Important: the media type uses the built-in media type converters of Axios,
     * of which one exists for PLY files.
     */
    public static getMediaType<T extends LinkedRepresentation, TResult>(
        resource: T,
        rel: RelationshipType,
        itemName: keyof T): Promise<TResult> {
        const uri = getUri(resource, rel);
        if (uri) {
            return HttpUtil.get<T, TResult>(
                resource,
                rel,
                AxiosBrowserCacheUrlMutation.makeMutationOption<T>({
                    headers: {
                        accept: 'model/*,*/*;q=0.5',
                    },
                    responseType: 'arraybuffer',
                }))
                .then((response) => {
                    if (response && response.status === 200) {
                        const data = response.data as TResult;
                        resource[itemName] = data as any;
                        return data;
                    }
                    throw new Error(`Failed to get media: ${status}`);
                });
        }
        return Promise.reject(new Error('No such link relation'));
    }

    /**
     * Utility function to see if a resource {@link LinkedRepresentation} was already hydrated or not
     * @param resource
     */
    public static isItemHydrated(resource: LinkedRepresentation): boolean {
        return ResourceUtil.tryGetStatus(resource) === Status.hydrated;
    }

    public static isTracked(resource: LinkedRepresentation, resourceName: string): boolean {
        return TrackedRepresentationUtil.isTracked(resource, resourceName);
    }

    public static isDeleted(resource: LinkedRepresentation): boolean {
        return ResourceUtil.tryGetStatus(resource) === Status.deleted ||
            ResourceUtil.tryGetStatus(resource) === Status.deleteInProgress;
    }

    public static isForbidden(resource: LinkedRepresentation): boolean {
        return ResourceUtil.tryGetStatus(resource) === Status.forbidden;
    }

    public static isUnknown(resource: LinkedRepresentation): boolean {
        return ResourceUtil.tryGetStatus(resource) === Status.unknown;
    }

    public static tryGetStatus(resource: LinkedRepresentation): Status | undefined {
        if (instanceOfTrackedRepresentation(resource)) {
            const trackedState = TrackedRepresentationUtil.getState(resource);
            if (trackedState) {
                return trackedState.status;
            }
        }
        return undefined;
    }

    /**
     * Ensures a resource is hydrated.
     *
     * @returns the resource if hydrated already, otherwise it attempts to hydrate it and returns it.
     * @throws an error if after attempting to hydrate it fails.
     */
    public static async ensureHydratedResource<T extends LinkedRepresentation>(
        resource: T, options?: CacheOptions): Promise<T> {
        if (!ResourceUtil.isItemHydrated(resource)) {
            const resourceWithState = await ApiUtil.get<T>(resource, options);
            if (resourceWithState && ResourceUtil.isItemHydrated(resourceWithState)) {
                return resourceWithState;
            } else {
                const uri = getRequiredUri(resource, LinkRelation.canonicalOrSelf);
                const state = ResourceUtil.tryGetStatus(resource);
                throw new Error(`Resource ${uri} expected to be hydrated (state: ${state?.toString()})`);
            }
        } else {
            return resource;
        }
    }

    /**
     * Refresh a resource
     */
    public static async refresh<T extends LinkedRepresentation>(resource: T, options?: CacheOptions): Promise<T | null> {
        return await ApiUtil.get<T>(resource, { ...options, forceLoad: true }) ?? null;
    }

    /**
     * @returns whether a resource is the previous version of another one.
     * Note: Both resource will have to be hydrated, otherwise it will return false.
     */
    public static isPrevious<T extends LinkedRepresentation>(maybePrevious: T, latest: T): boolean {
        if (LinkUtil.matches(latest, LinkRelation.previous)) {
            if (LinkUtil.matches(maybePrevious, LinkRelation.canonical)) {
                if (LinkUtil.getUri(latest, LinkRelation.previous) ===
                    LinkUtil.getUri(maybePrevious, LinkRelation.canonical)) {
                    return true;
                }
            } else {
                log.debug('No canonical uri to compare for %s', LinkUtil.getUri(maybePrevious, LinkRelation.self));
            }
        } else {
            log.debug('No previous uri to compare for %s', LinkUtil.getUri(latest, LinkRelation.self));
        }
        return false;
    }
}
