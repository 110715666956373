// filter length floats to n-th decimal place
// TODO this should be a global filter DRY this Vue.filter
import { NumberUtil } from '@/lib/base/NumberUtil';
import numeral from 'numeral';
import { withZeroSignedFix } from '@/lib/filters/format/withZeroSignedFix';

/**
 * Filter floats to 1 decimal place and adds a '+' sign
 *
 * E.g:
 * 38.33333 => formatNumberSign(38.33333) = +38
 * 38.33333 => formatNumberSign(-38.33333) = -38
 */
export const formatNumberSign = function(value: number | null, precision = 2): string {
    if (NumberUtil.isFiniteNumber(value)) {
        const inputString = value > 0 ? '+0.' : '0.';
        const formattedNumber = numeral(value).format(inputString + '0'.repeat(precision));
        return withZeroSignedFix(formattedNumber);
    } else {
        return '--';
    }
};
