
    import { Component, InjectReactive, Vue } from 'vue-property-decorator';
    import Ago from '@/components/lib/Ago.vue';
    import { DateTime } from 'luxon';
    import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
    import { formatAngle } from '@/lib/filters/format/formatAngle';
    import {
        isCupRotation,
        RadiographicDegreesCupRotation,
    } from '@/lib/api/resource/case/surgical-template/HipSurgicalTemplateModel';
    import { StemOptionsFactory } from '@/hipPlanner/components/form/panels/stem/StemOptionsFactory';
    import CatalogStemFormattingUtils from '@/components/data/combobox/utils/CatalogStemFormattingUtils';
    import { formatLength } from '@/lib/filters/format/formatLength';
    import PlanItemDetailsItem from '@/components/case-plan/PlanItemDetailsItem.vue';
    import { NameRepresentationUtil } from '@/components/case-settings/utils/NameRepresentationUtil';
    import {
        HipSurgicalTemplateRepresentation,
    } from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import CupRotationUtil from '@/hipPlanner/components/state/CupRotationUtil';

    /**
     * This component is a v-card with the details of one of the plans within a case. Each
     * case may have zero or more of them items.
     *
     * If the item is the 'active' (aka current) plan then it will be highlighted as the
     * active plan. Only one plan will be 'active'.
     *
     * If the plan has no report then it is assumed to be still being processed and will
     * show as being processed. The v-card will poll for updates so that the user doesn't
     * need to reload the web application.
     */
    @Component({ components: { PlanItemDetailsItem, Ago } })
    export default class PlanItemDetails extends Vue {
        @InjectReactive() store!: ProjectStore;
        @InjectReactive() planStore!: PlanReportStore;

        /**
         * Get the created on date from the plan representation as a Luxon timestamp ({@link DateTime}).
         */
        protected get createdOn(): DateTime | null {
            if (this.plan.created_on) {
                const when = DateTime.fromISO(this.plan.created_on).setZone('local');
                if (when) {
                    return when;
                }
            }
            return null;
        }

        protected get formattedStemShape(): string {
            const stem = this.template.stem;
            return stem ? `(${CatalogStemFormattingUtils.formattedShape(stem?.type)})` : '';
        }

        protected get formattedStemDescription(): string {
            const stem = this.template.stem;
            return stem ? StemOptionsFactory.makeStemLabel(stem) : '';
        }

        protected get formattedStemAnteversion(): string {
            const measurements = this.planStore.measurements;
            if (measurements) {
                return formatAngle(measurements.stem_angle_anteversion ?? null);
            }
            return '--';
        }

        protected get formattedResectionDistances(): string {
            const measurements = this.planStore.measurements;
            if (measurements) {
                const greaterTrochanterDistance = measurements.resection_distances_greater_trochanter;
                const lesserTrochanterDistance = measurements.resection_distances_lesser_trochanter;
                const saddleDistance = measurements.resection_distances_saddle;

                return `${formatLength(greaterTrochanterDistance, 0)} /
                ${formatLength(lesserTrochanterDistance, 0)} /
                ${formatLength(saddleDistance, 0)}`;
            }

            return '--/--/--';
        }

        protected get formattedCupSize(): string {
            return this.template.cup?.size ?? '';
        }

        protected get formattedHeadDescription(): string {
            const head = this.template.head;
            if (head) {
                const offset = StemOptionsFactory.formatHeadOffset(head);

                if (offset) {
                    return `${offset} mm Offset`;
                }
            }

            return '';
        }

        protected get formattedLinerDescription(): string {
            const liner = this.template.liner;
            const size = liner?.size;
            const type = liner?.type;

            if (size && type) {
                return `${size} (${type})`;
            }

            return '';
        }

        protected get radiographicRotation(): RadiographicDegreesCupRotation | null {
            if (this.template) {
                if (isCupRotation(this.template.cup_rotation)) {
                    return CupRotationUtil.toRadiographic(this.template.cup_rotation);
                }
            }

            return null;
        }

        protected get formattedCupAnteversion(): string {
            return formatAngle(this.radiographicRotation?.anteversion ?? null);
        }

        protected get formattedCupInclination(): string {
            return formatAngle(this.radiographicRotation?.inclination ?? null);
        }

        protected get patientDescription(): string {
            const patient = this.store.patient;
            if (patient) {
                return NameRepresentationUtil.format(patient.name);
            }

            return '';
        }

        protected get plan(): PlanRepresentation {
            return this.planStore.plan;
        }

        protected get template(): HipSurgicalTemplateRepresentation {
            const template = this.planStore.template;
            if (template) {
                return template;
            } else {
                throw new Error('expect plan to have a template');
            }
        }
    }
