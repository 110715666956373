
    import { mapStores } from 'pinia';
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

    import AppButton from 'sol-ui/src/components/basic/AppButton.vue';
    import AppCard from 'sol-ui/src/components/basic/AppCard.vue';
    import AppCheckboxGroup from 'sol-ui/src/components/inputs/AppCheckboxGroup.vue';
    import AppFormControl from 'sol-ui/src/components/basic/AppFormControl.vue';
    import AppInputDate from 'sol-ui/src/components/inputs/AppInputDate.vue';
    import AppInputText from 'sol-ui/src/components/inputs/AppInputText.vue';
    import AppRadioGroup from 'sol-ui/src/components/inputs/AppRadioGroup.vue';
    import AppSelect from 'sol-ui/src/components/inputs/AppSelect.vue';
    import AppTextarea from 'sol-ui/src/components/inputs/AppTextarea.vue';
    import SidebarInformation from 'sol-ui/src/components/basic/SidebarInformation.vue';
    import AppPageHolder from 'sol-ui/src/components/layout/AppPageHolder.vue';
    import AppPageTitle from 'sol-ui/src/components/basic/AppPageTitle.vue';
    import AppFormActions from 'sol-ui/src/components/basic/AppFormActions.vue';
    import TitleUtil from '@/components/TitleUtil';
    import CaseTitle from '@/components/title/CaseTitle.vue';
    import { CaseSettingsStore, useCaseSettings } from '@/stores/caseSettings/store';
    import AppInlineNotification from 'sol-ui/src/components/basic/AppInlineNotification.vue';
    import AppFormStatusNotification from 'sol-ui/src/components/basic/AppFormStatusNotification.vue';
    import ScanUpload from '@/components/case-settings/scan/ScanUpload.vue';
    import anylogger from 'anylogger';
    import { UploadEventState, UploadStateType } from '@/components/case-settings/scan/workflow/ScanUploadState';
    import Notifications from '@/components/notifications/Notifications.vue';
    import UnassignSurgeon from '@/components/case-settings/unassign-surgeon/unassignSurgeon.vue';
    import { useUserRoles } from '@/stores/userRoles/store';

    const log = anylogger('CaseSettings');

    @Component({
        computed: { ...mapStores(useCaseSettings, useUserRoles) },
        components: {
            AppButton,
            AppCard,
            AppCheckboxGroup,
            AppFormControl,
            AppFormStatusNotification,
            AppInlineNotification,
            AppInputDate,
            AppInputText,
            AppRadioGroup,
            AppSelect,
            AppTextarea,
            AppPageHolder,
            AppPageTitle,
            AppFormActions,
            SidebarInformation,
            ScanUpload,
            UnassignSurgeon,
        },
    })
    export default class Settings extends Vue {
        @Prop({ required: true })
        apiUri!: string;

        caseSettingsStore!: CaseSettingsStore;

        uploadEvent: UploadStateType = UploadEventState.None;

        isMounted = false;

        async mounted(): Promise<void>  {
            this.isMounted = false;

            await this.caseSettingsStore.load(this.apiUri);

            // emit the project so that the title bar can show the project title and track
            // the project state to update the stepper
            TitleUtil.$emit(this, {
                titleComponentType: CaseTitle,
                titleData: { project: this.caseSettingsStore.project },
                notificationComponentType: Notifications,
                notificationData: { project: this.caseSettingsStore.project },
            });

            this.isMounted = true;
        }

        @Watch('caseSettingsStore.settings.selectedSurgeon')
        private async onSelectedSurgeon(): Promise<void> {
            if (this.isMounted) {
                // Update selected surgeon preferences only after component is mounted.
                await this.caseSettingsStore.onSelectedSurgeon();
            }
        }

        @Watch('caseSettingsStore.isSubmitted')
        private async onSettingsSubmitted(): Promise<void> {
            // reset uploadEvent
            this.onUploadEvent(UploadEventState.None);

            if (this.caseSettingsStore.isSubmitted && !this.caseSettingsStore.hasError) {
                log.info('Settings submitted, update project data');
                // This will trigger and update the ScanUpload when patient info are saved.
                await this.caseSettingsStore.loadProject(this.apiUri);
            }
        }

        private onUploadEvent(event: UploadStateType): void {
            this.uploadEvent = event;
        }

        get isUploadCompleted(): boolean {
            return this.uploadEvent === UploadEventState.Completed;
        }

        get isUploadError(): boolean {
            return this.uploadEvent === UploadEventState.Error;
        }
    }
