import { CrossSectionPlaneUtils } from '@/lib/planning/cross-section/CrossSectionPlaneUtils';
import { CrossSectionNameEnum } from '@/lib/planning/cross-section/CrossSectionPlaneController';
import { HipCrossSectionOutlineNames } from '@/lib/planning/cross-section/types';
import { HipRenderOrder } from '@/hipPlanner/assembly/objects/HipRenderOrder';
import { StemOptionsFactory } from '@/hipPlanner/components/form/panels/stem/StemOptionsFactory';
import { formatArrayNumber } from '@/lib/filters/format/formatArrayNumber';
import anylogger from 'anylogger';
import { HipPlannerAssembly, StemAssembly } from '@/hipPlanner/assembly/HipPlannerAssembly';
import { CrossSectionPlane } from '@/lib/planning/cross-section/CrossSectionPlane';
import {
    HipStemCatalogComponentRepresentation,
} from '@/lib/api/representation/catalog/hip/HipStemCatalogComponentRepresentation';
import { Vector3 } from 'three';
import { worldPosition } from '@/lib/base/ThreeUtil';

const log = anylogger('stem-neck-cut');

/** Make the stem coronal cross-section plane */
export function makeNeckCutCrossSection(assembly: HipPlannerAssembly): CrossSectionPlane {
    const femoralAssembly = assembly.femoralAssembly;

    const { normal, origin } = getResectionPlaneNormalAndOrigin(assembly);

    // Make and get the stem femur cut cross-section
    const plane = CrossSectionPlaneUtils.makeVisibleWhenEnabledPlane(
        CrossSectionNameEnum.OperativeFemurNeckCut,
        origin,
        normal,
        {
            name: HipCrossSectionOutlineNames.OperativeFemurCut,
            models: [femoralAssembly.femur, femoralAssembly.innerSurface],
        });
    plane.object3d.renderOrder = HipRenderOrder.NeckCutPlane;

    const component = assembly.stem.getCaseComponent().component as HipStemCatalogComponentRepresentation | null;
    log.debug(
        'Cross section \'%s\' create for stem \'%s\': normal: %s, origin: %s',
        plane.name,
        component ? StemOptionsFactory.makeStemLabel(component) : '',
        formatArrayNumber(normal.toArray()),
        formatArrayNumber(origin.toArray()));

    return plane;
}

export function updateNeckCutCrossSectionPlane(assembly: StemAssembly, plane: CrossSectionPlane) {
    const { normal, origin } = getResectionPlaneNormalAndOrigin(assembly);

    const component = assembly.stem.getCaseComponent().component as HipStemCatalogComponentRepresentation | null;
    log.debug(
        'Cross section \'%s\' update for stem \'%s\': normal: %s, origin: %s',
        plane.name,
        component ? StemOptionsFactory.makeStemLabel(component) : '',
        formatArrayNumber(normal.toArray()),
        formatArrayNumber(origin.toArray()));

    plane.direction = normal;
    plane.updateWorldPosition(origin);
}

/**
 * @return the current normal and origin of the resection plane
 */
function getResectionPlaneNormalAndOrigin(assembly: StemAssembly): { normal: Vector3; origin: Vector3 } {
    return {
        normal: new Vector3().setZ(1).transformDirection(assembly.stemResectionPlane.matrixWorld).negate(),
        origin: worldPosition(assembly.stemResectionPlane),
    };
}
