import { LinkedRepresentation, LinkUtil } from 'semantic-link';
import LinkRelation from '@/lib/api/LinkRelation';

export default class LinkedRepresentationUtil {
    /**
     * Given a linked representation, attempt to get the representation key for Vue component. Each
     * component must be unique and each component should be associated with an underlying resource
     * on the API. By using the key attribute of a component those constraints should be met.
     */
    public static getItemKey(item: LinkedRepresentation): string {
        if (item) {
            const url = LinkUtil.getUri(item, LinkRelation.canonicalOrSelf);
            if (url) {
                return url;
            }
        }
        return 'Unknown';
    }
}
