import { Vue } from 'vue/types/vue';
import { PlannerEvent } from '@/lib/planning/events/PlannerEvent';
import anylogger from 'anylogger';
import {
    ViewerFilterItemModeEventPayload,
    VisibilityItemByAliasEventPayload,
} from '@/hipPlanner/components/viewer-filter-bar/item/event/payload';
import AcidPlannerModeController from '@/hipPlanner/assembly/controllers/AcidPlannerModeController';
import { ViewingMode } from '@/lib/viewer/ViewingMode';
import { addHandlers } from '@/lib/vue/addHandlers';
import { WatchStopHandle } from 'vue';

const log = anylogger('ViewerFilterObserver');

/**
 * Support for storing a refresh token in local storage.
 */
export class ViewerFilterObserver {
    private stopHandles: WatchStopHandle[] = [];

    constructor(
        /**
         * event bus used for filtering
         * TODO Add typing for event bus
         */
        private readonly eventBus: Vue,
        /**
         * controller that does visibility, transparency, x-ray, and a lot of other stuff. Adding here to use it
         * until I stop using it
         */
        private readonly plannerModeController: AcidPlannerModeController) {
        this.stopHandles = [
            addHandlers(this.eventBus, [
                [PlannerEvent.ToggleModelVisibilityByAlias, this.onToggleModelVisibilityByAlias.bind(this)],
                [PlannerEvent.ToggleViewXRayMode, this.onToggleViewXRayMode.bind(this)],
            ]),
        ];
    }

    private onToggleModelVisibilityByAlias(payload: VisibilityItemByAliasEventPayload): void {
        log.debug(
            'onToggleModelVisibilityByAlias handler called with filter: %s, isActive: %s',
            payload.filter.toString(),
            payload.isActive);
        this.plannerModeController.toggleVisibilityByAlias(payload.isActive, payload.filter);

        this.eventBus.$emit(PlannerEvent.ToggleModelVisibilityByAliasDone, payload);
    }

    public off(): void {
        this.stopHandles.forEach(h => h());
        this.stopHandles = [];
    }

    private onToggleViewXRayMode(payload: ViewerFilterItemModeEventPayload): void {
        log.debug('onToggleViewXRayMode handler called filter: %s, isActive: %s', payload.filter, payload.isActive);

        const viewingMode = payload.isActive ? ViewingMode.Xray : ViewingMode.Normal;
        this.plannerModeController.changeXRayMode(viewingMode);
    }
}
