
    import { Component, Vue } from 'vue-property-decorator';
    import NewCase from '@/components/case/NewCase.vue';

    import ViewPath from '@/router/viewPath';
    import BackToDashboard from '@/components/case/BackToDashboard.vue';
    import { RouteUtils } from '@/router/RouteUtils';
    import { mapStores } from 'pinia';
    import { useVersion } from '@/stores/version';
    import { VersionStore } from '@/stores/version/store';

    /**
     * The sidebar shows up everywhere in the app except the 3D viewer page.
     */
    @Component({ components: { BackToDashboard, NewCase }, computed: { ...mapStores(useVersion) } })
    export default class NavigationSidebar extends Vue {
        versionStore!: VersionStore;

        viewPaths = ViewPath;

        mounted() {
            this.versionStore.load();
        }

        get showNewCaseButton(): boolean {
            return RouteUtils.isOnDashboard(this.$route);
        }
    }
