import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import CameraActions from '@/lib/planning/camera/CameraActions';
import ViewerUtils from '@/lib/planning/viewer/ViewerUtils';

import anylogger from 'anylogger';
import { Vector3 } from 'three';
import { CameraAnimation } from '@/lib/planning/camera/CameraAnimation';
import LPS from '@/lib/base/LPS';
import { SupportedCameraType } from '@/lib/scene/BasicViewer';

const log = anylogger('CameraMan');

/**
 * Custom camera positions used on the hip application
 */
export enum HipCameraPresetEnum {
    InitialAP = 'initialAP',
    StemAP = 'stemAP',
    CupAP = 'cupAP',
    Combined = 'combined',
}

type PresetName = string | HipCameraPresetEnum.InitialAP | HipCameraPresetEnum.StemAP
    | HipCameraPresetEnum.CupAP | HipCameraPresetEnum.Combined;

export interface CameraPreset {
    /** A point in world-space that the camera is 'looking at'. This point will be in the centre of the camera's
     * view, and the camera will 'orbit' around this point until the view is translated.
     * */
    lookAt: Vector3;

    /** The position of the camera in world-space */
    position: Vector3;

    /** The approximate 'up' direction of the camera in the world-space */
    up: Vector3;
}

/** Default distance in millimeters between a camera's look-at point and its position */
const CAMERA_DEFAULT_DISTANCE = 450;

/** Default for the camera approximate 'up' direction in world-space */
const CAMERA_DEFAULT_UP_DIRECTION = LPS.Superior;

/**
 * The class in charge of moving/animating the camera to different positions.
 *
 * 1. It allows to add 'presets' based on camera position, direction, lookAt point and distance.
 * 2. It allows to smoothly animate the camera or move it without any animation.
 */
export default class CameraMan {
    private _presets: { [key: string]: CameraPreset } = {};

    /**
     * The current camera animation
     */
    private _animation: CameraAnimation | null = null;

    /**
     * Initialise the camera manager
     */
    public constructor(
        private _camera: SupportedCameraType,
        private _controls: TrackballControls) {
    }

    /**
     * Move/animate the camera using a given camera preset
     */
    public animateTo(presetName: PresetName): void {
        const preset = this._presets[presetName];

        if (preset) {
            if (this._animation) {
                this._animation.cancel();
            }

            this._animation = CameraActions.makeSmoothCameraAnimation(
                this._camera,
                this._controls,
                this._camera.position,
                preset.position,
                preset.lookAt,
                preset.up);

            this._animation.animate();
        } else {
            log.warn('Cannot animate camera to new position. Camera preset %s not set up', presetName);
        }
    }

    /**
     * Move the camera using a given camera preset
     */
    public moveTo(presetName: PresetName, distance?: number): void {
        const preset = this._presets[presetName];
        if (preset) {
            CameraActions.moveTo(
                this._camera,
                this._controls,
                preset.position,
                preset.lookAt,
                preset.up,
                distance);
        } else {
            log.warn('Cannot move camera to new position. Camera preset %s not set up', presetName);
        }
    }

    /**
     * Add a camera preset with a target look-at point and either a position or an offset-direction and distance
     */
    public addPreset(
        presetName: PresetName,
        lookAt: Vector3,
        properties: { direction: Vector3, distance?: number, up?: Vector3 } | { position: Vector3, up?: Vector3 },
    ): CameraPreset {
        const preset = {
            position: 'position' in properties ?
                properties.position.clone() :
                ViewerUtils.getPositionAlongDirection(
                    lookAt, properties.direction, properties.distance ?? CAMERA_DEFAULT_DISTANCE),
            lookAt: lookAt.clone(),
            up: (properties.up ?? CAMERA_DEFAULT_UP_DIRECTION).clone(),
        };
        this._presets = { ...this._presets, [presetName]: preset };
        return preset;
    }
}
