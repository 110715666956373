export enum SurgicalTemplateRecordChangeState {
    /**
     * A surgical template record state enum that can be used for UI workflows
     * that produce a change on the surgical template.
     *
     * Note: there is no concept on the server if the surgical template is resetting or not. There is a concept around
     * business logic being:
     * 1. scheduled (record_state: 'new')
     * 2. processing (record_state: 'processing')
     * 3. completed (record_state: 'completed')
     * 4. error (record_state: 'error')
     *
     * Note:
     * By combining the state of the surgical template resource and the UI interaction with reset components we can
     * keep the state of the reset workflow.
     *
     *                                              +--------------------+
     *                                              |                    |
     *                                              |    Initial         |
     *                                              |                    |
     *                                              +--------------------+
     *                                                        |
     *                                                        |  (A) A UI actions that triggers a change and will
     *                                                        |  trigger
     *                                                        |
     *                                                        v
     *                                              +--------------------+
     *                                              |                    |
     *                                         +----|      Started       |
     *                                         |    |                    |
     *                                         |    +--------------------+
     *                                         |               |
     *     The client was not fast enough  (C) |               |  (B) - The surgical template record
     *     to realize about the 'new' or       |               |        state change to 'new' or 'processing.
     *     processing state                    |               v
     *                                         |     +--------------------+
     *                                         |     |                    |
     *                                         |     |    Processing      |
     *                                         |     |                    |
     *                                         |     +--------------------+
     *                                         |               |
     *                                         |               |  (D)  - The surgical template record
     *                                         |               |         state change to 'completed'.
     *                                         |               v
     *                                         |     +--------------------+
     *                                         |     |                    |
     *                                         +---->|   CompletedServer  |
     *                                               |                    |
     *                                               +--------------------+
     *                                                         |
     *                                                         | (E) - The UI reflects the new components
     *                                                         |
     *                                                         v
     *                                               +--------------------+
     *                                               |                    |
     *                                               |       Done         |
     *                                               |                    |
     *                                               +--------------------+
     *
     *                                               +--------------------+           *** From **any** state except 'Done'
     *                                               |                    |                         |
     *                                               |      Error         | <-----------------------+
     *                                               |                    |
     *                                               +--------------------+
     *
     */
    /**
     * Initial state
     */
    Initial = 'initial',
    /**
     * The reset button clicked.
     */
    Started = 'started',
    /**
     * The client detects the business logic associated with the reset process has started.
     *
     * Conditions:
     * 1. The surgical template record state changed to 'new' or 'processing'.
     * 2. The re-ranking must have been in the {@property SurgicalTemplateRecordChangeState.Started}
     */
    Processing = 'processing',
    /**
     * The client detect the business logic associated with the re-ranking process has finished.
     *
     * Detection:
     * The surgical template record state changed to 'completed'.
     * The reset state must have been in {@property SurgicalTemplateRecordChangeState.Processing} or {@property SurgicalTemplateRecordChangeState.Started}
     *
     * Note
     * ----
     * Previous state could have been {@property SurgicalTemplateRecordChangeState.Started}: It is unlikely but there is a small chance
     * that the client did not picked up the transition between the record state 'new' or 'processing' and only realizes
     * that the business logic is 'completed'. This is unlikely given the reset takes some times and that time
     * should be enough for the client to fetch the surgical template while it was in 'new' or 'processing'.
     */
    CompletedServer = 'completed-server',
    /**
     * This is a final state for a reset process.
     *
     * A UI pure event, which signals that the components were updated in the 3D view.
     *
     */
    Done = 'done',
    /**
     * The client detected an error during reset.
     *
     * E.g.:
     * a. The client attempted to transition to a state without being in the right previous state
     * b. The client detected an error in the update service (PUT/GET request while reset failed)
     */
    Error = 'error',
}

export class SurgicalTemplateRecordChangeStateUtil {
    public static isInProgress(state: SurgicalTemplateRecordChangeState): boolean {
        return state === SurgicalTemplateRecordChangeState.Started ||
            state === SurgicalTemplateRecordChangeState.Processing ||
            state === SurgicalTemplateRecordChangeState.CompletedServer;
    }

    public static isDone(state: SurgicalTemplateRecordChangeState): boolean {
        return state === SurgicalTemplateRecordChangeState.Done;
    }
}
