export default class JsonUtil {
    /**
     * Encode a object as JSON for inclusion as a HTTP header.
     *
     * To support JSON as an http header value, all whitespace should be removed (specifically
     * whitespace include newlines, carriage returns, tabs etc). Simple spaces are ok, but don't
     * add any value from a machine-to-machine perspective (just make it human readable).
     *
     * The length of the JSON document should be kept short. Different (browser) implementations will have
     * line length limits, and header length limits.
     *
     * @see {@link https://tools.ietf.org/id/draft-reschke-http-jfv-07.html}
     */
    public static toJsonHeader(resource: unknown): string {
        return JSON.stringify(resource);
    }
}
