import HipTemplateController, {
    cancelWaitUntilCanQueryComponents,
    waitUntilCanQueryComponents,
} from '@/hipPlanner/components/state/HipTemplateController';

/**
 * A service that allows to check if the components of the surgical template can be queried or not.
 *
 * This service uses the client side artifact [form]{@link SurgicalTemplateForm} which holds the
 * [state]{@link SurgicalTemplateFormState} of the white dialog.
 * It is the intent of this utility to check the client side states (UI), and the latest surgical template
 * [record_state]{@link SurgicalTemplateRepresentation.record_state}, given in that way we can avoid querying
 * the components if a change has either been:
 * - scheduled, but not executed yet.
 * - executed, but components data has not been calculated yet.
 *
 * Note: The record_state of the surgical template does not know about the UI state.
 */
export default class SurgicalTemplateComponentsService {
    /**
     * A token to cancel waiting to query components.
     */
    private readonly cancellation: AbortController;

    constructor(
        private controller: HipTemplateController) {
        this.cancellation = new AbortController();
    }

    /**
     * Linear waiting strategy until the current user changes are sent
     * to the server, and synchronized and the components data can be queried.
     *
     * @return if the components can be queried or not. If the there was an error, or the caller
     * made use of the cancellation and called abort, it returns false.
     */
    public waitUntilCanQueryComponents(): Promise<boolean> {
        return waitUntilCanQueryComponents(this.controller, this.cancellation.signal);
    }

    /**
     * Cancel the awaiting until the components can be queried.
     */
    public cancel(): void {
        cancelWaitUntilCanQueryComponents(this.cancellation);
    }
}
