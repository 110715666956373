import { Vue } from 'vue-property-decorator';
import anylogger from 'anylogger';
import HipTemplateController from '@/hipPlanner/components/state/HipTemplateController';
import { HipEvent } from '@/lib/planning/events/HipEvent';
import { ReRankingState, ReRankingStateUtil } from '@/hipPlanner/stores/planner/ReRankingState';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { HipSurgicalTemplateStoreUtil } from '@/hipPlanner/components/state/HipSurgicalTemplateStoreUtil';
import SurgicalTemplateSynchroniser from '@/hipPlanner/stores/template/SurgicalTemplateSynchroniser';
import { HipPlannerStore } from '@/hipPlanner/stores/planner/hipPlannerStore';
import { HipTemplateStore } from '@/hipPlanner/stores/template/hipTemplateStore';
import {
    anyCupAssemblyChange,
    anyStemAssemblyChange,
    hasTargetsChanged,
} from '@/lib/api/resource/case/surgical-template/hipTemplateComparison';

const log = anylogger('HipSurgicalTemplateMerger.ts');

/**
 * A class that analyze if changes in a template are due to the normal flow of the application or
 * due to changes done in another tab/user/session
 *
 * @listens {@link HipSynchronisationEvent.Fetched}
 * @emits {@link HipEvent.ChangesDetected} if detect the current version in the 3D planning has changes not done by the user.
 */
export default class HipSurgicalTemplateChangeDetection {
    constructor(
        private templateController: HipTemplateController,
        private templateSynchroniser: SurgicalTemplateSynchroniser,
        private plannerStore: HipPlannerStore,
        private plannerEventBus: Vue,
    ) {
    }

    private error = false;

    private get templateStore(): HipTemplateStore {
        return this.templateController.store;
    }

    /**
     * Handler to compare if there is a version mismatch between the server representation and the client version
     *
     * Note: This does the minimum comparison between the incoming representation and the client surgical template.
     * - It **does not do** any clever merge between the representations. For example, if the client version,
     * and the incoming version have compatible differences it will still set as in error.
     */
    public onSurgicalTemplateFetched(template: HipSurgicalTemplateRepresentation): void {
        const hasChanges = (
            currentTemplate: HipSurgicalTemplateRepresentation,
            template: HipSurgicalTemplateRepresentation,
            state: ReRankingState): boolean => {
            const hasTargetChanges = hasTargetsChanged(template, currentTemplate);
            if (hasTargetChanges) {
                log.debug('targets changes detected in merge');
                return true;
            }

            if (anyStemAssemblyChange(template, currentTemplate, this.plannerStore.enableStemTransform)) {
                log.debug('femoral changes detected in merge');
                //
                // Edge case here for when is re-ranking. TODO: Need to validate this is working.
                //
                if (ReRankingStateUtil.isReRanking(state)) {
                    log.debug('femoral changes detected but avoided given re-ranking state: %s', state);
                } else {
                    log.debug('femoral changes detected but is not re-ranking. (re-ranking state: %s', state);

                    return true;
                }
            }

            if (anyCupAssemblyChange(template, currentTemplate)) {
                log.debug('acetabular changes detected in merge');
                return true;
            }

            return false;
        };

        if (this.error) {
            log.debug('Ignoring fetched events due to being in error.');
        } else if (this.templateController.isDirty) {
            log.debug('Skipping merge check while form is saving (has changes not flushed yet)');
        } else {
            const currentTemplate = HipSurgicalTemplateStoreUtil.makeUpdateDocument(this.templateStore);
            const reRankingState = this.plannerStore.stem.reRankingState;

            if (hasChanges(currentTemplate, template, reRankingState)) {
                this.error = true;

                this.templateSynchroniser.stop();

                this.plannerEventBus.$emit(
                    HipEvent.ChangesDetected.name,
                    HipEvent.ChangesDetected.payload(template));
            } else {
                log.debug('no changes detected in merge');
            }
        }
    }
}
