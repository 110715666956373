import { HipCupFitMethodEnum } from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { SelectCupFitMethodOption } from '@/components/data/combobox/types/component-selectors/types';

const HipCupFitMethodOptions: SelectCupFitMethodOption[] = [
    {
        value: HipCupFitMethodEnum.Acid,
        // The 'ACID' branding is no longer used - this is the native formus labs fit method
        text: 'Auto',
    },
    {
        value: HipCupFitMethodEnum.Fossa,
        text: 'Fossa',
    },
    {
        value: HipCupFitMethodEnum.Beverland,
        text: 'Beverland',
    },
];

export default HipCupFitMethodOptions;
