import { HipCaseRepresentation } from '@/lib/api/representation/case/HipCaseRepresentation';
import {
    HipComponentsCatalogRepresentation,
} from '@/lib/api/representation/global-catalog/HipComponentsCatalogRepresentation';
import { Vector3 } from 'three';

export enum LoadingState {
    Init = 'init',
    LoadingResources = 'loading-resources',
    LoadingSceneOrigin = 'loading-scene-origin',

    // final states
    Completed = 'completed',
    Error = 'error',
    Cancelled = 'cancelled',
}

export type HipCaseState = {
    loadingState: LoadingState;

    // @see https://medium.com/@bklik/how-to-abort-requests-in-pinia-stores-f46577cff80f
    cancellation: AbortController;

    /**
     * Not used for now to display to the users. Only used to count if it has errors or not, so the content of each
     * message itself does not much
     */
    errorMessages: string[];

    caseResource: HipCaseRepresentation | null;

    componentsCatalog: HipComponentsCatalogRepresentation | null;

    /**
     * --------------------------- Scene Transformation --------------------------------------
     */

    /**
     * A point at the origin of the APP plane, in the middle of the pelvis level with the left and right ASIS.
     */
    sceneOrigin: Vector3 | null;
}
