import ResourceUtil from '@/lib/api/ResourceUtil';

import anylogger from 'anylogger';
import { HipCaseStore } from '@/hipPlanner/stores/case/hipCaseStore';
import assert from 'assert';
import { watch, WatchStopHandle } from 'vue';
import { HipEvent } from '@/lib/planning/events/HipEvent';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';

const log = anylogger('SurgicalTemplateEventEmitter');

/**
 * Emits {@event HipEvent.RecordStateChange} events on changes to the surgical template.
 */
export default class SurgicalTemplateEventEmitter {
    constructor(
        private caseStore: HipCaseStore,
        private plannerEventBus: Vue,
    ) {
        const getSurgicalTemplate = (): HipSurgicalTemplateRepresentation => {
            const template = this.caseStore.surgicalTemplate;
            assert.ok(!!template, 'No surgical template');
            return template;
        };

        this.previousValue = duplicateSurgicalTemplate(getSurgicalTemplate());

        this.stopHandle = watch(() => caseStore.surgicalTemplate?.record_state,
            () => {
                const template = getSurgicalTemplate();
                if (ResourceUtil.isItemHydrated(template)) {
                    if (this.previousValue) {
                        emitRecordStateChanges(this.plannerEventBus, template, this.previousValue);
                    }
                    this.previousValue = duplicateSurgicalTemplate(template);
                } else {
                    log.warn('surgical template value not hydrated yet');
                }
            });
    }

    private stopHandle: WatchStopHandle | null = null;
    private previousValue: HipSurgicalTemplateRepresentation | null = null;

    public off() {
        if (this.stopHandle) {
            this.stopHandle();
            this.stopHandle = null;
        }
    }
}

/**
 * Emits a {@event HipEvent.RecordStateChange} if the record state changed from the current to the
 * previous surgical template
 */
function emitRecordStateChanges(
    plannerEventBus: Vue,
    current: HipSurgicalTemplateRepresentation, previous: HipSurgicalTemplateRepresentation): void {
    if (current.record_state !== previous.record_state) {
        plannerEventBus.$emit(
            HipEvent.RecordStateChange.name,
            HipEvent.RecordStateChange.payload(current, previous));
    } else {
        log.debug('surgical template has the same record state as before');
    }
}

function duplicateSurgicalTemplate(value: HipSurgicalTemplateRepresentation): HipSurgicalTemplateRepresentation {
    const copyOfSurgicalTemplate = ResourceUtil.duplicateResourceWithoutMetadata(value);
    if (copyOfSurgicalTemplate) {
        return copyOfSurgicalTemplate as HipSurgicalTemplateRepresentation;
    }

    throw new Error('invalid empty surgical template');
}
