
    import { Component, InjectReactive, Prop, Vue } from 'vue-property-decorator';
    import QrcodeVue from 'qrcode.vue';
    import anylogger from 'anylogger';
    import ProjectStore from '@/hipPlanner/components/state/project/ProjectStore';
    import PlanReportStore from '@/hipPlanner/components/state/plan/PlanReportStore';
    import PreoperativePlanRepresentationFactory
        from '@/components/case-plan/actions/PreoperativePlanRepresentationFactory';

    const log = anylogger('QRCode');

    /**
     * A Wrapper
     */
    @Component({ components: { QrcodeVue } })
    export default class QRCode extends Vue {
        @InjectReactive() store!: ProjectStore;
        @InjectReactive() planStore!: PlanReportStore;

        @Prop({ required: false, default: 100 })
        public size!: number | string;

        /**
         * TODO: cleanup - this is an ugly dump of roughly what needs to be done
         *
         * Known prerequisites:
         *   - must be a hip case
         *   - the surgical template must be configured for APP (see specifications with fallback to preferences)
         *   - the template must have a valid stem/head/cup/liner
         */
        public get transferValue(): string | null {
            try {
                const preoperativePlan = PreoperativePlanRepresentationFactory.make(this.store, this.planStore);
                return JSON.stringify(preoperativePlan);
            } catch (e) {
                log.warn(`That didn't work. ${e}`);
                return null;
            }
        }
    }
