import { camelCase } from 'lodash';
import ComponentSelectorRepresentationUtil from '@/lib/api/ComponentSelectorRepresentationUtil';

/** mapping of stem type to display strings */
const STEM_TYPE_NAMES: { [id: string]: string } = {
    fullProfile: 'Full',
    reducedStem: 'Reduced',
    microplasty: 'Microplasty',
};

export default class CatalogStemFormattingUtils {
    /**
     * The 'shape' is the first part of the type
     * E.g: Reduced Stem[High Offset] -> shape: Reduced Stem
     */
    public static formattedShape(componentType: string): string {
        const shape = ComponentSelectorRepresentationUtil.getStemShapeFromStemType(componentType);
        return STEM_TYPE_NAMES[camelCase(shape)];
    }
}
