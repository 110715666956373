import { Vue } from 'vue/types/vue';
import {
    HipSurgicalTemplateRepresentation,
} from '@/lib/api/representation/case/surgical-template/hip/HipSurgicalTemplateRepresentation';
import { CacheOptions } from '@/lib/semanticNetworkMigrationUtils';
import { PlanRepresentation } from '@/lib/api/representation/case/plan/PlanRepresentation';
import { ApiRepresentation } from '@/lib/api/representation/ApiRepresentation';
import {
    AlignmentModeEnum,
    AlignmentModeType,
    HipSurgicalSpecificationRepresentation,
    isHipSurgicalSpecificationsRepresentation,
} from '@/lib/api/representation/SurgicalSpecificationRepresentation';
import { HipProductPreferencesRepresentation } from '@/lib/api/representation/ProductPreferencesRepresentation';
import PlanReportService from '@/components/case-plan/services/PlanReportService';
import { CaseRepresentation } from '@/lib/api/representation/case/CaseRepresentation';
import { PlanFileCollectionRepresentation } from '@/lib/api/representation/case/plan/PlanFileRepresentation';
import { isHipSurgicalTemplate } from '@/lib/api/representation/case/surgical-template/SurgicalTemplateUtil';
import { MeasurementsRepresentation } from '@/lib/api/representation/case/measurements/MeasurementsRepresentation';
import PlanReportDownloadService from '@/components/case-plan/services/PlanReportDownloadService';
import { AxiosInstance } from 'axios';
import PlanResource from '@/lib/api/resource/case/plan/PlanResource';
import { UriDeconstructionUtil } from '@/components/case-plan/UrlDeconstructionUtil';
import LinkRelation from '@/lib/api/LinkRelation';
import ModelDownloadService from '@/components/case-plan/services/ModelDownloadService';
import assert from 'assert';
import Bugsnag from '@bugsnag/js';
import anylogger from 'anylogger';
import {
    HipReportMeasurementsRepresentation,
} from '@/lib/api/representation/case/plan/HipReportMeasurementsRepresentation';
import { PlanType } from '@/lib/constants/PlanType';
import { PlanReportState } from '@/hipPlanner/components/state/plan/PlanReportState';
import { HipSpecificationStore } from '@/hipPlanner/stores/specifications/hipSurgicalSpecificationStore';
import { LinkUtil } from 'semantic-link';
import {
    HipSurgicalTemplateMeasurementFactory,
} from '@/lib/api/resource/case/surgical-template/measurements/HipSurgicalTemplateMeasurementFactory';
import { hasReport, isCreating, isCreationBroken } from '@/lib/api/representation/case/plan/planRepresentationUtil';

const log = anylogger('PlanReportStore');

/** The global store of the project in a flat form */
export default class PlanReportStore {
    protected readonly _state: PlanReportState = {
        initialised: false,

        files: null,
        _reportMeasurements: null,
        _templateMeasurements: null,

        template: null,

        studyMeasurements: null,
    };

    private _planReportService: PlanReportService;
    private _planDownloadService: PlanReportDownloadService;
    private _modelDownloadService: ModelDownloadService;

    constructor(
        private readonly _project: CaseRepresentation,
        private readonly _plan: PlanRepresentation,
        private readonly _type: PlanType,
        private readonly _eventBus: Vue,
        protected readonly _specificationStore: HipSpecificationStore,
        private readonly _axiosInstance: AxiosInstance,
        private readonly _apiResource: ApiRepresentation,
        private _apiOptions: CacheOptions) {
        this._planReportService = new PlanReportService(this._project, this._plan, this._apiResource, this._apiOptions);
        this._planDownloadService = new PlanReportDownloadService(this._axiosInstance);
        this._modelDownloadService = new ModelDownloadService(this._axiosInstance);
    }

    /** =========================================================================================
     *
     * Getters (computed - reactive) properties to access the data in the component
     *
     * ========================================================================================= */

    public get initialised(): boolean {
        return this._state.initialised;
    }

    public get planId(): string {
        return UriDeconstructionUtil.pathNameOfLink(this._plan, LinkRelation.self);
    }

    public get isUserPlan(): boolean {
        return this._type === PlanType.UserPlan;
    }

    public get isAutomatedPlan(): boolean {
        return this._type === PlanType.AutomatedPlan;
    }

    public get isCreating(): boolean {
        return isCreating(this._plan);
    }

    public get isCreationBroken(): boolean {
        return isCreationBroken(this._plan);
    }

    public get hasReportLink(): boolean {
        return hasReport(this._plan);
    }

    public get plan(): PlanRepresentation {
        return this._plan;
    }

    public get template(): HipSurgicalTemplateRepresentation | null {
        return this._state.template;
    }

    public get templateHistoryId(): string {
        return UriDeconstructionUtil.pathNameOfLink(this._plan, LinkRelation.up);
    }

    /**
     * Measurements in the plan view and qr code have been fed from different sources over time.
     *
     * 1. Since the     release v1.7 (June 2023) cases with completed plans should have a report-measurements link.
     * This is the source of measurements for news cases.
     * 2. Prior to that, since release v1.0.7611 (April 2022), measurements were calculated per surgical template updated
     * @see {class HipNewSurgicalTemplateMeasurements}
     * 3. Prior to that measurements, were calculated per surgical template, but with a different structure
     * @see {class HipLegacySurgicalTemplateMeasurements}
     *
     * Given the data in s3 has not been migrated, this strategy allows to account for backward compatibility.
     */
    public get measurements(): HipReportMeasurementsRepresentation | null {
        if (!this.initialised) {
            return null;
        }

        if (LinkUtil.matches(this.plan, LinkRelation.reportMeasurements)) {
            return this._state._reportMeasurements;
        }

        if (this.isCreating) {
            // if it is being created there is a chance the representation will change and will have
            // a report-measurements link in a while. This all should be reactive and this stage and render
            // the measurements when needed.
            return null;
        } else {
            // fallback strategy
            // if it is not being created (it is already completed) and does not have
            // a 'report-measurements' link, we assume it is a legacy case that has not been re-approved,
            // so the measurements.json for the report do not exist.
            // We still need to display what the product was displaying for these cases.
            assert.ok(this._state._templateMeasurements, 'template measurements do not exist');
            assert.ok(this.template, 'template do not exist');
            const legacyTemplateMeasurements = HipSurgicalTemplateMeasurementFactory.make(
                this._state._templateMeasurements, this.template);

            return {
                offset_change: legacyTemplateMeasurements.getCombinedLegOffsetDifference(),
                leg_length_change: legacyTemplateMeasurements.getCombinedLegLengthDifference(),
                stem_angle_anteversion: legacyTemplateMeasurements.getStemAnteversion()?.value,
                cup_anteversion: legacyTemplateMeasurements.getCupAnteversion()?.value,
                resection_distances_lesser_trochanter: legacyTemplateMeasurements.getStemResectionLesserTrochanter()?.value,
                resection_distances_greater_trochanter: legacyTemplateMeasurements.getStemResectionGreaterTrochanter()?.value,
                resection_distances_saddle: legacyTemplateMeasurements.getStemSaddleDistance()?.value,
            } as HipReportMeasurementsRepresentation;
        }
    }

    public get studyMeasurements(): MeasurementsRepresentation | null {
        return this.initialised ? this._state.studyMeasurements : null;
    }

    public get specification(): HipSurgicalSpecificationRepresentation | null {
        return this.initialised ? this._specificationStore.specification : null;
    }

    public get preferences(): HipProductPreferencesRepresentation | null {
        return this.initialised ? this._specificationStore.preferences : null;
    }

    public get files(): PlanFileCollectionRepresentation | null {
        return this.initialised ? this._state.files : null;
    }

    /**
     * Return the number of files in the plan.
     *
     * Note:
     * 1. This will be zero until the plan has been completed.
     * 2. The files are only available for download by the super-admin, admin and organization admin,
     * so it is likely that the files collection will be empty for common users.
     *
     * When the browser does a GET for a common user (e.g.: a surgeon), a 403 will be returned.
     * The semantic-network library instantiate a sparse resource with the forbidden state, which won't have items.
     */
    public get fileCount(): number {
        return this.files?.items?.length ?? 0;
    }

    /**
     * If the alignment mode is 'APP'
     */
    public get isAnteriorPelvicPlaneAlignmentMode(): boolean {
        return this.alignmentMode === AlignmentModeEnum.APP;
    }

    /**
     * Load the alignment mode in the case of a hip project and sets {@private this.alignmentMode}
     */
    public get alignmentMode(): AlignmentModeType | null {
        return this._specificationStore.isReady ? this._specificationStore.alignmentMode : null;
    }

    /** =========================================================================================
     *
     * Actions & side-effects
     *
     * ========================================================================================= */

    public async refreshPlan(): Promise<void> {
        const files = await PlanResource.getPlanFiles(
            this._plan, { ...this._apiOptions, forceLoad: true });
        const reportMeasurements = await PlanResource.getReportMeasurements(
            this._plan, { ...this._apiOptions, forceLoad: true });

        if (files) {
            this._state.files = files;
        }

        if (reportMeasurements) {
            this._state._reportMeasurements = this._plan.reportMeasurements ?? null;
        }
    }

    public async loadReportData(): Promise<void> {
        const plan = await this._planReportService.getReportData();

        if (plan) {
            this.flatMapState(plan);

            const specification = plan.template?.surgicalSpecification;
            if (isHipSurgicalSpecificationsRepresentation(specification)) {
                this._specificationStore.$reset();
                await this._specificationStore.init(specification);
            }
        }

        this._state.initialised = true;
    }

    public async downloadReport(): Promise<void> {
        await this._planDownloadService.download(this._plan);
    }

    /**
     * Note: the files are available for download only by the super-admin, admin and organization admin,
     * so it is likely that the files collection will be empty for the common users.
     */
    public async downloadModels(): Promise<void> {
        try {
            if (this.planId && this.templateHistoryId && this.fileCount && this.files) {
                await this._modelDownloadService.download(
                    this.files, `Formus Case ${this.templateHistoryId}.${this.planId} 3D Models.zip`);
            } else {
                log.error('Case not available for download');
            }
        } catch (err: unknown) {
            assert.ok(err instanceof Error);
            // TODO: provide the error information back to the user
            log.error('Failed to download 3D models: %s', err.message);
            Bugsnag.notify(err);
        }
    }

    protected flatMapState(plan: PlanRepresentation): void {
        this._state.files = plan.files ?? null;
        this._state._reportMeasurements = plan.reportMeasurements ?? null;

        if (isHipSurgicalTemplate(plan.template)) {
            this._state.template = plan.template;
            this._state._templateMeasurements = plan.template.measurements ?? null;
            this._state.studyMeasurements = plan.template.study?.measurements ?? null;
        }
    }
}
